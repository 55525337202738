import { Product } from '&types/hedgeconnect';
import { getConfig } from '@/config/config';
import { prepareHeaders } from './baseQuery';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/state/store';
import { setThirdPartyId } from '../user/userSlice';
import { isDefined } from '@sgme/fp';

export const fxkitApi = createApi({
  reducerPath: 'fxkitApi',
  // BaseQuery setup
  baseQuery: fetchBaseQuery({
    baseUrl: getConfig().api_pretrade_url,
    prepareHeaders: (headers) => prepareHeaders(headers),
  }),
  // Endpoints setup
  endpoints: (builder) => ({
    getProducts: builder.query<Product, void>({
      query: () => ({
        url: `/`,
        method: 'GET',
      }),
      async onQueryStarted(_payload, { queryFulfilled, dispatch, extra }) {
        try {
          const { meta } = await queryFulfilled;
          const thirdPartyId = meta?.response?.headers.get('Third-Party-Id');
          if (isDefined(thirdPartyId)) {
            dispatch(setThirdPartyId(thirdPartyId));
          }
        } catch {
          // @todo Error management
        }
      }
    }),
  }),
});

export const { useGetProductsQuery } = fxkitApi;

const selectProductsResult = fxkitApi.endpoints.getProducts.select();

const selectChosenBdrId = (_state: RootState, chosenBdrId: string) => chosenBdrId;

export const selectAllProduct = createSelector(
  selectProductsResult,
  (products) => products?.data?.bulkTrade ?? null,
);

// Chosen bdrid is company id too
export const selectProductByChosenBdrId = createSelector(
  [selectAllProduct, selectChosenBdrId],
  (allProducts, chosenBdrId) => allProducts?.filter((product) => product.company === chosenBdrId),
);
