import { IntlProvider } from '@/context/IntlContext';
import { SGWTConnectCore, SGWTConnectError } from '@sgwt/connect-core';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { App } from './components/App';
import { SgwtConnectHTMLElement } from './types/sgwt-widgets';
import { BUS_TOPIC_SGCONNECT_READY, requestAuthorization } from './widgets/widgets';
import { isDefined } from '@sgme/fp';
import { AppDispatch, AppStore, store } from './state/store';
import { connectToSignalr } from './features/signalr';
import { sgwtConnect } from './widgets/sgwtConnect';
import { setUserPermissions } from './features/user/userSlice';
import { getFakeIam } from './widgets/fakeUser';
import { SgwtWebAnalytics, SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';
import { getConfig } from './config/config';
import { initSgBootstrap } from './utils/theme';

setupSgwtConnectWidget(sgwtConnect);
initSgBootstrap();

function setupApp() {
  renderApp(store);
  connectToSignalr(store.dispatch);
}

function renderApp(store: AppStore) {
  const container = document.getElementById('root');
  const root = createRoot(container!);

  const {
    matomo: { url, site_id },
  } = getConfig();

  root.render(
    <StrictMode>
      <Provider store={store}>
        <IntlProvider>
          <SgwtWidgetContextProvider infrastructure="azure">
            <SgwtWebAnalytics
              siteId={site_id}
              baseUrl={url}
              debug={getConfig().sgwt_env === 'homologation'} // To show debug on homolo env
            />
            <App />
          </SgwtWidgetContextProvider>
        </IntlProvider>
      </Provider>
    </StrictMode>,
  );
}

function renderError(authorizationError: SGWTConnectError) {
  document.body.innerHTML = `
      <div class='alert alert-danger' role='alert'>
        Authorization error: ${authorizationError}.
      </div>`;
}

function setupSgwtConnectWidget(sgwtConnect: SGWTConnectCore) {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');

  if (widget) {
    // When the code is executed, the swgtConnectWidget may not have been initialized. So, we need to check that, otherwise calling
    // `swgtConnectWidget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the swgtConnectWidget is ready...
      const handleSgwtConnectReady = () => {
        widget.setSgwtConnectInstance(sgwtConnect);
        widget.removeEventListener(BUS_TOPIC_SGCONNECT_READY, handleSgwtConnectReady);
      };

      widget.addEventListener(BUS_TOPIC_SGCONNECT_READY, handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}

requestAuthorization(setupApp, renderError, {
  onUserInfo: (userInfo) => {
    if (isDefined(userInfo)) {
      hasHedgeConnectionIAMPermissions(userInfo);
      saveUserPermissions(store.dispatch, userInfo);
    }
  },
});

export const hasHedgeConnectionIAMPermissions = (userInfo: UserAuthorization) => {
  if(getFakeIam() !== undefined){
    return true;
  }

  const haveIamRights =
    userInfo?.user_authorization.some((authorization) =>
      authorization.permissions.some((permission) => permission.name === 'SGM_HEDGECONNECT'),
    ) ?? false;

  if (!haveIamRights) {
    console.log('User has no right to access HedgeConnect')
    redirectToRequestAccess();
  }
};

export const redirectToRequestAccess = () => {
  window.location.replace(window.sgmeConfiguration.access_request_url + location.href);
};

type Authorization = {
  resource: string;
  permissions: { name: string }[];
};

export type UserAuthorization = {
  user_authorization: Authorization[];
};

export function saveUserPermissions(dispatch: AppDispatch, userInfo: UserAuthorization) {

  const canAccessFromUrl = getFakeIam() !== undefined ? true :
    userInfo?.user_authorization.some((authorization) =>
      authorization.permissions.some((permission) => permission.name === 'CanAccessFromUrl'),
    ) ?? false;

  dispatch(setUserPermissions({ canAccessFromUrl }));
}
