import { AppListenerEffectAPI } from '@/state/store';
import {
  clientLatencyAborted,
  dealTimeout,
  execute,
  selectQuoteReply,
  selectRfsId,
} from '@/features/rfs/rfsSlice';
import { selectStreamingInfo } from '@/features/streaming/streamingSlice';
import { assertIsDefined, isNotDefined } from '@sgme/fp';
import { selectProduct } from '@/features/product/productSlice';
import { mapToMultipassQuoteExecution } from '@/models/transform/multipass/toMultipass';
import { currentRfsEnd } from '@/state/listeners/matchers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { toTimeoutedDealMail } from '@/models/transform/helpRequest/toTimeoutedDealMail';
import { logger } from '@/utils/logger';
import { isCurrencyPairAllowed } from '@/components/content/product/shared';
import { selectChoosenBdrId, selectRfsCurrencies, selectRights } from '@/features/user/userSlice';
import { hasRightToDealLeg } from '@/utils/rights';

function isLatencyError(
  response: { data: void } | { error: FetchBaseQueryError | SerializedError },
): boolean {
  return 'error' in response && 'status' in response.error && response.error.status === 408;
}
export async function triggerExecution(
  { payload: currencyPair }: ReturnType<typeof execute>,
  {
    getState,
    dispatch,
    condition,
    extra: {
      webApiEndpoints: { executeQuote, helpRequest },
      getDateNow,
    },
  }: AppListenerEffectAPI,
) {
  const state = getState();
  const quoteReply = selectQuoteReply(state, currencyPair);
  const rfsId = selectRfsId(state, currencyPair);
  const connectionId = selectStreamingInfo(state).connectionId;
  const bdrId = selectChoosenBdrId(state);

  const rfsCurrenciesAllowed = selectRfsCurrencies(state, bdrId ?? 0);
  const { hasForwardRight, hasSpotRight } = selectRights(state);

  const { legsByCurrencyPair } = selectProduct(state);

  assertIsDefined(bdrId, 'cannot request quote without bdrId');
  assertIsDefined(quoteReply, `Cannot execute without quote reply for ${currencyPair}`);
  assertIsDefined(rfsId, `Cannot execute without RFS ID for ${currencyPair}`);
  assertIsDefined(connectionId, `Cannot execute without streaming for ${currencyPair}`);

  const legs = legsByCurrencyPair[currencyPair].filter((leg) => (
      leg.isSelectedForExecution &&
      isNotDefined(leg.errors) &&
      isNotDefined(leg.warnings) &&
      isCurrencyPairAllowed(rfsCurrenciesAllowed, currencyPair) &&
      hasRightToDealLeg(leg, hasForwardRight, hasSpotRight)
    ));

  const executionPayload = mapToMultipassQuoteExecution(
    legs,
    connectionId,
    quoteReply,
    getDateNow(),
  );

  const response = await dispatch(executeQuote.initiate(executionPayload));

  if (isLatencyError(response)) {
    dispatch(clientLatencyAborted(rfsId));
    logger.logWarning('PendingExecutionTimeout', {
      rfsId,
    });
    return;
  }

  if ((await condition(currentRfsEnd(rfsId), 10000)) === false) {
    dispatch(dealTimeout(rfsId));
    dispatch(helpRequest.initiate(toTimeoutedDealMail(state, currencyPair)));
  }
}
