import {
  errorOnRequest,
  heartbeat,
  quoteAbort,
  selectAllRfsByCurrencyPair,
  selectCurrencyPairByRfsId,
  selectRfsByCurrencyPair,
  streamStarted,
} from '@/features/rfs/rfsSlice';
import { AppListenerEffectAPI } from '@/state/store';
import { isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { logger } from '@/utils/logger';
import {
  currentRfsAbort,
  currentRfsHeartbeat,
  currentRfsQuoteReceived,
} from '@/state/listeners/matchers';
import { MultipassHeartbeat } from '@/models/rfs';
import { isDefined } from '@sgme/fp';
import { selectProduct, unselectDeal } from '@/features/product/productSlice';
import { mapTolegWithPrice } from '@/components/content/product/AllProductsByCurrencyPair';
import { selectStep } from '@/features/step/stepSlice';
import { getCurrencyWithNoSpotAndForwardRights, getNoForwardRights, getNoSpotRights, getRfsState } from '@/utils/rights';
import { selectRights } from '@/features/user/userSlice';
import { ProductLegItem } from '@/components/content/product/shared';

const ONE_SECOND_IN_MILLISECONDS = 1000;
const ONE_MINUTE_IN_MILLISECONDS = 60 * ONE_SECOND_IN_MILLISECONDS;

export async function streamStartedListener(
  { payload }: ReturnType<typeof streamStarted>,
  {
    dispatch,
    getState,
    take,
    extra: {
      webApiEndpoints: { cancelQuote },
    },
  }: AppListenerEffectAPI,
) {
  const actionPredicate = isAnyOf(
    currentRfsHeartbeat(payload.rfsId),
    currentRfsQuoteReceived(payload.rfsId),
    currentRfsAbort(payload.rfsId),
  );
  const state = getState();

  
  const currencyPair = selectCurrencyPairByRfsId(state, payload.rfsId);

  const { legsByCurrencyPair } = selectProduct(state);
  const allRfsByCurrencyPair = selectAllRfsByCurrencyPair(state);
  const legsWithPrice = mapTolegWithPrice(legsByCurrencyPair, allRfsByCurrencyPair);

  const step = selectStep(state);
  const rfsState = getRfsState(step);

  const { hasForwardRight, hasSpotRight } = selectRights(state);

  
  
  
  // ██████╗ ███████╗ █████╗ ██╗         ███████╗███████╗██╗     ███████╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗
  // ██╔══██╗██╔════╝██╔══██╗██║         ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║
  // ██║  ██║█████╗  ███████║██║         ███████╗█████╗  ██║     █████╗  ██║        ██║   ██║██║   ██║██╔██╗ ██║
  // ██║  ██║██╔══╝  ██╔══██║██║         ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║██║   ██║██║╚██╗██║
  // ██████╔╝███████╗██║  ██║███████╗    ███████║███████╗███████╗███████╗╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║
  // ╚═════╝ ╚══════╝╚═╝  ╚═╝╚══════╝    ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
  
  Object.entries(legsWithPrice).forEach(([currentCurrencyPair,legs]) => {
    legs.forEach((leg) => {
    const hasNoSpotRights = getNoSpotRights(legs, hasSpotRight)
    const hasNoForwardRights = getNoForwardRights(legs, hasForwardRight)
    
    const { errors, warnings, type,  thirdPartyTradeReference} = leg
    if (
      isDefined(errors) ||
      isDefined(warnings) ||
      (hasNoSpotRights && type === 'Spot') ||
      (hasNoForwardRights && type === 'Forward')
    ) {
      dispatch(
        unselectDeal({
          currencyPair: currentCurrencyPair,
          thirdPartyTradeReference: thirdPartyTradeReference!,
        }),
      );
    }
  });
  });


//     ██████╗██╗  ██╗███████╗ ██████╗██╗  ██╗    ██████╗ ██╗ ██████╗ ██╗  ██╗████████╗███████╗
//    ██╔════╝██║  ██║██╔════╝██╔════╝██║ ██╔╝    ██╔══██╗██║██╔════╝ ██║  ██║╚══██╔══╝██╔════╝
//    ██║     ███████║█████╗  ██║     █████╔╝     ██████╔╝██║██║  ███╗███████║   ██║   ███████╗
//    ██║     ██╔══██║██╔══╝  ██║     ██╔═██╗     ██╔══██╗██║██║   ██║██╔══██║   ██║   ╚════██║
//    ╚██████╗██║  ██║███████╗╚██████╗██║  ██╗    ██║  ██║██║╚██████╔╝██║  ██║   ██║   ███████║
//     ╚═════╝╚═╝  ╚═╝╚══════╝ ╚═════╝╚═╝  ╚═╝    ╚═╝  ╚═╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
                                                                                                                                                                                           
Object.entries(legsWithPrice).forEach(([usedCurrencyPair, legs]) => {
    const CurrencyWithNoSpotAndForwardRight = getCurrencyWithNoSpotAndForwardRights(
      legs as ProductLegItem[],
      hasForwardRight,
      hasSpotRight,
    );

    if (rfsState === 'Request' && CurrencyWithNoSpotAndForwardRight && usedCurrencyPair === currencyPair) {
      dispatch(errorOnRequest(usedCurrencyPair));
    }
  });
  // ---------------------------------- -----
//   ███████╗████████╗ ██████╗ ██████╗   ██████╗ ███████╗███████╗     █████╗ ███████╗████████╗███████╗██████╗    ███╗   ███╗██╗███████╗███████╗██╗███╗   ██╗ ██████╗   ██╗  ██╗███████╗ █████╗ ██████╗ ████████╗██████╗ ███████╗ █████╗ ████████╗
//   ██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗  ██╔══██╗██╔════╝██╔════╝    ██╔══██╗██╔════╝╚══██╔══╝██╔════╝██╔══██╗   ████╗ ████║██║██╔════╝██╔════╝██║████╗  ██║██╔════╝   ██║  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝
//   ███████╗   ██║   ██║   ██║██████╔╝  ██████╔╝█████╗  ███████╗    ███████║█████╗     ██║   █████╗  ██████╔╝   ██╔████╔██║██║███████╗███████╗██║██╔██╗ ██║██║  ███   ███████║█████╗  ███████║██████╔╝   ██║   ██████╔╝█████╗  ███████║   ██║   
//   ╚════██║   ██║   ██║   ██║██╔═══╝   ██╔══██╗██╔══╝  ╚════██║    ██╔══██║██╔══╝     ██║   ██╔══╝  ██╔══██╗   ██║╚██╔╝██║██║╚════██║╚════██║██║██║╚██╗██║██║   ██   ██╔══██║██╔══╝  ██╔══██║██╔══██╗   ██║   ██╔══██╗██╔══╝  ██╔══██║   ██║   
//   ███████║   ██║   ╚██████╔╝██║       ██║  ██║██║     ███████║    ██║  ██║██║        ██║   ███████╗██║  ██║   ██║ ╚═╝ ██║██║███████║███████║██║██║ ╚████║╚██████╔   ██║  ██║███████╗██║  ██║██║  ██║   ██║   ██████╔╝███████╗██║  ██║   ██║   
//   ╚══════╝   ╚═╝    ╚═════╝ ╚═╝       ╚═╝  ╚═╝╚═╝     ╚══════╝    ╚═╝  ╚═╝╚═╝        ╚═╝   ╚══════╝╚═╝  ╚═╝   ╚═╝     ╚═╝╚═╝╚══════╝╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═════╝ ╚══════╝╚═╝  ╚═╝   ╚═╝   
                                                                                                                                                                                                                                 

  let waitingTimeoutInMilliseconds = 5 * ONE_MINUTE_IN_MILLISECONDS; // first event can be long

  const updateTimeout = (action: PayloadAction<MultipassHeartbeat>) => {
    const multipassMaxHeartbeatInterval =
      action.payload.Heartbeat.Interval * ONE_SECOND_IN_MILLISECONDS;

    waitingTimeoutInMilliseconds = 2 * multipassMaxHeartbeatInterval;
  };

  // ---------------------------------------

  let nextActionExecution = await take(actionPredicate, waitingTimeoutInMilliseconds);

  while (nextActionExecution !== null) {
    const [action, currentState, previousState] = nextActionExecution;

    if (heartbeat.match(action)) {
      updateTimeout(action);
    }

    if (quoteAbort.match(action)) {
      break;
    }

    nextActionExecution = await take(actionPredicate, waitingTimeoutInMilliseconds);
  }

  if (nextActionExecution === null) {
    const currencyPair = selectCurrencyPairByRfsId(state, payload.rfsId);
    let rfs = null;

    if (isDefined(currencyPair)) {
      rfs = selectRfsByCurrencyPair(state, currencyPair);
    }

    if (isDefined(rfs) && rfs.executionStatus !== 'Done') {
      logger.logInformation(`cancel RFS after a missing heartbeat`);
      dispatch(cancelQuote.initiate(payload.rfsId));
    }
    // Else { already cancel }
  }
}
