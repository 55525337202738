import { FormattedMessage } from 'react-intl';

function UnableToContinuePage() {
  return (
    <main className="p-0 d-flex flex-column">
      <div className="container mb-3">
        <div className="mt-48px">
          <div className="d-flex justify-content-between">
            <h2 className="display-3">
              <FormattedMessage id="unableToContinuePage.title" />
            </h2>
          </div>
        </div>
      </div>
      <div className="bg-lvl2 flex-column flex-grow-1 border-top d-flex gap-8px align-items-center justify-content-center text-secondary">
        <i className="icon icon-xl icon-outlined">info</i>
        <h2>
          <FormattedMessage id="unableToContinuePage.body" />
        </h2>
        <p className='text-center'>
          <FormattedMessage id="unableToContinuePage.description" values={{ br: <br /> }} />
        </p>
      </div>
    </main>
  );
}

export default UnableToContinuePage;
