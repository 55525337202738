import { useAppDispatch, useAppSelector } from '@/features/hooks';
import { FormattedMessage } from 'react-intl';
import { useCallback } from 'react';
import { productValidationRequested, selectProduct } from '@/features/product/productSlice';
import { selectChoosenBdrId, selectCompanies, selectCompanyDataStatus } from '@/features/user/userSlice';
import { isNotDefined } from '@sgme/fp';
import { useFetchUserCurrentQuery } from '@/features/api/web.api';
import { Step, stepChanged } from '@/features/step/stepSlice';
import { CompanyItemForWidget } from './CompanyItemForWidget';

export interface clickSelectProps {
  onClickSelect: (id: number) => void;
}

export function SelectCompanyForWidgetSource(): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const companyDataStatus = useAppSelector(selectCompanyDataStatus);

  const companiesList = useAppSelector(selectCompanies);
  const allCompanies = Object.values(companiesList).sort((a, b) =>
    a.companyName.localeCompare(b.companyName),
  );

  const { legsByCurrencyPair } = useAppSelector(selectProduct);
  const chosenBdrId = useAppSelector(selectChoosenBdrId);

  const { isFetching, isSuccess, isError } = useFetchUserCurrentQuery();

  const onClickChoose = useCallback(() => {
    dispatch(productValidationRequested());
  }, [dispatch]);

  if (isNotDefined(legsByCurrencyPair)) {
    return null;
  }

  const legsLength = Object.values(legsByCurrencyPair).flat().length;

  if (isError) {
    dispatch(stepChanged(Step.CannotProceed));
  }

  return (
    <main className="p-0 d-flex flex-column ">
      <SelectCompanyForWidgetHeader
        legsLength={legsLength}
        onClickChoose={onClickChoose}
        chosenBdrId={chosenBdrId}
      />

      <div className="bg-lvl2 flex-grow-1 border-top pb-48px">
        <div className="container mb-3">
          <div className="d-flex flex-column mt-48px">
            <h4 className="d-flex justify-content-between text-secondary mb-1">
              <FormattedMessage id={`SelectCompany.title`} />
            </h4>

            {isFetching && (
              <div className="spinner-grow" role="status">
                <FormattedMessage id="alerts.loading" />
              </div>
            )}
            {isSuccess &&
              allCompanies.map((company) => (
                <CompanyItemForWidget
                  key={company.companyBdrId}
                  company={company}
                  companyDataStatus={companyDataStatus}
                />
              ))}
          </div>
        </div>
      </div>
    </main>
  );
}

// ██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗
// ██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
// ██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
// ██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝

function SelectCompanyForWidgetHeader({
  legsLength,
  chosenBdrId,
  onClickChoose,
}: {
  legsLength: number;
  chosenBdrId?: number;
  onClickChoose: () => void;
}) {
  return (
    <div className="container mb-3">
      <div className="d-flex justify-content-between mt-48px mb-24px">
        <div className="d-flex flex-column">
          <>
            <h2 className="display-3">
              <FormattedMessage id="SelectCompany.header" />
            </h2>
            <h5 className="mt-2">
              <FormattedMessage id="SelectCompany.subHeader" />
            </h5>
          </>
        </div>
        <div className="my-auto">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            disabled={!chosenBdrId}
            onClick={onClickChoose}
          >
            <div className="lx-auto" data-e2e="select-company-next">
              <FormattedMessage id="SelectCompany.next" values={{ operationsCount: legsLength }} />
              &nbsp;
              <i className="icon icon-sm ms-2">arrow_forward</i>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
