import { getConfig } from '@/config/config';
import {
  MultipassDealDone,
  MultipassDealRejected,
  MultipassFillReport,
  MultipassHeartbeat,
  MultipassQuoteAbort,
  MultipassQuoteReply,
} from '@/models/rfs';
import { AppDispatch } from '@/state/store';
import { logger } from '@/utils/logger';
import { retry } from '@/utils/retry';
import { getFakeUser } from '@/widgets/fakeUser';
import { sgwtConnect } from '@/widgets/sgwtConnect';
import { HubConnection, HubConnectionBuilder, LogLevel, RetryContext } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { isDefined } from '@sgme/fp';
import {
  dealDone,
  dealRejected,
  fillReport,
  heartbeat,
  quoteAbort,
  quoteWithdraw,
} from './rfs/rfsSlice';
import { quoteReceivedThunk } from './rfs/rfsThunks';
import { streamingStateChanged } from './streaming/streamingSlice';

const {
  signalr: { hub_url, reconnect_delay },
} = getConfig();

function getAuthorizationToken(): string | undefined {
  const authorizationHeader = sgwtConnect.getAuthorizationHeader();
  if (authorizationHeader === null) {
    return undefined;
  }
  return authorizationHeader.replace('Bearer ', '');
}

function getHubUrl(): string {
  const fakeUser = getFakeUser();
  return `${hub_url}/${isDefined(fakeUser) ? '?user=' + fakeUser : ''}`;
}

function createSignalrConnection(): HubConnection {
  return new HubConnectionBuilder()
    .withUrl(getHubUrl(), {
      accessTokenFactory: () => getAuthorizationToken() ?? '',
    })
    .withHubProtocol(new MessagePackHubProtocol())
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
        const {
          previousRetryCount,
          elapsedMilliseconds,
          retryReason: { stack: retryReasonStack },
        } = retryContext;
        logger.logWarning(
          'Attempt to auto reconnect. Retry count: {previousRetryCount_n}. Time spend reconnecting in ms: {elapsedMilliseconds_n}. Reason: {retryReasonStack_s}',
          previousRetryCount,
          elapsedMilliseconds,
          JSON.stringify(retryReasonStack),
        );

        if (retryContext.elapsedMilliseconds < 60 * 1000) {
          return reconnect_delay;
        } else {
          return reconnect_delay * 10;
        }
      },
    })
    .build();
}

export const signalrConnection = createSignalrConnection();

const maxRetries = Number.MAX_SAFE_INTEGER;
const delayMs = 1_000;

export function connectToSignalr(dispatch: AppDispatch) {
  const startConnection = () =>
    retry(() => signalrConnection.start(), { maxRetries, delayMs }).then(
      () => {
        const connectionId = signalrConnection.connectionId!;
        logger.logInformation('Established signalR connection for {connectionId}', connectionId);
        dispatch(streamingStateChanged({ state: 'connected', connectionId }));
      },
      () => {
        logger.logError(
          'Fail to establish signalR connection for {connectionId}',
          signalrConnection.connectionId,
        );
      },
    );

  window.addEventListener('offline', async function () {
    await signalrConnection.stop();
  });

  window.addEventListener('online', startConnection);

  startConnection();

  signalrConnection.onreconnected((connectionId = signalrConnection.connectionId!) => {
    logger.logWarning('Re-established signalR connection for {connectionId}', connectionId);
    dispatch(streamingStateChanged({ state: 'connected', connectionId }));
  });
  signalrConnection.onclose(() => {
    logger.logError('Lost signalR connection for {connectionId}', signalrConnection.connectionId);
    dispatch(streamingStateChanged({ state: 'disconnected', connectionId: undefined }));
    // Try to reconnect right away
    startConnection();
  });
  signalrConnection.on('ReceiveDuplicatedConnection', () => {
    logger.logWarning(
      'Duplicate signalR connection for {connectionId}',
      signalrConnection.connectionId,
    );
    dispatch(streamingStateChanged({ state: 'disconnected', connectionId: undefined }));
  });

  signalrConnection.on('ReceiveQuoteReply', (payload: MultipassQuoteReply) => {
    dispatch(quoteReceivedThunk(payload));
  });

  signalrConnection.on('ReceiveHeartbeat', (payload: MultipassHeartbeat) => {
    dispatch(heartbeat(payload));
  });

  signalrConnection.on('ReceiveQuoteAbort', (payload: MultipassQuoteAbort) => {
    dispatch(quoteAbort(payload));
  });

  signalrConnection.on('ReceiveQuoteWithdraw', (payload: MultipassQuoteReply) => {
    dispatch(quoteWithdraw(payload));
  });

  signalrConnection.on('ReceiveDealRejected', (payload: MultipassDealRejected) => {
    dispatch(dealRejected(payload));
  });
  signalrConnection.on('ReceiveDealReply', (payload: MultipassDealDone) => {
    dispatch(dealDone(payload));
  });
  signalrConnection.on('ReceiveFillReport', (payload: MultipassFillReport) => {
    dispatch(fillReport(payload));
  });
}
