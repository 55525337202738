import { SgErrorPage } from '@sg-bootstrap/components/dist/react-library/src/components';
import { isDefined } from '@sgme/fp';
import { useIntl } from 'react-intl';

function ErrorPage() {
  const url = window.location.href.split('/?user=');
  const linkButton = isDefined(url[1]) ? `/?user=${url[1]}` : '/';
  const { formatMessage } = useIntl();

  return (
    <div className="d-flex align-items-center justify-content-center">
      <SgErrorPage
        code={500}
        action-button-label={formatMessage({id:'errorPage-action-button'})}
        description-label={formatMessage({id:'errorPage-description-label'})}
        title-label={formatMessage({id:'errorPage-title-label'})}
        actionButtonLink={linkButton}
        aria-braillelabel={undefined}
        aria-brailleroledescription={undefined}
        aria-colindextext={undefined}
        aria-description={undefined}
        aria-rowindextext={undefined}
        placeholder=""
      />
    </div>
  );
}

export default ErrorPage;
