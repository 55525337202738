import { selectProductByChosenBdrId, useGetProductsQuery } from '@/features/api/fxkit.api';
import { selectexecutedTrades } from '@/features/executedTrades/executedTradesSlice';
import { useAppDispatch, useAppSelector } from '@/features/hooks';
import { productValidationRequested } from '@/features/product/productSlice';
import { CompanyDataStatus, chooseBdrId } from '@/features/user/userSlice';
import { Company } from '@/models/user';
import { isDefined } from '@sgme/fp';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { CompanyTooltip } from './CompanyTooltip';

export interface CompanyItemForTradeRetrieverSourceProps {
  company: Company;
  companyDataStatus: CompanyDataStatus;
}

export function CompanyItemForTradeRetrieverSource({ company, companyDataStatus }: CompanyItemForTradeRetrieverSourceProps) {
  const dispatch = useAppDispatch();
  const { isLoading, isError } = useGetProductsQuery();

  const bulkTradeFromCompany = useAppSelector((state) =>
    selectProductByChosenBdrId(state, `${company.companyBdrId}`),
  );

  const executedTrades = useAppSelector(selectexecutedTrades)[company.companyBdrId];
  const shouldShowExecutedTrades = isDefined(executedTrades) && executedTrades.length > 0;

  const numberOfTradesExecuted = isDefined(executedTrades) ? executedTrades.length : 0;

  const numberOfTradesToSelect = isDefined(bulkTradeFromCompany)
    ? bulkTradeFromCompany.length! - numberOfTradesExecuted
    : 0;

  const isEveryTradeExecuted = numberOfTradesToSelect === 0;

  const onClickChoose = useCallback(() => {
    dispatch(chooseBdrId(company.companyBdrId));
    dispatch(productValidationRequested());
  }, []);

  return isLoading || isError ? null : (
    <div
      className={`border border-md col-xs-12 col-lg-9  p-3 mt-2 bg-primary-alt border-md border-light`}
    >
      <div className="d-flex justify-content-between ">
        <label className={`d-flex flex-row align-items-center fw-medium`}>
          <CompanyTooltip
            company={company}
            companyDataStatus={companyDataStatus}
            placement="left"
          />

          <span className="flex-fill ">{company.companyName}</span>
          {shouldShowExecutedTrades && (
            <div className="badge badge-discreet-success badge-md rounded-pill ms-2 pt-1">
              <FormattedMessage
                id="SelectCompany.executed"
                values={{ count: executedTrades.length }}
              />
            </div>
          )}
        </label>

        <div className="my-auto">
          <button
            type="button"
            disabled={isEveryTradeExecuted}
            className="btn btn-flat-primary"
            data-e2e={`${company.companyName}-select-company-next`}
            onClick={onClickChoose}
          >
            <FormattedMessage
              id="SelectCompany.select"
              values={{ count: numberOfTradesToSelect, isEveryTradeExecuted }}
            />
            <i className="icon icon-sm ms-2">arrow_forward</i>
          </button>
        </div>
      </div>
    </div>
  );
}
