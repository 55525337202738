import { CurrencyPairProvider } from '@/components/contexts/CurrencyPair';
import { PricedBulkLeg } from '@/models/rfs';

import { ProductLeg } from './ProductLeg';
import { ProductLegItem, isCurrencyPairAllowed } from './shared';
import { assertIsDefined, isDefined, isEmpty, isNotDefined } from '@sgme/fp';
import { CurrencyPairHeader } from './leg/CurrencyPairHeader';
import { useState } from 'react';
import ProductByCurrencyPairHeader from './ProductByCurrencyPairHeader';
import { ExecutionStatus, selectIsRejectedOnRequest } from '@/features/rfs/rfsSlice';
import { useAppSelector } from '@/features/hooks';
import { selectChoosenBdrId, selectRfsCurrencies, selectRights } from '@/features/user/userSlice';
import {
  getNoForwardRights,
  getNoSpotRights,
  getRfsState,
  hasRightToDealLeg,
} from '@/utils/rights';
import { selectStep } from '@/features/step/stepSlice';

interface ProductByCurrencyPairProps {
  ccyPair: string;
  legs: readonly PricedBulkLeg[];
  backOfficeIds: readonly string[];
  isReferenceVisible: boolean;
  executionStatus: ExecutionStatus;
}

const errorStatus = ['Rejected', 'Timeout', 'ClientLatencyAborted'];

export function ProductByCurrencyPair({
  ccyPair,
  legs,
  isReferenceVisible,
  executionStatus,
}: ProductByCurrencyPairProps): React.ReactElement {
  const [isExpanded, setIsExpanded] = useState(true);
  const isRejectedOnRequest = useAppSelector(state => selectIsRejectedOnRequest(state, ccyPair));

  const step = useAppSelector(selectStep);
  const rfsState = getRfsState(step);

  const bdrId = useAppSelector(selectChoosenBdrId);
  assertIsDefined(bdrId, 'Cannot get bdrId');
  const rfsCurrenciesAllowed = useAppSelector(state => selectRfsCurrencies(state, bdrId));
  const { hasForwardRight, hasSpotRight } = useAppSelector(selectRights);

  const modifiedLegs = legs as ProductLegItem[];

  const hasNoSpotRights = getNoSpotRights(modifiedLegs, hasSpotRight);
  const hasNoForwardRights = getNoForwardRights(modifiedLegs, hasForwardRight);

  const isStatusOnError = isDefined(executionStatus) && errorStatus.includes(executionStatus);

  const isCurrencyPairOnWarning = modifiedLegs.some(leg => isDefined(leg.warnings));
  const isCurrencyPairOnError = modifiedLegs.some(leg => isDefined(leg.errors));

  const filteredLegs = legs.filter(
    leg => leg.isSelectedForExecution && isNotDefined(leg.errors) && isNotDefined(leg.warnings),
  );

  const isExecutedAndNotSelected =
    isReferenceVisible &&
    ((filteredLegs.length === 0 && !isStatusOnError) ||
      isCurrencyPairOnWarning ||
      isCurrencyPairOnError ||
      isRejectedOnRequest ||
      !isCurrencyPairAllowed(rfsCurrenciesAllowed, ccyPair));

  return (
    <CurrencyPairProvider value={ccyPair}>
      <div className="d-flex flex-column" data-e2e={`${ccyPair}-deals`}>
        {!isEmpty(modifiedLegs) && !isExecutedAndNotSelected && (
          <>
            <CurrencyPairHeader
              currencyPair={ccyPair}
              filteredLegsLength={filteredLegs.length}
              isOpen={isExpanded}
              rfsState={rfsState}
              legs={modifiedLegs}
              isStatusOnError={isStatusOnError}
              hasForwardRights={hasForwardRight}
              hasSpotRights={hasSpotRight}
              rfsCurrenciesAllowed={rfsCurrenciesAllowed}
              setIsOpen={setIsExpanded}
            />

            {!isEmpty(modifiedLegs) && (
              <>
                <ProductByCurrencyPairHeader
                  rfsState={rfsState}
                  currencyPair={ccyPair}
                  isExpanded={isExpanded}
                  isCurrencyPairOnError={isCurrencyPairOnError || isStatusOnError}
                  isCurrencyPairOnWarning={isCurrencyPairOnWarning || isStatusOnError}
                  hasNoForwardRights={hasNoForwardRights}
                  hasNoSpotRights={hasNoSpotRights}
                  rfsCurrenciesAllowed={rfsCurrenciesAllowed}
                  isReferenceVisible={isReferenceVisible}
                  legsLentgh={legs.length}
                />

                {modifiedLegs.map((leg, index) => {
                  const hasRights = hasRightToDealLeg(leg, hasForwardRight, hasSpotRight);

                  return !hasRights && isReferenceVisible ? null : (
                    <ProductLeg
                      key={index}
                      leg={leg}
                      isExpanded={isExpanded}
                      rfsState={rfsState}
                      isRejectedOnRequest={isRejectedOnRequest}
                      isStatusOnError={isStatusOnError}
                      hasNoForwardRights={hasNoForwardRights}
                      hasNoSpotRights={hasNoSpotRights}
                      rfsCurrenciesAllowed={rfsCurrenciesAllowed}
                      index={index}
                      isReferenceVisible={isReferenceVisible}
                    />
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </CurrencyPairProvider>
  );
}

// -------------------------------------------------------
