import { createContext, useContext } from 'react';

const defaultValue = '@@_MISSING_CCY_PAIR';
const CurrencyPairContext = createContext<string>(defaultValue);

export function useCurrencyPair() {
  const currencyPair = useContext(CurrencyPairContext);
  if (currencyPair === defaultValue) {
    throw new Error('You did not provide a currency pair with CurrencyPairProvider!!!');
  }
  return currencyPair;
}

export const CurrencyPairProvider = CurrencyPairContext.Provider;
