import { isCurrencyPairAllowed } from '@/components/content/product/shared';
import { errorChanged } from '@/features/error/errorSlice';
import { getLegsForCurrencyPair } from '@/features/product/productSlice';
import { requestStream } from '@/features/rfs/rfsAction';
import { streamStarted, tmsReady } from '@/features/rfs/rfsSlice';
import { Step, stepChanged } from '@/features/step/stepSlice';
import { selectStreamingInfo } from '@/features/streaming/streamingSlice';
import { selectChoosenBdrId, selectRfsCurrencies, selectRights } from '@/features/user/userSlice';
import { mapToMultipassQuoteRequest } from '@/models/transform/multipass/toMultipass';
import { streamingConnectedMatcher } from '@/state/listeners/matchers';
import { AppListenerEffectAPI } from '@/state/store';
import { logger } from '@/utils/logger';
import { hasRightToDealLeg } from '@/utils/rights';
import { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { assertIsDefined, isNotDefined } from '@sgme/fp';

export async function requestQuoteAfterProductReceived(
  { payload: currencyPair }: ReturnType<typeof requestStream>,
  {
    getState,
    dispatch,
    take,
    extra: {
      webApiEndpoints: { requestQuote },
      getNewRfsId,
    },
  }: AppListenerEffectAPI,
) {
  const state = getState();

  const bdrId = selectChoosenBdrId(state);
  const rfsCurrenciesAllowed = selectRfsCurrencies(state, bdrId ?? 0);
  assertIsDefined(bdrId, 'cannot request quote without bdrId');

  const { hasForwardRight, hasSpotRight } = selectRights(state);

  const legs = getLegsForCurrencyPair(state, currencyPair).filter((leg) => (
    leg.isSelectedForExecution &&
    isNotDefined(leg.errors) &&
    isNotDefined(leg.warnings) &&
    isCurrencyPairAllowed(rfsCurrenciesAllowed, currencyPair) &&
    hasRightToDealLeg(leg, hasForwardRight, hasSpotRight)
  ));

  let { connectionId } = selectStreamingInfo(state);

  if (connectionId === undefined) {
    const waitForStreaming = await take(streamingConnectedMatcher);
    connectionId = waitForStreaming[0].payload.connectionId!;
  }

  const rfsId = getNewRfsId();

  dispatch(streamStarted({ rfsId, currencyPair }));

  const isTmsReady = await window?.xprops?.onProductPriced(rfsId);

  logger.logInformation('Call onProductPriced with rfsId: {rfsId}, isTmsReady: {isTmsReady}', rfsId, isTmsReady);

  if (isTmsReady === true) {
    dispatch(tmsReady(rfsId));
  }

  if (legs.length !== 0) {
    const response = await dispatch(
      requestQuote.initiate(mapToMultipassQuoteRequest(bdrId, rfsId, connectionId, legs)),
    );

    if ('error' in response) {
      dispatch(errorChanged(extractErrorMessage(response.error)));
      dispatch(stepChanged(Step.RequestRejected));
    }
  }
}

function extractErrorMessage(error: FetchBaseQueryError | SerializedError): string {
  if ('error' in error) {
    return error.error;
  } else if ('data' in error && error.data) {
    return String(error.data);
  } else if ('message' in error && error.message) {
    return error.message;
  } else {
    return 'Unknown error';
  }
}
