import { ISGWTConnectConfiguration } from '@sgwt/connect-core';

export interface AppConfig {
  useFakeSgConnect: boolean;
  fake_user?: string;
  fake_iam?: string;
  env: string;
  sgwt_env: 'homologation' | 'production';
  sgconnect: ISGWTConnectConfiguration;
  matomo: { url: string; site_id: string };
  api_url: string;
  api_pretrade_url: string;
  signalr: {
    hub_url: string;
    reconnect_delay: number;
  };
  access_request_url: string;
}

export function getConfig(): AppConfig {
  return window.sgmeConfiguration;
}
