import { PricedBulkLeg } from '@/models/rfs';

// -------------------------------------------------------

export type ProductLegItem = PricedBulkLeg & { tradeId: string | undefined };

// -------------------------------------------------------

export const trade_id_column_width = 140;
export const rate_column_width = 140;

export function isCurrencyPairAllowed(rfsCurrenciesAllowed: string[], currencyPair: string) {
  return rfsCurrenciesAllowed.includes(currencyPair.split('/')[0]) && rfsCurrenciesAllowed.includes(currencyPair.split('/')[1]);
}
