import { AccountCenter } from '@/components/sgwt/AccountCenter';
import { useAppSelector } from '@/features/hooks';
import { selectNavigableUsersList, selectSelectedUser } from '@/features/user/userSlice';

export function Header() {
  const navigableUsersList = useAppSelector(selectNavigableUsersList);
  const selectedUser = useAppSelector(selectSelectedUser);
  const canNavigateAs = selectedUser?.canNavigateAs;

  return (
    <div className="mb-3">
      <header className="navbar navbar-lg">
        <div className="navbar-title ms-4">
          <div className="navbar-logo">
            <svg height="32" width="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <g>
                <rect fill="#e60028" width="32" height="16"></rect>
                <rect fill="#000" y="16" width="32" height="16"></rect>
                <rect fill="#fff" x="6" y="15" width="20" height="2"></rect>
              </g>
            </svg>
          </div>
          <div className="navbar-title-divider border mx-3" />
          <div
            className="navbar-service-name bg-lvl1 d-flex align-items-center"
            style={{ width: '8rem' }}
          >
            Hedge Connect
          </div>
        </div>
        <div className="navbar-content me-4">
          <ul className="navbar-navigation m-0 d-flex align-items-center justify-content-between w-100">
            <li className="navbar-item ms-auto">
              <AccountCenter
                navigateAsList={navigableUsersList}
                navigateAsUser={selectedUser}
                canNavigateAs={canNavigateAs}
                // Used to make sure we refresh account center after navigableUsersList has been fetched
                key={JSON.stringify(navigableUsersList)}
              />
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
}
