import { LoadingSkeleton } from '../LoadingSkeleton';
import { useAppSelector } from '@/features/hooks';
import { useDispatch } from 'react-redux';
import { Step, stepChanged } from '@/features/step/stepSlice';

function UnreadyCompanies() {
  const dispatch = useDispatch();
  const fxKitQueries = useAppSelector((state) => state.fxkitApi.queries);
  const productsFromFxKit = Object.values(fxKitQueries);

  const everyProductsIsRejected = productsFromFxKit.every(
    (product) => product?.status === 'rejected',
  );

  if (everyProductsIsRejected) {
    dispatch(stepChanged(Step.CannotProceed));
  }

  return (
    <>
      {productsFromFxKit.map((_product, index) => (
        <div className="mt-48px mb-24px">
          <div key={index} className="card card-bordered mb-2">
            <div className="card-body">
              <LoadingSkeleton />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default UnreadyCompanies;
