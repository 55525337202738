import { FormattedMessage } from 'react-intl';

interface LegTypeProps {
  tradeType: 'Spot' | 'Forward';
}

// -------------------------------------------------------

export function LegType({ tradeType }: LegTypeProps) {
  return (
    <div className="mt-4px" style={{ width: '7rem' }}>
      <div className="badge badge-discreet-primary rounded-pill text-primary ms-2">
        <FormattedMessage id="ExecuteOperations.operation.type" values={{ tradeType }} />
      </div>
    </div>
  );
}
