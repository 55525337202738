import {
  productValidationRequested,
  productRejected,
  productUpdated,
  productValidated,
  productReceived,
  getLegsByCurrencyPair,
  invalidStatus,
} from '@/features/product/productSlice';
import { AppListenerEffectAPI } from '@/state/store';
import {
  CurrencyChoice,
  TradeCaptureBulkErrors,
  TradeCaptureBulkRequest,
  TradeCaptureBulkResponseChanges,
  TradeCaptureBulkWarnings,
} from '@/models/tradeCapture';
import { requestStream } from '@/features/rfs/rfsAction';
import { isDefined, isNotDefined } from '@sgme/fp';
import { BulkLeg } from '@/models/product';
import { mapProductLegToTradeCapture } from '@/utils/adapterInOutTradeCapture';
import { selectChoosenBdrId, productComesFromTradeRetriever } from '@/features/user/userSlice';
import { selectProductByChosenBdrId } from '@/features/api/fxkit.api';
import { selectexecutedTrades } from '@/features/executedTrades/executedTradesSlice';
import { ProductLegFromTms } from '&types/hedgeconnect';

export async function validateProduct(
  _action: ReturnType<typeof productValidationRequested>,
  {
    dispatch,
    getState,
    extra: {
      getNewGuid,
      webApiEndpoints: { requestTradeCapture },
    },
  }: AppListenerEffectAPI,
) {
  const state = getState();
  const isFromTradeRetriever = productComesFromTradeRetriever(state);
  const chosenBdrId = selectChoosenBdrId(state);
  const executedTrades = selectexecutedTrades(state);
  let usedBulkTrade: ProductLegFromTms[] = [];

  if (isFromTradeRetriever && isDefined(chosenBdrId)) {
    // https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#selecting-values-from-results
    const productFromChosenBdrId = selectProductByChosenBdrId(getState(), `${chosenBdrId}`);
    if (isDefined(productFromChosenBdrId)) {
      // @todo duplicate, need to find a better way
      usedBulkTrade = productFromChosenBdrId;

      // We can go back to validate Product after execution of some product
      // We need to exclude executed trades
      if (isDefined(executedTrades) && isDefined(executedTrades[chosenBdrId])) {
        usedBulkTrade = productFromChosenBdrId.filter(
          (leg) => !executedTrades[chosenBdrId].includes(leg.thirdPartyTradeReference!),
        );
      }

      // Partial or total of bultrade (exclude already executed deal)
      dispatch(productReceived({ bulkTrade: usedBulkTrade }));
    } else {
      dispatch(invalidStatus('NoProduct'));
      return;
    }
  }

  const legsByCurrencyPair = getLegsByCurrencyPair(getState());
  const currencyPairs = Object.keys(legsByCurrencyPair);

  if (currencyPairs.length === 0) {
    dispatch(invalidStatus('NoProduct'));
    return;
  }

  await Promise.all(
    currencyPairs.map(async (currencyPair) => {
      /**
       * get amount currency the same for all bulk leg inside currencyPair
       * product: { legByCurrencyPair : { 'EUR/USD' : [ { amountCurrency: 'USD'}, { amountCurrency: 'USD' } ] } }
       * get amount currency => 'USD'
       */
      const { amountCurrency } = legsByCurrencyPair[currencyPair][0];
      const negotiatedCurrency: CurrencyChoice = currencyPair.startsWith(amountCurrency) ? 1 : 2;

      const payload: TradeCaptureBulkRequest = {
        idVersion: 0,
        changedFields: {
          currencyPair,
          negotiatedCurrency,
          legs: toObject(
            legsByCurrencyPair[currencyPair],
            (_leg, index) => String(index),
            mapProductLegToTradeCapture,
          ),
        },
      };

      const sessionId = getNewGuid();
      try {
        const { data } = await dispatch(requestTradeCapture.initiate({ sessionId, payload }));
        if (!isDefined(data)) {
          dispatch(invalidStatus('NoProduct'));
          return;
        }
        const { errors, warnings, changedFields } = data;

        const currencyPair = changedFields.currencyPair;

        if (isNotDefined(currencyPair)) {
          dispatch(invalidStatus('NoProduct'));
          return;
        }
        if (
          isEveryLegOnErrorOrWarning(errors, changedFields) ||
          isEveryLegOnErrorOrWarning(warnings, changedFields)
        ) {
          dispatch(productRejected({ errors, warnings, currencyPair }));
          return;
        }

        dispatch(productUpdated({ changedFields, currencyPair })); // trade capture fixed the product, with no errors and no warnings

        const {
          product: { legsByCurrencyPair },
        } = getState();

        if (hasLegsWithWarnings(legsByCurrencyPair[currencyPair])) {
          dispatch(productRejected({ errors, warnings, currencyPair }));
          return;
        }

        dispatch(productValidated());
        dispatch(requestStream(currencyPair));
      } catch (e) {
        // TODO: handle the error
        dispatch(requestStream(currencyPair));
      }
    }),
  );
}

type MapItemToArgs<In> = [value: In, currentIndex: number, array: readonly In[]];
type MapItemTo<In, Out> = (...args: MapItemToArgs<In>) => Out;

/**
 * Generate an object from an array, key & value mappers
 * @param array The array
 * @param mapToKey Map to the key of the new object
 * @param mapToValue Map to the value of the new object
 * @returns The current iteration of the object being generated by `Array.prototype.reduce`
 */
function toObject<T, K extends string, V>(
  array: readonly T[],
  mapToKey: MapItemTo<T, K>,
  mapToValue: MapItemTo<T, V>,
) {
  const obj = {} as Record<K, V>;
  for (let index = 0; index < array.length; index++) {
    obj[mapToKey(array[index], index, array)] = mapToValue(array[index], index, array);
  }
  return obj;
}

function hasLegsWithWarnings(legs: readonly BulkLeg[]): boolean {
  if (legs.every((leg) => leg.warnings)) {
    return true;
  }
  return false;
}

function isEveryLegOnErrorOrWarning(
  ErrorOrWarning: TradeCaptureBulkWarnings | null | TradeCaptureBulkErrors,
  changedFields: TradeCaptureBulkResponseChanges,
) {
  return (
    isDefined(ErrorOrWarning) &&
    isDefined(ErrorOrWarning?.legs) &&
    Object.values(ErrorOrWarning.legs).length === Object.values(changedFields.legs).length
  );
}
