import { getConfig } from '@/config/config';

// WARNING: stay those functions here and not in the ./sgwtConnect.ts; without that, unit tests crash

export function getFakeUser(): string | undefined {
  return getQueryParamFromUrl('user') ?? getConfig().fake_user;
}

export function getQueryParamFromUrl(queryParam: string): string | undefined {
  const params = new URLSearchParams(self.location.search);
  return (params.has(queryParam) && params.get(queryParam)) || undefined;
}

export function getFakeIam(): string | undefined {
  return getQueryParamFromUrl('iam') ?? getConfig().fake_iam;
}
