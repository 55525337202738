import { useEffect, useState } from 'react';

export const useTimeout = (durationInMilliseconds: number) => {
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTimeout(true);
    }, durationInMilliseconds);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return isTimeout
};
