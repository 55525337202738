import { useAppSelector } from '@/features/hooks';
import { selectProduct } from '@/features/product/productSlice';
import { selectAllRfsIds, selectCurrencyPair, selectErrorCodeForAllCurrencies } from '@/features/rfs/rfsSlice';
import { useEffect } from 'react';
import { AllProductsByCurrencyPair } from '../content/product/AllProductsByCurrencyPair';
import StreamingErrorHeader from './StreamingErrorHeader';

interface StreamingErrorProps {
  type: 'Request' | 'Execution';
}

export function StreamingError({ type }: StreamingErrorProps) {
  const rfsIds = useAppSelector(selectAllRfsIds);

  const allRfsErrorCodes = useAppSelector(selectErrorCodeForAllCurrencies);

  const { legsByCurrencyPair } = useAppSelector(selectProduct);
  const getCurrencyPair = useAppSelector(selectCurrencyPair);

  const operationsInError = Object.values(allRfsErrorCodes).reduce((acc, { rfsId }) => {
    const ccy = getCurrencyPair(rfsId);
    acc += legsByCurrencyPair[ccy].length;
    return acc;
  }, 0);

  useEffect(() => {
    rfsIds.forEach((rfsId) => {
      window?.xprops?.onDealNotDone(rfsId);
    });
  }, []);

  return (
    <main className="p-0 d-flex flex-column">
      <StreamingErrorHeader operationsInError={operationsInError} type={type} />
      <div className="bg-lvl2 flex-grow-1 border-top pb-48px">
        <div className="container mb-3 ">
          <AllProductsByCurrencyPair />
        </div>
      </div>
    </main>
  );
}
