import styled from '@emotion/styled';
import { isDefined } from '@sgme/fp';
import { useCurrencyPair } from '@/components/contexts/CurrencyPair';
import { currencies } from '@/data/currencies';
import { ProductLegItem, isCurrencyPairAllowed } from './shared';
import { LegRate } from './leg/LegRate';
import { LegSummary } from './leg/LegSummary';
import { LegTradeId } from './leg/LegTradeId';
import { LegWay } from './leg/LegWay';
import { Checkbox } from '@/components/Checkbox';
import { useAppDispatch, useAppSelector } from '@/features/hooks';
import { LegType } from './leg/LegType';
import { LegDate } from './leg/LegDate';
import { useEffect } from 'react';
import { currencyPairSelectedForExecution, unselectDeal } from '@/features/product/productSlice';
import { productComesFromTradeRetriever } from '@/features/user/userSlice';

// -------------------------------------------------------

interface ProductLegsProps {
  leg: ProductLegItem;
  index: number;
  isReferenceVisible: boolean;
  isRejectedOnRequest: boolean;
  isStatusOnError: boolean;
  rfsCurrenciesAllowed: string[];
  hasNoSpotRights: boolean;
  hasNoForwardRights: boolean;
  isExpanded: boolean;

  rfsState: 'Request' | 'Execution';
}

// -------------------------------------------------------

export function ProductLeg({
  leg,
  index,
  isReferenceVisible,
  isRejectedOnRequest,
  isStatusOnError,
  rfsState,
  rfsCurrenciesAllowed,
  hasNoSpotRights,
  hasNoForwardRights,
  isExpanded,
}: ProductLegsProps): React.ReactElement | null {
  const ccyPair = useCurrencyPair();
  const dispatch = useAppDispatch();
  const { precision: spotPrecision } = currencies[ccyPair];
  const areCurrenciesAllowed = isCurrencyPairAllowed(rfsCurrenciesAllowed, ccyPair);

  const {
    displayWay,
    amount,
    amountCurrency,
    expiryDate,
    spot,
    fwdPoints,
    type,
    tradeId,
    errors,
    warnings,
    isSelectedForExecution,
    sgReference,
    currencyPair,
    thirdPartyTradeReference,
  } = leg;

  const hasErrors = isDefined(errors) || isStatusOnError;
  const hasWarnings = isDefined(warnings) || isStatusOnError;

  useEffect(() => {
    if (isStatusOnError || !areCurrenciesAllowed) {
      dispatch(
        currencyPairSelectedForExecution({
          currencyPair,
          isSelected: false,
        }),
      );
    }
  }, [areCurrenciesAllowed, currencyPair, dispatch, isStatusOnError]);

  const shouldDisplayError =
    hasErrors ||
    hasWarnings ||
    (hasNoSpotRights && type === 'Spot') ||
    (hasNoForwardRights && type === 'Forward') ||
    !areCurrenciesAllowed;

  const allowDealSelection = useAppSelector(productComesFromTradeRetriever);
  const isExecuted = rfsState === 'Execution';

  if (!isExpanded) return null;

  return (
    <>
      <div className={getProductLegClassName(index === 0, shouldDisplayError)}>
        <div className="d-flex align-items-center justify-content-between m-3">
          <div className="d-flex">
            <div className="d-flex gap-5">
              {!isExecuted && allowDealSelection && (
                <Checkbox
                  warnings={warnings}
                  errors={errors}
                  isRejectedOnRequest={isRejectedOnRequest}
                  currencyPair={currencyPair}
                  tradeIndex={index}
                  isSelected={Boolean(isSelectedForExecution)}
                  thirdPartyTradeReference={thirdPartyTradeReference!}
                  shouldDisplayError={shouldDisplayError}
                />
              )}
              <LegWay displayWay={displayWay} hasErrors={hasErrors} hasWarnings={hasWarnings} />
            </div>
            <LegSummary
              displayWay={displayWay}
              amount={amount}
              amountCurrency={amountCurrency}
              index={index}
              errors={errors}
              warnings={warnings}
              sgReference={sgReference}
            />
          </div>

          <div className="d-flex gap-5">
            <LegDate expiryDate={expiryDate} index={index} errors={errors} warnings={warnings} />
            {isReferenceVisible && <LegTradeId tradeId={tradeId} />}
            <LegTooltip>
              <LegRate
                spot={spot}
                spotPrecision={spotPrecision}
                fwdPoints={fwdPoints}
                tradeType={type}
              />
            </LegTooltip>
            <LegType tradeType={type} />
          </div>
        </div>
      </div>
    </>
  );
}

function getProductLegClassName(isFirst: boolean, displayError: boolean) {
  const base = `card border border-light${!isFirst ? ' mt-2' : ''}`;
  if (displayError) {
    return `${base} border-socgen error`;
  }
  return base;
}

// -------------------------------------------------------

const LegTooltip = styled.div`
  & .tooltip-wrapper {
    display: none;
  }

  &:hover .tooltip-wrapper {
    display: block;
  }

  &.warning::before {
    border-color: var(--bs-red);
  }

  &.error::before {
    border-color: var(--bs-red);
  }
`;
