import { ProductLegItem } from '@/components/content/product/shared';
import { Step } from '@/features/step/stepSlice';
import { BulkLeg } from '@/models/product';

const REQUEST_STATES = [
  Step.PriceRequested,
  Step.StreamingPrices,
  Step.StreamEnded,
  Step.RequestRejected,
];
const EXECUTION_STATES = [Step.ExecutionPending, Step.ExecutionSuccess, Step.ExecutionRejected];

export function hasRightToDealLeg(leg: BulkLeg, hasForwardRight: boolean, hasSpotRight: boolean) {
  return (hasSpotRight && leg.type === 'Spot') || (hasForwardRight && leg.type === 'Forward');
}

export const getNoSpotRights = (legs: Partial<ProductLegItem>[], hasSpotRights: boolean) =>
  !hasSpotRights && legs.some((leg) => leg.type === 'Spot');
  
export const getNoForwardRights = (legs: Partial<ProductLegItem>[], hasForwardRights: boolean) =>
  !hasForwardRights && legs.some((leg) => leg.type === 'Forward');

  export const getCurrencyWithNoSpotAndForwardRights = (
  legs: ProductLegItem[],
  hasForwardRights: boolean,
  hasSpotRights: boolean,
) =>
  (hasSpotRights && !hasForwardRights && legs.every((leg) => leg.type === 'Forward')) ||
  (!hasSpotRights && hasForwardRights && legs.every((leg) => leg.type === 'Spot'));

  
export function getRfsState(step: Step): 'Request' | 'Execution' {
  if (REQUEST_STATES.includes(step)) {
    return 'Request';
  }

  if (EXECUTION_STATES.includes(step)) {
    return 'Execution';
  }

  return 'Request';
}
