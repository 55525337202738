import { HedgeConnectXProps, Product } from "&types/hedgeconnect";
import { Promisify } from "@/components/App";

export const getOverrideXprops = (bulkProduct: Product | null = { bulkTrade: [] }): Promisify<HedgeConnectXProps> =>
({
  onProductPriced: (rfsId: string) => (Promise.resolve(true)),
  getProduct: () => (Promise.resolve(bulkProduct!)),
  onClose: () => (Promise.resolve()),
  onDealDone: (bulkProduct: any) => (Promise.resolve()),
  onDealNotDone: () => (Promise.resolve()),
  thirdPartyId: ""
})
