import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

type ExecutionModalProps = {
  isExecutionModalOpen: boolean;
  onClickExecute: () => void;
  isDisabled: boolean;
  numberOfFilteredLegs: number;
  company: string;
};

function ExecutionModal({
  isExecutionModalOpen,
  isDisabled,
  onClickExecute,
  numberOfFilteredLegs,
  company,
}: ExecutionModalProps) {
  return (
    <Modal show={isExecutionModalOpen} data-e2e="execution-modal">
      <Modal.Header className="d-flex">
        <h5 className="modal-title">
          <FormattedMessage id="ExecuteOperations.confirmation.modal" />
        </h5>
        <button type="button" className="btn btn-close my-auto" aria-label="Close"></button>
      </Modal.Header>
      <Modal.Body>
        <div className="text-start mb-3">
          <FormattedMessage
            id="ExecuteOperations.subHeader"
            values={{
              company,
              operationsCount: numberOfFilteredLegs,
              css: (chunks) => <span className="fw-bold">{chunks}</span>,
            }}
          />
        </div>
        <div className="d-flex justify-content-end mt-5">
          <button type="button" className="btn btn-lg btn-flat-secondary text-secondary me-2">
            <FormattedMessage id={`ExecuteOperations.cancel`} />
          </button>
          <button
            type="button"
            className="btn btn-primary btn-lg"
            data-e2e="execute-button-modal"
            onClick={onClickExecute}
            disabled={isDisabled || numberOfFilteredLegs === 0}
          >
            <FormattedMessage
              id="ExecuteOperations.confirmation.modalButton"
              values={{ operationsCount: numberOfFilteredLegs }}
            />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ExecutionModal;
