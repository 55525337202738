import { ProductLeg } from '&types/hedgeconnect';
import { BulkLeg } from '@/models/product';
import {
  MultipassLegExecutionPayload,
  MultipassQuoteExecutionPayload,
  MultipassQuoteRequestPayload,
  QuoteReply,
} from '@/models/rfs';
import { mapProductLegTypeToProductName } from '@/utils/adapterInOutTradeCapture';
import { assertIsDefined } from '@sgme/fp';
import { format } from 'date-fns';

export function mapToMultipassQuoteRequest(
  bdrId: number,
  rfsId: string,
  connectionId: string,
  legs: readonly ProductLeg[],
): MultipassQuoteRequestPayload {
  const firstLeg = legs[0];
  const currencyPair = firstLeg.currencyPair;
  const amountCurrency = currencyPair.indexOf(firstLeg.amountCurrency) === 0 ? 1 : 2;

  return {
    fxBulkProduct: {
      currencyPair,
      amountCurrency,
      legs: legs.map((leg, index) => ({
        id: `${index}`,
        product: leg.type === 'Spot' ? 'FxSpot' : 'FxFwd',
        way: leg.way,
        amount: leg.amount,
        paymentDate: leg.expiryDate,
        ndFixingInfos: null,
        paymentDateTenor: leg.maturityDateTenor,
        sourceDealId: leg.thirdPartyTradeReference,
      })),
    },
    replyToStream: connectionId,
    requesterCounterpartyId: bdrId,
    rfsId,
  };
}

export const FULL_DATE_MULTIPASS = "yyyy-MM-dd'T'HH:mm:ss";

export function mapToMultipassQuoteExecution(
  legs: readonly BulkLeg[],
  connectionId: string,
  {
    quoteId,
    lastQuoteFromBackTimestamp,
    legs: streamLegs,
    rfsId,
    frontReceivedTimestamp,
  }: QuoteReply,
  currentDate: Date,
): MultipassQuoteExecutionPayload {


  const selectedLegsWithIds = Object.entries(legs).reduce((acc,[id,leg])=>{
    if(leg.isSelectedForExecution){
      acc[id]= leg
    }
    return acc
  },{} as Record<string, BulkLeg>)
  const selectedLegIds = Object.keys(selectedLegsWithIds);

  const selectedLegs = Object.values(selectedLegsWithIds);
  const selectedStreamLegs = streamLegs.filter((_leg, index)=> selectedLegIds.includes(String(index)));

  
  const pricedLegs = selectedLegs.map((leg, i) => {
    const quoteLeg =selectedStreamLegs[i];
    return mapToMultipassQuoteExecutionLeg(leg, quoteLeg?.spotWithMargin, quoteLeg?.forwardPoints);
  });
  return {
    quoteId,
    emailNotificationList: '',
    userLocalDateTime: format(currentDate, FULL_DATE_MULTIPASS),
    lastQuoteFromBackTimestamp,
    lastQuoteReceivedByFrontTimestamp: frontReceivedTimestamp,
    executionClickByFrontTimestamp: currentDate.getTime(),
    legs: pricedLegs,
    replyToStream: connectionId,
    rfsId,
  };
}

function mapToMultipassQuoteExecutionLeg(
  leg: BulkLeg,
  spotClient: number | undefined,
  forwardPoints: number | undefined,
): MultipassLegExecutionPayload {
  assertIsDefined(spotClient, 'Cannot execute without price');
  assertIsDefined(forwardPoints, 'Cannot execute without price');
  return {
    amount: leg.amount,
    customerWay: leg.way,
    spotClient,
    product: mapProductLegTypeToProductName(leg.type),
    forwardPoints,
    valueDate: leg.expiryDate,
    valueDateTenor: leg.type === 'Spot' ? 'SP' : 'BR',
  };
}
