import { useAppDispatch, useAppSelector } from '@/features/hooks';
import { currencyPairSelectedForExecution } from '@/features/product/productSlice';
import { requestStream } from '@/features/rfs/rfsAction';
import { cancelStream, selectRfsId } from '@/features/rfs/rfsSlice';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ToggleButton } from '../../ToggleButton';
import { ProductLegItem, isCurrencyPairAllowed } from '../shared';
import { isDefined } from '@sgme/fp';
import { productComesFromTradeRetriever } from '@/features/user/userSlice';

interface CurrencyPairHeaderProps {
  currencyPair: string;
  filteredLegsLength: number;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  legs: ProductLegItem[];
  isStatusOnError: boolean;
  hasSpotRights: boolean;
  hasForwardRights: boolean;
  rfsCurrenciesAllowed: string[];
  rfsState: 'Request' | 'Execution';
}
export const CurrencyPairHeader = ({
  currencyPair,
  filteredLegsLength,
  isOpen,
  setIsOpen,
  legs,
  isStatusOnError,
  hasForwardRights,
  hasSpotRights,
  rfsCurrenciesAllowed,
  rfsState,
}: CurrencyPairHeaderProps) => {
  // Know how many legs are check
  const neitherLegIsSelected = filteredLegsLength === 0;

  /**
   * Some selectors
   */
  const rfsId = useAppSelector(state => selectRfsId(state, currencyPair))!;
  // To determine on which page we are
  const isExecuted = rfsState === 'Execution';
  // If we are from website or smart button
  const isFromUrl = useAppSelector(productComesFromTradeRetriever);

  /**
   * Some state
   */
  const [currencyPairChecked, setCurrencyPairChecked] = useState(true);
  const areCurrenciesAllowed = isCurrencyPairAllowed(rfsCurrenciesAllowed, currencyPair);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setCurrencyPairChecked(!neitherLegIsSelected);
  }, [neitherLegIsSelected]);

  /**
   * Handle click on the checkbox
   */
  const handleCheckCurrencyPair = () => {
    // We need to toogle state of all legs and header checked states
    setCurrencyPairChecked(!currencyPairChecked);
    dispatch(
      currencyPairSelectedForExecution({
        currencyPair,
        isSelected: !currencyPairChecked,
      }),
    );
    if (currencyPairChecked) {
      // We unchecked the checkbox so we need to cancel stream
      dispatch(cancelStream({ currencyPair, rfsId }));
    } else {
      // We checked the checkbox so we need to start stream
      dispatch(requestStream(currencyPair));
    }
  };

  /**
   * Some logics
   */
  const isDisabled =
    isStatusOnError ||
    legs.every(leg => isDefined(leg.warnings)) ||
    legs.every(leg => isDefined(leg.errors)) ||
    (!hasForwardRights && legs.every(leg => leg.type === 'Forward')) ||
    (!hasSpotRights && legs.every(leg => leg.type === 'Spot')) ||
    !areCurrenciesAllowed;
  const shouldShowErrorBadge = isStatusOnError && isExecuted;

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="d-flex align-items-center mt-5 mb-3">
      {!isExecuted && isFromUrl && (
        <div className="form-check me-4">
          <input
            type="checkbox"
            className="form-check-input"
            data-e2e={`${currencyPair}-select-all`}
            onChange={handleCheckCurrencyPair}
            disabled={isDisabled}
            checked={isDisabled ? !isDisabled : currencyPairChecked}
          />
        </div>
      )}
      <h5 className={`${!isExecuted && isFromUrl ? 'ms-2' : ''}`}>{currencyPair}</h5>
      <span className="badge badge-discreet-primary badge-sm rounded-pill ms-1 pt-1">
        <FormattedMessage
          id="ExecuteOperations.selected"
          values={{ operationsCount: filteredLegsLength, isExecuted }}
        />
      </span>
      <hr className="m-0 ms-2 flex-grow-1" />
      {isExecuted && !shouldShowErrorBadge && (
        <div className="badge badge-success badge-md rounded-pill ms-2 pt-1">
          <FormattedMessage id="ExecuteOperations.executed" />
        </div>
      )}
      {shouldShowErrorBadge && (
        <div className="badge badge-danger badge-md rounded-pill ms-2 pt-1">
          <FormattedMessage id="ExecuteOperations.error" />
        </div>
      )}
      <ToggleButton isOpen={isOpen} onClick={toggle} className="mr-3" />
    </div>
  );
};
