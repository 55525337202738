import { FormattedMessage } from 'react-intl';
import { useCompanyName } from '@/features/hooks';

export function CompanyName({ allIsDone }: { allIsDone: boolean }): React.ReactElement {
  const company = useCompanyName();

  return (
    <FormattedMessage
      id="Operations.subHeader"
      values={{
        company,
        allIsDone,
        css: (chunks) => <span className="fw-medium text-info">{chunks}</span>,
      }}
    />
  );
}
