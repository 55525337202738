import { HedgeConnectXProps } from '&types/hedgeconnect';
import { Footer } from '@/components/layout/Footer';
import { Header } from '@/components/layout/Header';
import { useAppDispatch, useAppSelector } from '@/features/hooks';
import { invalidStatus, productReceived } from '@/features/product/productSlice';
import {
  ProductSource,
  selectCanAccessFromUrl,
  setProductSource,
  setThirdPartyId,
} from '@/features/user/userSlice';
import { useTimeout } from '@/hooks/useTimeout';
import { setupProductListeners } from '@/state/listeners/setupProductListeners';
import { logger } from '@/utils/logger';
import { getOverrideXprops } from '@/utils/overrideXprop';
import { isEmpty, isNotDefined } from '@sgme/fp';
import { useEffect } from 'react';
import './App.css';
import { Stepper } from './Stepper';
import { ServiceLoader } from './content/ServiceLoader';
import { Step, stepChanged } from '@/features/step/stepSlice';

/**
 * Functions are async on this side of the Zoid boundary
 */
export type Promisify<T> = {
  [K in keyof T]: T[K] extends (...args: infer A) => infer R ? (...args: A) => Promise<R> : T[K];
};

declare global {
  interface Window {
    xprops: Promisify<HedgeConnectXProps>;
  }
}

setupProductListeners();

export function App(): React.ReactElement {
  const dispatch = useAppDispatch();
  const timeout = useTimeout(800);
  const canAccessFromUrl = useAppSelector(selectCanAccessFromUrl);

  useEffect(
    function checkSourceOfUser() {
      const dispatchProductReceived = async () => {
        if (window?.xprops?.getProduct) {
          // If getProduct that comes from smartbutton
          // Check integrity of product
          let product = null;
          try {
            product = await window.xprops.getProduct();
          } catch (e) {
            logger.logError('getProduct call failed {error}', e);
            window?.xprops?.onDealNotDone(
              new Error(
                'HedgeConnect was unable to retrieve the product description from your application',
                { cause: e },
              ),
            );
          }

          if (isNotDefined(product)) {
            logger.logError('NoProduct - redirect to error page');
            return dispatch(invalidStatus('NoProduct'));
          }

          if (isEmpty(product.bulkTrade)) {
            logger.logError('EmptyProduct - redirect to error page');
            return dispatch(invalidStatus('EmptyProduct'));
          }

          logger.logInformation(
            'Product comes from {source} and product are valid: {product}',
            ProductSource.FromWidget,
            product,
          );
          dispatch(setProductSource(ProductSource.FromWidget));
          return dispatch(productReceived(product));
        }

        // No xprops.getProduct means it's come from url

        // Check IAM Permission if it's allow to come from url
        if (canAccessFromUrl === false) {
          logger.logInformation('User are unable to continue on Hedgeconnect');
          return dispatch(stepChanged(Step.UnableToContinue));
        }

        window.xprops = getOverrideXprops(); // Really nead this ? Yes we need onProductPriced
        logger.logInformation('Product comes from {source}', ProductSource.FromWidget);
        return dispatch(setProductSource(ProductSource.FromTradeRetriever));
      };

      dispatchProductReceived();
    },
    [dispatch, canAccessFromUrl],
  );

  useEffect(
    function storeThirdPartyId() {
      // If user accessFromUrl the third party id need to be retrieve by /api/pretrades
      if (window?.xprops?.thirdPartyId) {
        logger.logInformation(
          'thirdPartyId is provided by the tms with value: {thirdPartyId}',
          window?.xprops?.thirdPartyId,
        );
        dispatch(setThirdPartyId(window?.xprops?.thirdPartyId));
      }
      logger.logInformation("ThirdPartyId isn't provided by TMS xprops is empty. Need to fetch api/pretrades to get it.")
    },
    [dispatch],
  );

  return !timeout ? (
    <ServiceLoader />
  ) : (
    <div className="app">
      <Header />
      <Stepper />
      <Footer />
    </div>
  );
}
