import { useAppDispatch, useAppSelector, useCompanyName } from '@/features/hooks';
import { FormattedMessage } from 'react-intl';
import { selectAllCurrencyPairs, selectAllRfsIds } from '@/features/rfs/rfsSlice';
import { requestStream } from '@/features/rfs/rfsAction';

import { AllProductsByCurrencyPair } from '@/components/content/product/AllProductsByCurrencyPair';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currencyPairSelectedForExecution } from '@/features/product/productSlice';

export function StreamEnded() {
  const currencyPairs = useSelector(selectAllCurrencyPairs);
  const dispatch = useAppDispatch();
  const onPriceAgain = () => {
    currencyPairs.forEach((currencyPair) => {
      dispatch(
        currencyPairSelectedForExecution({
          currencyPair,
          isSelected: true,
        }),
      );
      dispatch(requestStream(currencyPair));
    });
  };

  const company = useCompanyName();

  const rfsIds = useAppSelector(selectAllRfsIds);
  const handleCloseWhilePricing = () => {
    rfsIds.forEach((rfsId) => {
      window?.xprops?.onDealNotDone(rfsId);
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleCloseWhilePricing);
    return () => {
      window.removeEventListener('beforeunload', handleCloseWhilePricing);
    };
  }, []);

  return (
    <main className="p-0 d-flex flex-column">
      <div className="container mb-3">
        <div className="mt-48px">
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="display-3">
                <FormattedMessage id="StreamEnded.header" />
              </h2>
              <h5 className="mt-2">
                <FormattedMessage
                  id="StreamEnded.subHeader"
                  values={{
                    company,
                    css: (chunks) => <span className="fw-medium text-info">{chunks}</span>,
                  }}
                />
              </h5>
            </div>
            <div className="my-auto">
              <button type="button" className="btn btn-info px-3 py-2" onClick={onPriceAgain}>
                <FormattedMessage id="StreamEnded.next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-lvl2 flex-grow-1 border-top pb-48px">
        <div className="container mb-3">
          <AllProductsByCurrencyPair />
        </div>
      </div>
    </main>
  );
}
