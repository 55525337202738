/**
 * Sum spot rate and forward points without floating point errors
 * @param spot Spot rate
 * @param fwdPoints Forward points
 * @param spotPrecision Currency spot precision
 * @returns The computed Forward rate
 * @see https://modernweb.com/what-every-javascript-developer-should-know-about-floating-points/
 */
export function sumSpotAndFwdPoints(
  spot: number,
  fwdPoints: number,
  spotPrecision: number,
): number {
  const fwdPrecision = spotPrecision + 1;
  const fwdPrecisionFactor = 10 ** fwdPrecision;
  return (spot * fwdPrecisionFactor + fwdPoints * 100) / fwdPrecisionFactor;
}
