import { assertUnreachable } from '@sgme/fp';
import { LoadOperations } from './LoadOperations';
import { ExecuteOperations } from './ExecuteOperations';
import { useAppSelector } from '@/features/hooks';
import { ExecutionResult } from './ExecutionResult';
import { selectStep, Step } from '@/features/step/stepSlice';
import { StreamEnded } from './StreamEnded';
import { SelectCompany } from './SelectCompany';
import { LoadingPage } from './LoadingPage';
import { StreamingError } from './StreamingError/StreamingError';
import { useStepTracker } from '@/hooks/useStepTracker';
import { NoProduct } from './NoProduct';
import { selectAllCurrencyPairs } from '@/features/rfs/rfsSlice';
import UnableToContinuePage from './UnableToContinuePage';

export function Stepper() {
  const step = useAppSelector(selectStep);
  const isOnExecuteOperationPage = useAppSelector(selectAllCurrencyPairs).length > 0;
  useStepTracker();

  switch (step) {
    case Step.CannotProceed:
      return <NoProduct />;
    case Step.LoadingPage:
      return <LoadingPage />;
    case Step.SelectCompany:
      return <SelectCompany />;
    case Step.ValidationRequested:
    case Step.PriceRequested:
      return isOnExecuteOperationPage ? <ExecuteOperations /> : <LoadOperations />;
    case Step.ProductKO:
    case Step.StreamingPrices:
      return <ExecuteOperations />;
    case Step.ExecutionPending:
    case Step.ExecutionSuccess:
      return <ExecutionResult />;
    case Step.StreamEnded:
      return <StreamEnded />;
    case Step.RequestRejected:
      return <StreamingError type="Request" />;
    case Step.ExecutionRejected:
      return <StreamingError type="Execution" />;
    case Step.UnableToContinue:
      return <UnableToContinuePage />;
    default:
      assertUnreachable(step, 'Unhandled step');
  }
}
