import { CompanyDataStatus, companyDataLoadingRequested } from '@/features/user/userSlice';
import { Company } from '@/models/user';
import styled from '@emotion/styled';
import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';




export function CompanyTooltip({
  company,
  placement = "right",
  companyDataStatus
}: {
  company: Company;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  companyDataStatus: CompanyDataStatus
}) {
  return (
    <CompanyPopover company={company} placement={placement}>
      {companyDataStatus === 'NotLoaded' ||
        (companyDataStatus === 'Loading' && (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border spinner-border-sm my-auto" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ))}

      {companyDataStatus === 'Loaded' && (
        <StyledPopoverBody>
          <div className="header">
            <FormattedMessage id="ClientPicker.tooltip.country" />
          </div>
          <div>{company.country ?? '-'}</div>

          <div className="header">
            <FormattedMessage id="ClientPicker.tooltip.siret" />
          </div>
          <div>{company.siret ?? '-'}</div>
        </StyledPopoverBody>
      )}

      {companyDataStatus === 'Error' && (
        <StyledErrorMessage className="d-flex align-items-center">
          <FormattedMessage id="ClientPicker.tooltip.error" />
        </StyledErrorMessage>
      )}
    </CompanyPopover>
  );
}



// ---------------------------------------

interface CompanyPopoverProps {
  company: Company;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

// ---------------------------------------

// PropsWithChildren<CompanyPopoverProps>

export function CompanyPopover({
  company,
  placement = 'right',
  children,
}: PropsWithChildren<CompanyPopoverProps>): ReactElement {
  // hooks

  const id = `company-item-${company.companyBdrId}`;

  // render
  return (
    <>
      <i className="icon icon-sm me-2" id={id}>
        info_outline
      </i>

      <UncontrolledPopover target={id} placement={placement} trigger="hover">
        <PopoverBody>
          <ChildrenWrapper targetId={id}>{children}</ChildrenWrapper>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}

// ---------------------------------------


export interface ChildrenWrapperProps {
  targetId: string;
}

const ChildrenWrapper = ({ targetId, children }: PropsWithChildren<ChildrenWrapperProps>) => {
  const dispatch = useDispatch();

  // useEffect has to be in the children to dispatch the request in lazyness
  useEffect(() => {
    dispatch(companyDataLoadingRequested());
  }, []);

  return <div data-e2e={targetId}>{children}</div>;
};



const StyledPopoverBody = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr;

  & > div {
    margin: 4px 0;
    color: #000;

    &.header {
      color: var(--secondary);
      font-weight: 600;
    }
  }
`;

// ---------------------------------------

const StyledErrorMessage = styled.div`
  min-height: 32px;
  color: var(--bs-red);
`;
