import { useAppSelector } from '@/features/hooks';
import { selectProduct } from '@/features/product/productSlice';
import { isInvalidProductStatus } from '@/models/product';
import ErrorPage from './ErrorPage';

export function NoProduct() {
  const fxKitQueries = useAppSelector((state) => state.fxkitApi.queries);
  const productsFromFxKit = Object.values(fxKitQueries);

  const everyProductsIsRejected = productsFromFxKit.every(
    (product) => product?.status === 'rejected',
  );
  const { status } = useAppSelector(selectProduct);

  if (!isInvalidProductStatus(status) && !everyProductsIsRejected) {
    return null;
  }
  return <ErrorPage />;
}
