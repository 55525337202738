import { startAppListening } from '@/state/store';
import { requestQuoteAfterProductReceived } from '@/state/listeners/requestQuoteAfterProductReceived';
import { bootstrap } from '@/state/listeners/bootstrap';
import { requestStream } from '@/features/rfs/rfsAction';
import { productValidationRequested } from '@/features/product/productSlice';
import { validateProduct } from './validateProduct';
import { companyDataLoadingRequested, setProductSource } from '@/features/user/userSlice';
import { getClientInformation } from './getClientInformation';
import { cancelQuote } from './cancelQuote';
import { cancelStream, dealRejected, execute, quoteAbort, streamStarted } from '@/features/rfs/rfsSlice';
import { triggerExecution } from './triggerExecution';
import {streamStartedListener } from './streamStartedListener';
import { dealRejectedListener } from './dealRejectedListener';
import { quoteAbortListener } from './quoteAbortListener';
import { getExecutedTrades } from './getExecutedTrades';
import { cleanProducts } from '@/features/executedTrades/executedTradesAction';

export function setupProductListeners() {
  const subscriptions = [
    startAppListening({
      actionCreator: setProductSource,
      effect: bootstrap,
    }),

    startAppListening({
      actionCreator: productValidationRequested,
      effect: validateProduct,
    }),

    startAppListening({
      actionCreator: requestStream,
      effect: requestQuoteAfterProductReceived,
    }),

    startAppListening({
      actionCreator: companyDataLoadingRequested,
      effect: getClientInformation,
    }),

    startAppListening({
      actionCreator: cancelStream,
      effect: cancelQuote,
    }),
    startAppListening({ actionCreator: streamStarted, effect: streamStartedListener }),
    startAppListening({ actionCreator: execute, effect: triggerExecution }),
    startAppListening({ actionCreator: dealRejected, effect: dealRejectedListener }),
    startAppListening({ actionCreator: quoteAbort, effect: quoteAbortListener }),
    startAppListening({ actionCreator: cleanProducts, effect: getExecutedTrades }),
  ];

  return () => {
    subscriptions.forEach((unsubscribe) => unsubscribe());
  };
}
