import { getErrorType } from '@/components/StreamingError/getError';
import { useAppSelector } from '@/features/hooks';
import { selectRfsByCurrencyPair } from '@/features/rfs/rfsSlice';
import { productComesFromTradeRetriever } from '@/features/user/userSlice';
import { isDefined } from '@sgme/fp';
import { FormattedMessage } from 'react-intl';
import { isCurrencyPairAllowed } from './shared';

interface ProductByCurrencyPairProps {
  legsLentgh: number;
  isReferenceVisible: boolean;
  isCurrencyPairOnError: boolean;
  isCurrencyPairOnWarning: boolean;
  hasNoSpotRights: boolean;
  hasNoForwardRights: boolean;
  rfsCurrenciesAllowed: string[];
  rfsState: 'Request' | 'Execution';
  isExpanded: boolean;
  currencyPair: string;
}

export const getIdErrorMessage = (
  rfsState: 'Request' | 'Execution',
  errors: {
    errorMessage?: string;
    errorCode?: string;
  },
  hasNoSpotRights: boolean,
  hasNoForwardRights: boolean,
  areCurrenciesAllowed: boolean,
) => {
  switch (true) {
    case !areCurrenciesAllowed:
      return 'ExecuteOperations.currencyNotAllowed.errorMessage';
    case rfsState === 'Execution':
      return 'ExecuteOperations.EXECUTE.errorMessage';
    case rfsState === 'Request' && isDefined(errors.errorCode) && isDefined(errors.errorMessage): {
      const errorType = getErrorType(errors?.errorCode, errors?.errorMessage);
      return `ExecuteOperations.${errorType}.errorMessage`;
    }
    case hasNoForwardRights && hasNoSpotRights:
      return 'ExecuteOperations.NoSpotNoForward.errorMessage';
    case hasNoForwardRights:
      return 'ExecuteOperations.NoForward.errorMessage';
    case hasNoSpotRights:
      return 'ExecuteOperations.NoSpot.errorMessage';
    default:
      return 'ExecuteOperations.OTHER.errorMessage';
  }
};

function ProductByCurrencyPairHeader({
  isReferenceVisible,
  legsLentgh,
  isCurrencyPairOnError,
  isCurrencyPairOnWarning,
  rfsState,
  currencyPair,
  isExpanded,
  rfsCurrenciesAllowed,
  hasNoForwardRights,
  hasNoSpotRights,
}: ProductByCurrencyPairProps) {
  const isFromUrl = useAppSelector(productComesFromTradeRetriever);
  const rfs = useAppSelector((state) => selectRfsByCurrencyPair(state, currencyPair));
  const areCurrenciesAllowed = isCurrencyPairAllowed(rfsCurrenciesAllowed, currencyPair);

  const idErrorMessage = getIdErrorMessage(
    rfsState,
    {
      errorCode: rfs?.errorCode,
      errorMessage: rfs?.errorMessage,
    },
    hasNoSpotRights,
    hasNoForwardRights,
    areCurrenciesAllowed,
  );
  const shouldDisplayError =
    isCurrencyPairOnError ||
    isCurrencyPairOnWarning ||
    hasNoForwardRights ||
    hasNoSpotRights ||
    !areCurrenciesAllowed;

  if (!isExpanded) return null;

  return (
    <>
      {shouldDisplayError && (
        <div className="mb-2 text-danger" data-e2e="currency-header-error-message">
          <FormattedMessage id={idErrorMessage} />
        </div>
      )}
      <div className="text-secondary fw-medium  mx-3 mb-2">
        <div
          className={`${
            !isReferenceVisible && isFromUrl ? 'ms-5' : ''
          } d-flex justify-content-between`}
        >
          <FormattedMessage
            id="ExecuteOperations.listHeader"
            values={{ operationsCount: legsLentgh }}
            tagName="div"
          />

          <div className="d-flex gap-5">
            <div style={{ width: '8rem' }}>
              <FormattedMessage id="ExecuteOperations.date" />
            </div>
            {isReferenceVisible && (
              <div style={{ width: '10rem' }}>
                <FormattedMessage id="ExecuteOperations.tradeIdHeader" tagName="div" />
              </div>
            )}
            <div style={{ width: '4rem' }}>
              <FormattedMessage id="ExecuteOperations.rateHeader" tagName="div" />
            </div>
            <div style={{ width: '6rem' }}>
              <FormattedMessage id="ExecuteOperations.type" tagName="div" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductByCurrencyPairHeader;
