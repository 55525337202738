import './LoadingSkeleton.css';

export function LoadingSkeleton(): React.ReactElement {
  return (
    <div className="d-flex flex-column">
      <div className="loading-skeleton-line mb-2" style={{ width: '73px', height: '12px' }}></div>
      <div className="loading-skeleton-line mb-2" style={{ width: '440px', height: '12px' }}></div>
      <div className="loading-skeleton-line" style={{ width: '293px', height: '12px' }}></div>
    </div>
  );
}
