import { Way } from '@/models/product';
import {
  MultipassBidAsk,
  MultipassQuoteLeg,
  MultipassQuoteReply,
  QuoteLeg,
  QuoteReply,
} from '@/models/rfs';

export function mapFromMultipassQuoteReply(quoteReply: MultipassQuoteReply, now: Date): QuoteReply {
  return {
    rfsWindow: quoteReply.BulkQuoteReply.RfsWindow,
    rfsId: quoteReply.BulkQuoteReply.RfsId,
    quoteType: quoteReply.BulkQuoteReply.QuoteType,
    quoteId: quoteReply.BulkQuoteReply.QuoteId,
    predealChecks: quoteReply.BulkQuoteReply.PredealChecks,
    notionalCurrency: quoteReply.BulkQuoteReply.NotionalCurrency,
    legs: quoteReply.BulkQuoteReply.Legs.map(mapFromMultipassQuoteLeg),
    nettedView: quoteReply.BulkQuoteReply.NettedView.map(mapFromMultipassQuoteLeg),
    lastQuoteFromBackTimestamp: quoteReply.Timestamp,
    timestamp: quoteReply.Timestamp,
    frontReceivedTimestamp: now.getTime(),
  };
}

function mapFromMultipassQuoteLeg(quoteReplyLeg: MultipassQuoteLeg): QuoteLeg {
  return {
    spotWithMargin: toNumberPrice(quoteReplyLeg.SpotWithMargin, quoteReplyLeg.CustomerWay),
    forwardPoints: toNumberPrice(quoteReplyLeg.ForwardPoints, quoteReplyLeg.CustomerWay),
    forwardMarginPoints: quoteReplyLeg.ForwardMarginPoints ? toNumberPrice(quoteReplyLeg.ForwardMarginPoints, quoteReplyLeg.CustomerWay) : 0,
    isForward: quoteReplyLeg.IsForward,
    amount: Number(quoteReplyLeg.Amount),
    date: quoteReplyLeg.Date,
    dateTenor: quoteReplyLeg.DateTenor,
    customerWay: quoteReplyLeg.CustomerWay,
  };
}

function toNumberPrice(bidAsk: MultipassBidAsk, way: Way): number {
  const relevantPriceString = bidAsk[way === 'Buy' ? 'Ask' : 'Bid'];
  const relevantPrice = Number(relevantPriceString);
  return Number.isNaN(relevantPrice) ? 0 : relevantPrice;
}
