interface RetryOptions {
  maxRetries: number;
  delayMs: number;
  onRetry?: (reason: RetryReason) => void;
}

interface RetryReason {
  reason: unknown;
  retryCount: number;
}

export function retry<T>(doWork: () => Promise<T>, options: RetryOptions): Promise<T> {
  return new Promise((resolve, reject): void => {
    function doWorkOnce(numberOfAttempts: number): void {
      doWork().then(resolve, function onrejected(reason: unknown) {
        if (options.onRetry) {
          options.onRetry({ reason, retryCount: numberOfAttempts });
        }

        if (numberOfAttempts < options.maxRetries) {
          setTimeout(() => doWorkOnce(numberOfAttempts + 1), options.delayMs);
        } else {
          reject({ reason, retryCount: numberOfAttempts });
        }
      });
    }

    doWorkOnce(1);
  });
}
