const encoding = '0123456789abcdefghijklmnopqrstuvwxyz';
const encodingLength = BigInt(encoding.length);

export function uuidToBase36(uuid: string) {
  const cleanUuid = uuid.replace(/-/g, '');

  let iUuid = BigInt(`0x${cleanUuid}`);
  let str = '';

  do {
    str = encoding.at(Number(iUuid % encodingLength)) + str;
    iUuid = iUuid / encodingLength;
  } while (iUuid > 0);

  return str;
}
