import { getConfig } from '@/config/config';
import { SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';

export function HelpCenter(): JSX.Element {
  return (
    <aside>
      <SgwtHelpCenter
        applicationId="hedgeconnect"
        mailSubject={`[HedgeConnect:${getConfig().env}] Help center message`}
        allowScreenshot={true}
      />
    </aside>
  );
}
