import { FormattedMessage } from 'react-intl';

function CompanyWarning() {
  return (
    <div className="mt-4 alert alert-outline-warning p-2">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <i className="icon icon-md me-2 align-self-start ">info_outline</i>

          <p className="mb-0 align-self-center ">
            <FormattedMessage id="SelectCompany.missingCompanies.error" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanyWarning;
