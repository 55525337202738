import { BulkLeg } from '@/models/product';
import { ProductLegFromTms } from '&types/hedgeconnect';
import { mapProductLegFromTms } from '@/utils/adapterInOutTms';
import { clearUndefined } from '@/utils/clearUndefined';

export function mapProductLegToBulkLeg(productLegFromTms: ProductLegFromTms): BulkLeg {
  const productLeg = mapProductLegFromTms(productLegFromTms);

  switch (productLeg.type) {
    case 'Spot':
      return {
        ...clearUndefined({
          thirdPartyTradeReference: productLeg.thirdPartyTradeReference,
          maturityDateTenor: productLeg.maturityDateTenor,
          company: productLeg.company,
          companyDescription: productLeg.companyDescription,
          sgReference: productLeg.sgReference,
        }),
        type: 'Spot',
        way: productLeg.way,
        displayWay: productLeg.displayWay,
        currencyPair: productLeg.currencyPair,
        amount: productLeg.amount,
        amountCurrency: productLeg.amountCurrency,
        expiryDate: productLeg.expiryDate,
        isSelectedForExecution: true,
      };

    case 'Forward':
      return {
        ...clearUndefined({
          thirdPartyTradeReference: productLeg.thirdPartyTradeReference,
          maturityDateTenor: productLeg.maturityDateTenor,
          company: productLeg.company,
          companyDescription: productLeg.companyDescription,
          sgReference: productLeg.sgReference,
        }),
        type: 'Forward',
        way: productLeg.way,
        displayWay: productLeg.displayWay,
        currencyPair: productLeg.currencyPair,
        amount: productLeg.amount,
        amountCurrency: productLeg.amountCurrency,
        expiryDate: productLeg.expiryDate,
        isSelectedForExecution: true,
      };

    default:
      throw new Error('Unknown product type: ' + productLegFromTms.product);
  }
}
