import { RootState } from '@/state/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface StreamingState {
  state: 'connected' | 'pending' | 'disconnected';
  connectionId: string | undefined;
}

const initialState: StreamingState = {
  state: 'pending',
  connectionId: undefined,
};

export const streamingSlice = createSlice({
  name: 'streaming',
  initialState,
  reducers: {
    streamingStateChanged: (state, action: PayloadAction<StreamingState>) => {
      state.state = action.payload.state;
      state.connectionId = action.payload.connectionId;
    },
  },
});

export const { streamingStateChanged } = streamingSlice.actions;

export const selectStreamingInfo = (state: RootState) => state.streaming;

export default streamingSlice.reducer;
