import { mapTolegWithPrice } from '@/components/content/product/AllProductsByCurrencyPair';
import { selectProduct } from '@/features/product/productSlice';
import {
  errorOnRequest,
  quoteAbort,
  selectAllRfsByCurrencyPair,
  selectCurrencyPairByRfsId,
  selectExecutionStatusByCurrencies,
  selectExecutionStatusForAllCurrencies,
} from '@/features/rfs/rfsSlice';
import { Step, selectStep, stepChanged } from '@/features/step/stepSlice';
import { AppListenerEffectAPI } from '@/state/store';
import { getRfsState } from '@/utils/rights';

export async function quoteAbortListener(
  { payload: { QuoteReject } }: ReturnType<typeof quoteAbort>,
  { getState, dispatch }: AppListenerEffectAPI,
) {
  const state = getState();


  const currencyPair = selectCurrencyPairByRfsId(state, QuoteReject.RfsId);
  

  const step = selectStep(state);
  const rfsState = getRfsState(step);

    if (rfsState === 'Request') {
      dispatch(errorOnRequest(currencyPair));
    }


  const executionStatus = selectExecutionStatusForAllCurrencies(state);
  const currentStep = selectStep(state);
  const executionStatusByCurrencyPair = selectExecutionStatusByCurrencies(state);
  const onExecutionPage = Object.values(executionStatusByCurrencyPair).includes('Done');

  const AreAllDealsRejectedInRequest = executionStatus.every((status) => status === 'Rejected');
  const nextStep =
    currentStep === Step.StreamingPrices || QuoteReject.ErrorCode === 'EM_TIMEOUT'
      ? Step.StreamEnded
      : currentStep === Step.PriceRequested
      ? Step.RequestRejected
      : currentStep;

  if (onExecutionPage) {
    dispatch(stepChanged(Step.ExecutionSuccess));
  } else if (!AreAllDealsRejectedInRequest) {
    dispatch(stepChanged(Step.PriceRequested));
  } else {
    dispatch(stepChanged(nextStep));
  }
}
