import { MultipassQuoteReply } from '@/models/rfs';
import { mapFromMultipassQuoteReply } from '@/models/transform/multipass/fromMultipass';
import { ExtraArgument, RootState } from '@/state/store';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { quoteReceived, selectAllRfsIds } from './rfsSlice';
import { getLegsByCurrencyPair } from '../product/productSlice';

export function quoteReceivedThunk(
  quote: MultipassQuoteReply,
): ThunkAction<void, RootState, ExtraArgument, AnyAction> {
  return (dispatch, getState, { getDateNow }) => {
    const state = getState();
    const legsByCurrencyPair = getLegsByCurrencyPair(state);

    if (selectAllRfsIds(state).includes(quote.BulkQuoteReply.RfsId)) {
      dispatch(
        quoteReceived({
          quoteReply: mapFromMultipassQuoteReply(quote, getDateNow()),
          legsByCurrencyPair,
        }),
      );
    }
    return;
  };
}
