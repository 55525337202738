import {
  companyDataFailed,
  companyDataLoaded,
  companyDataLoadingRequested,
  companyDataLoadingStarted,
} from '@/features/user/userSlice';
import { ClientDataRequest, isClientDataResponseKo } from '@/models/clientInformation';
import { AppListenerEffectAPI } from '@/state/store';
import { isNotDefined } from '@sgme/fp';

export async function getClientInformation(
  _action: ReturnType<typeof companyDataLoadingRequested>,
  {
    dispatch,
    getState,
    extra: {
      webApiEndpoints: { requestClientData },
    },
  }: AppListenerEffectAPI,
) {
  const state = getState();

  const canStartNewRequest =
    state.user.companyDataStatus === 'NotLoaded' || state.user.companyDataStatus === 'Error';

  if (!canStartNewRequest) {
    return;
  }

  dispatch(companyDataLoadingStarted());

  const allClientBdIds = Object.values(state.user.companies).map(
    ({ companyBdrId }) => companyBdrId,
  );

  const payload: ClientDataRequest = {
    bdrIds: allClientBdIds,
  };

  try {
    const { data } = await dispatch(requestClientData.initiate(payload));
    if (isNotDefined(data)) {
      dispatch(companyDataFailed({ error: -1 }));
      return;
    }

    if (isClientDataResponseKo(data)) {
      dispatch(companyDataFailed({ error: data.error }));
      return;
    }

    dispatch(companyDataLoaded(data));
  } catch (error) {
    // TODO: handle the error
    dispatch(companyDataFailed({ error: -2 }));
  }
}
