import { RootState } from '@/state/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  invalidStatus,
  productRejected,
  productValidationRequested,
} from '../product/productSlice';
import { requestStream } from '../rfs/rfsAction';
import { dealDone, execute, quoteReceived } from '../rfs/rfsSlice';

export enum Step {
  LoadingPage = 'LoadingPage',
  SelectCompany = 'SelectCompany',
  ValidationRequested = 'ValidationRequested',
  ProductKO = 'ProductKO',
  PriceRequested = 'PriceRequested',
  StreamingPrices = 'StreamingPrices',
  StreamEnded = 'StreamEnded',
  ExecutionPending = 'ExecutionPending',
  ExecutionSuccess = 'ExecutionSuccess',
  ExecutionRejected = 'ExecutionRejected',
  RequestRejected = 'RequestRejected',
  CannotProceed = 'CannotProceed',
  UnableToContinue = 'UnableToContinue'
}

const initialState = Step.LoadingPage;

export const stepSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    stepChanged: (_state, action: PayloadAction<Step>) => action.payload,
  },
  extraReducers: builder =>
    builder
      .addCase(invalidStatus, () => Step.CannotProceed)
      .addCase(productValidationRequested, () => Step.ValidationRequested)
      .addCase(productRejected, () => Step.ProductKO)
      .addCase(requestStream, () => Step.PriceRequested)
      .addCase(quoteReceived, currentStep =>
        currentStep === Step.PriceRequested ? Step.StreamingPrices : currentStep,
      )
      .addCase(execute, () => Step.ExecutionPending)
      .addCase(dealDone, () => Step.ExecutionSuccess),
});

export const { stepChanged } = stepSlice.actions;

export function selectStep(state: RootState) {
  return state.step;
}

export default stepSlice.reducer;
