import { useAppSelector } from '@/features/hooks';
import { FormattedMessage } from 'react-intl';

import UnreadyCompanies from './UnreadyCompanies';
import CompanyWarning from './CompanyWarning';
import { useFetchUserCurrentQuery } from '@/features/api/web.api';
import { CompanyItemForTradeRetrieverSource } from './CompanyItemForTradeRetrieverSource';
import { selectCompanies, selectCompanyDataStatus } from '@/features/user/userSlice';
import { Step, stepChanged } from '@/features/step/stepSlice';
import { useDispatch } from 'react-redux';

export function SelectCompanyForTradeRetrieverSource(): React.ReactElement | null {
  const dispatch = useDispatch();
  const companyDataStatus = useAppSelector(selectCompanyDataStatus);

  const companiesList = useAppSelector(selectCompanies);
  const allCompanies = Object.values(companiesList).sort((a, b) =>
    a.companyName.localeCompare(b.companyName),
  );

  const fxKitQueries = useAppSelector((state) => state.fxkitApi.queries);
  const productsFromTradeRetrieverSource = Object.values(fxKitQueries);
  
  const isTradeRetrieverSourceProductsReady = productsFromTradeRetrieverSource.some((product) => product?.status === 'fulfilled');
  const areSomeProductsRejected = productsFromTradeRetrieverSource.some(
    (product) => product?.status === 'rejected',
  );


  const { isFetching, isSuccess, isError } = useFetchUserCurrentQuery();

  if (isError) {
    dispatch(stepChanged(Step.CannotProceed));
  }

  return (
    <main className="p-0 d-flex flex-column ">
      <SelectCompanyForTradeRetrieverSourceHeader isTradeRetrieverSourceProductsReady={isTradeRetrieverSourceProductsReady} />

      <div className="bg-lvl2 flex-grow-1 border-top pb-48px">
        <div className="container mb-3">
          {areSomeProductsRejected && <CompanyWarning />}

          {isTradeRetrieverSourceProductsReady ? (
            <div className="d-flex flex-column mt-48px">
              <h4 className="d-flex justify-content-between text-secondary mb-1">
                <FormattedMessage id={`SelectCompany.tradeRetrieverSourceTitle`} />
              </h4>

              {isFetching && (
                <div className="spinner-grow" role="status">
                  <FormattedMessage id="alerts.loading" />
                </div>
              )}

              {isSuccess &&
                allCompanies.map((company, key) => (
                  <CompanyItemForTradeRetrieverSource company={company} companyDataStatus={companyDataStatus} key={key} />
                ))}
            </div>
          ) : (
            <UnreadyCompanies />
          )}
        </div>
      </div>
    </main>
  );
}

// ██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗
// ██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
// ██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
// ██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝

function SelectCompanyForTradeRetrieverSourceHeader({
  isTradeRetrieverSourceProductsReady,
}: {
  isTradeRetrieverSourceProductsReady: boolean;
}) {
  return (
    <div className="container mb-3">
      <div className="d-flex justify-content-between mt-48px mb-24px">
        <div className="d-flex flex-column">
          {isTradeRetrieverSourceProductsReady ? (
            <>
              <h2 className="display-3">
                <FormattedMessage id="SelectCompany.tradeRetrieverSourceHeader" />
              </h2>
              <h5 className="mt-2">
                <FormattedMessage id="SelectCompany.tradeRetrieverSourceSubHeader" />
              </h5>
            </>
          ) : (
            <FormattedMessage id="SelectCompany.tradeRetrieverSourceLoading" tagName="h2" />
          )}
        </div>
      </div>
    </div>
  );
}
