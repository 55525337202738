import { isDefined } from '@sgme/fp';

interface LegTradeIdProps {
  tradeId: string | undefined;
}

// -------------------------------------------------------

export function LegTradeId({ tradeId }: LegTradeIdProps) {
  return isDefined(tradeId) ? (
    <div
      className="monospace-numbers text-secondary ms-auto me-4 mt-2 text-end "
      style={{ width: '8rem' }}
    >
      {tradeId}
    </div>
  ) : (
    <div
      className="d-flex justify-content-center align-items-center me-4"
      style={{ width: '8rem' }}
    >
      <div className="text-end ms-auto my-auto ">-</div>
    </div>
  );
}
