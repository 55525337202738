import { RootState } from '@/state/store';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
export type ExecutedTradesProps = Record<string, string[]>;

const initialState: ExecutedTradesProps = {};

export const executedTradesSlice = createSlice({
  name: 'executedTrades',
  initialState,
  reducers: {
    addExecutedTrades: (
      state: ExecutedTradesProps,
      { payload }: PayloadAction<ExecutedTradesProps>,
    ) => {
      const currentState = current(state);

      const newbdrId = Object.keys(payload)[0];
      const newExecutedLegIds = Object.values(payload)[0];

      if (Object.keys(currentState).includes(newbdrId)) {
        const newState = Object.entries(currentState).reduce((acc, [brdId, executedLegIds]) => {
          if (newbdrId === brdId) {
            acc[brdId] = [...executedLegIds, ...newExecutedLegIds];
          } else {
            acc[brdId] = executedLegIds;
          }

          return acc;
        }, {} as Record<string, string[]>);

        state = newState;
      } else {
        state = { ...currentState, ...payload };
      }

      return state;
    },
  },
});

export const { addExecutedTrades } = executedTradesSlice.actions;

export const selectexecutedTrades = (state: RootState) => state.executedTrades;

export default executedTradesSlice.reducer;