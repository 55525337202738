import { CreditCheck } from '@/models/rfs';

export function getErrorType(errorCode: string | undefined, errorMessage: string | undefined) {
  if (errorCode === undefined) {
    return 'OTHER';
  }
  if (errorCode.includes('OVERSHOT')) {
    return 'OVERSHOT';
  }
  if (errorCode.includes('UNDERSHOT')) {
    return 'UNDERSHOT';
  }
  if (errorCode.includes('CREDIT_CHECK')) {
    if (errorMessage === undefined) {
      return 'OTHER';
    }
    switch (errorMessage) {
      case 'CVAR':
        return 'CREDIT_CHECK.CVAR';
      case 'NOLIMIT':
        return 'CREDIT_CHECK.NOLIMIT';
      case 'RDL':
        return 'CREDIT_CHECK.RDL';
      default:
        break;
    }
  }
  if (errorCode.includes('CUTOFF')) {
    return 'CUTOFF';
  }
  return 'OTHER';
}

export interface ErrorLimit {
  amount: number;
  currency?: string;
}
export const errorLimit = (
  errorCode: string | undefined,
  ErrorMessage: string | undefined,
  creditCheck: CreditCheck | undefined,
): ErrorLimit | undefined => {
  if (errorCode && errorCode.includes('OVERSHOT')) {
    const userLimit = ErrorMessage?.match(/user limit (\d*\.?\d*E?\d*) ([A-Z]{3})/);
    return userLimit
      ? {
          amount: parseFloat(userLimit[1]),
          currency: userLimit[2],
        }
      : undefined;
  }
  if (errorCode && errorCode.includes('UNDERSHOT')) {
    const userLimit = ErrorMessage?.match(/\(in ([A-Z]{3})\) .* less than (\d*\.?\d*E?\d*)/);
    return userLimit
      ? {
          currency: userLimit[1],
          amount: parseFloat(userLimit[2]),
        }
      : undefined;
  }
  if (errorCode && errorCode.includes('CREDIT_CHECK') && creditCheck?.Type === 'RDL') {
    return {
      amount: parseFloat(creditCheck.Limit!),
    };
  }
};

export const link = 'hedgeconnect@sgcib.com';
