import { PropsWithChildren } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

interface ForwardRateTooltipProps {}

// -------------------------------------------------------

export function ForwardRateTooltip({ children }: PropsWithChildren<ForwardRateTooltipProps>) {
  return (
    <div className="tooltip-wrapper position-absolute" style={{ width: 0, height: 0 }}>
      <div
        className="position-relative show popover bs-popover-left"
        role="tooltip"
        style={{ top: -15, left: -215 }} // It's magic ✨
      >
        <div className="shadow p-2">{children}</div>
      </div>
      <div
        className="position-relative show popover bs-popover-left border-0"
        style={{ width: 0, top: -55, left: -216 }} // It's magic ✨
      >
        <div className="tooltip-arrow"></div>
      </div>
    </div>
  );
}

// -------------------------------------------------------
// -------------------------------------------------------

interface ForwardRateTooltipLineProps {
  labelId: string;
  value: number;
  precision: number;
  alwaysShowSign?: boolean;
}

// -------------------------------------------------------

export function ForwardRateTooltipLine({
  labelId,
  value,
  precision,
  alwaysShowSign = false,
}: ForwardRateTooltipLineProps): JSX.Element {
  return (
    <div className="d-flex justify-content-between w-100">
      <span className="text-secondary fw-bold">
        <FormattedMessage id={labelId} />
      </span>

      <FormattedNumber
        value={value}
        signDisplay={alwaysShowSign ? 'always' : undefined}
        minimumFractionDigits={precision}
        maximumFractionDigits={precision}
      />
    </div>
  );
}
