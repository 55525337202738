import { useAppSelector } from '@/features/hooks';
import { selectProduct } from '@/features/product/productSlice';
import { isNotDefined } from '@sgme/fp';
import { FormattedMessage } from 'react-intl';
import { CompanyName } from '@/components/content/CompanyName';
import { LoadingSkeleton } from '@/components/content/LoadingSkeleton';

export function LoadOperations(): React.ReactElement | null {
  const { legsByCurrencyPair } = useAppSelector(selectProduct);
  if (isNotDefined(legsByCurrencyPair)) {
    return null;
  }
  const legs = Object.values(legsByCurrencyPair).flat();
  if (isNotDefined(legs)) {
    return null;
  }

  return (
    <main className="p-0 d-flex flex-column">
      <div className="container mb-3">
        <div className="d-flex justify-content-between mt-48px mb-24px">
          <div className="d-flex flex-column">
            <h2 className='display-3'>
              <FormattedMessage id="Operations.header" values={{ operationsCount: legs.length }} />
            </h2>
            <h5 className="mt-2">
              <CompanyName allIsDone={false} />
            </h5>
          </div>
        </div>
      </div>
      <div className="bg-lvl2 flex-grow-1 border-top pb-48px">
        <div className="container mb-3">
          <div className="mt-48px mb-24px">
            {legs.map((_leg, index) => (
              <div key={index} className="card card-bordered mb-2">
                <div className="card-body">
                  <LoadingSkeleton />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}
