import styled from '@emotion/styled';
import { Way } from '@/models/product';

interface LegWayProps {
  displayWay: Way | undefined;
  hasErrors: boolean;
  hasWarnings: boolean;
}

// -------------------------------------------------------

export function LegWay({ displayWay, hasErrors, hasWarnings }: LegWayProps) {
  return (
    <WayIcon className="me-2" way={displayWay!} hasErrors={hasErrors} hasWarnings={hasWarnings}>
      <i className="icon icon-sm">{displayWay === 'Buy' ? 'arrow_downward' : 'arrow_upward'}</i>
    </WayIcon>
  );
}

// -------------------------------------------------------

const buyHSL = '329, 56%, 49%';
const sellHSL = '180, 100%, 36%';

// -------------------------------------------------------

const WayIcon = styled.div<{ way: 'Buy' | 'Sell'; hasErrors: boolean; hasWarnings: boolean }>`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;

  background-color: ${({ way, hasErrors, hasWarnings }) =>
    hasErrors
      ? 'hsla(350, 100%, 45%, 0.1)'
      : hasWarnings
        ? 'hsla(32, 100%, 45%, 0.1)'
        : way === 'Buy'
          ? `hsla(${buyHSL}, 0.1)`
          : `hsla(${sellHSL}, 0.1)`};

  & > .icon {
    color: ${({ way, hasErrors, hasWarnings }) =>
      hasErrors || hasWarnings
        ? 'var(--bs-red)'
        : way === 'Buy'
          ? `hsla(${buyHSL}, 1)`
          : `hsla(${sellHSL}, 1)`};
  }
`;
