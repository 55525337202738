import { cancelStream } from '@/features/rfs/rfsSlice';
import { AppListenerEffectAPI } from '@/state/store';


export async function cancelQuote(
  { payload }: ReturnType<typeof cancelStream>,
  {
    dispatch,
    take,
    extra: {
      webApiEndpoints: { cancelQuote },
    },
  }: AppListenerEffectAPI,
) {

    dispatch(cancelQuote.initiate(payload.rfsId));
}
