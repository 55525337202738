import { useAppDispatch, useAppSelector } from '@/features/hooks';
import { chooseBdrId, CompanyDataStatus, selectChoosenBdrId } from '@/features/user/userSlice';
import { Company } from '@/models/user';
import { useCallback } from 'react';
import { CompanyTooltip } from './CompanyTooltip';

export interface CompanyItemForWidgetProps {
  company: Company;
  companyDataStatus: CompanyDataStatus;
}

export function CompanyItemForWidget({ company, companyDataStatus }: CompanyItemForWidgetProps) {
  const selectedBdrId = useAppSelector(selectChoosenBdrId);

  const dispatch = useAppDispatch();

  const isSelected = company.companyBdrId === selectedBdrId;

  const onClickSelect = useCallback(() => {
    dispatch(chooseBdrId(company.companyBdrId));
  }, [company.companyBdrId]);

  return (
    <div
      className={`border border-md col-xs-12 col-lg-9  p-3 mt-2 bg-primary-alt border-md ${
        isSelected ? 'border-primary' : 'border-light'
      }`}
      onClick={onClickSelect}
    >
      <div className="form-check d-block">
        <input
          type="radio"
          id={`${company.companyName}-${company.companyBdrId}`}
          name="customRadio"
          className="form-check-input"
          value={company.companyBdrId}
          checked={isSelected}
          onChange={() => {}} // avoid warning in console
        />

        <label
          className={`form-check-label d-flex flex-row align-items-center ${
            company.companyBdrId === selectedBdrId ? 'fw-bold' : 'fw-medium'
          }`}
          htmlFor={company.companyName}
        >
          <span className="flex-fill">{company.companyName}</span>

          <CompanyTooltip company={company} companyDataStatus={companyDataStatus} />
        </label>
      </div>
    </div>
  );
}
