import { useAppDispatch, useAppSelector } from '@/features/hooks';
import { selectProduct } from '@/features/product/productSlice';
import {
  CurrencyPairRfsState,
  ExecutionStatus,
  selectAllRfsByCurrencyPair,
  selectExecutionStatusByCurrencies,
} from '@/features/rfs/rfsSlice';
import { assertIsDefined, assertUnreachable, isDefined } from '@sgme/fp';
import { FormattedMessage } from 'react-intl';
import { LoadingSkeleton } from '@/components/content/LoadingSkeleton';
import { AllProductsByCurrencyPair, mapTolegWithPrice } from '@/components/content/product/AllProductsByCurrencyPair';
import { useEffect } from 'react';
import { BulkLeg } from '@/models/product';
import { currencies } from '@/data/currencies';
import { sumSpotAndFwdPoints } from '@/models/transform/display';
import { mapProductLegToTMS } from '@/utils/adapterInOutTms';
import { CompanyName } from './content/CompanyName';
import { productComesFromTradeRetriever, selectRights } from '@/features/user/userSlice';
import { cleanProducts } from '@/features/executedTrades/executedTradesAction';
import { hasRightToDealLeg } from '@/utils/rights';

type ExecutionResultStatus = Exclude<ExecutionStatus, 'Rejected'> | 'PartiallyDone';

export function ExecutionResult(): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const isFromUrl = useAppSelector(productComesFromTradeRetriever);

  function handleHeaderAction() {
    if (isFromUrl) {
      dispatch(cleanProducts());
    } else {
      window.close();
    }
  }

  const { legsByCurrencyPair } = useAppSelector(selectProduct);
  const allRfsByCurrencyPair = useAppSelector(selectAllRfsByCurrencyPair);
  const { hasForwardRight, hasSpotRight } = useAppSelector(selectRights);

  const executionStatusByCurrencyPair = Object.entries(allRfsByCurrencyPair)
    .filter(([, rfs]) => !rfs.isRejectedOnRequest)
    .reduce(
      (acc, [ccy, rfs]) => {
        if (isDefined(rfs.executionStatus)) {
          acc[ccy] = rfs.executionStatus;
        }
        return acc;
      },
      {} as Record<string, ExecutionStatus | undefined>,
    );

  const someDealsAreDone = Object.values(executionStatusByCurrencyPair).some(
    (executionStatus) => executionStatus === 'Done',
  );

  const someDealsAreClientLatencyAborted = Object.values(executionStatusByCurrencyPair).some(
    (executionStatus) => executionStatus === 'ClientLatencyAborted',
  );

  assertIsDefined(
    executionStatusByCurrencyPair,
    'Cannot display this component before execution has started',
  );

  useEffect(() => {
    const legsWithPrice = mapTolegWithPrice(legsByCurrencyPair, allRfsByCurrencyPair);
    Object.entries(executionStatusByCurrencyPair).forEach(([currencyPair, executionStatus]) => {
      if (executionStatus === 'Done') {
        const bulkLegsToTMS = legsWithPrice[currencyPair].map((leg) => {
          const { precision: spotPrecision } = currencies[leg.currencyPair];
          const legPrecision = leg.type === 'Forward' ? spotPrecision + 1 : spotPrecision;
          const numberFormatter = new Intl.NumberFormat('en-US', {
            maximumFractionDigits: legPrecision,
            minimumFractionDigits: legPrecision,
          });

          const rateValue = sumSpotAndFwdPoints(leg.spot!, leg.fwdPoints!, spotPrecision);
          const rate = numberFormatter.format(rateValue);
          const contraAmount = Math.round(leg.amount * rateValue);

          return mapProductLegToTMS(leg, contraAmount, rate);
        });

        window?.xprops?.onDealDone(bulkLegsToTMS);
      }
    });
  }, [allRfsByCurrencyPair, executionStatusByCurrencyPair, legsByCurrencyPair]);

  const status = getStatus(executionStatusByCurrencyPair);

  const lentgh = getLength(allRfsByCurrencyPair, legsByCurrencyPair);

  const executedLegsCount = Object.entries(legsByCurrencyPair).reduce((acc, [currency, legs]) => {
    const filteredLegs = legs.filter((leg) =>
      hasRightToDealLeg(leg, hasForwardRight, hasSpotRight),
    );

    const isTcErrorOrRequestError =
      allRfsByCurrencyPair[currency]?.executionStatus === 'Rejected' &&
      !allRfsByCurrencyPair[currency]?.isRejectedOnRequest;

    if (isTcErrorOrRequestError) {
      acc -= filteredLegs.length;
    }
    return acc;
  }, lentgh);

  return (
    <main className="p-0 flex-column d-flex">
      <div className="container mb-3">
        <div className="mt-48px mb-24px">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <h2 className={`display-3 ${getTextColor(status)}`}>
                <FormattedMessage
                  id={`ExecutionResult.${status}.header`}
                  values={{
                    operationsCount: lentgh,
                    executedLegsCount,
                    icon: <i className="icon icon-lg pb-1">{getIcon(status)}</i>,
                  }}
                />
              </h2>
              <h5 className="mt-2 fw-bold">
                <CompanyName allIsDone={someDealsAreDone} />
              </h5>
            </div>

            {(someDealsAreDone || someDealsAreClientLatencyAborted) && (
              <div className="my-auto">
                <button
                  type="button"
                  className={`btn btn-lg ${
                    isFromUrl ? 'btn-primary fw-medium' : 'btn-flat-primary'
                  }`}
                  onClick={handleHeaderAction}
                >
                  <FormattedMessage
                    id={`${
                      isFromUrl
                        ? 'ExecutionResult.Done.returnEntity'
                        : `ExecutionResult.${someDealsAreDone ? 'Done' : 'Rejected'}.next`
                    }`}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <ExecutionDetails />

      <div className="bg-lvl2 flex-grow-1 border-top pb-48px">
        <div className="container mb-4">{someDealsAreDone && <AllProductsByCurrencyPair />}</div>
      </div>
    </main>
  );
}

function ExecutionDetails() {
  const executionStatusByCurrencies = useAppSelector(selectExecutionStatusByCurrencies);
  const allExecutionStatus = Object.values(executionStatusByCurrencies);
  const { legsByCurrencyPair } = useAppSelector(selectProduct);

  const legs = Object.values(legsByCurrencyPair).flat();

  return (
    <>
      {allExecutionStatus.every((status) => status === 'Pending') && (
        <ExecutionPending legs={legs} />
      )}
      {allExecutionStatus.every((status) => status === 'Timeout') && <ExecutionTimeout />}
      {allExecutionStatus.every((status) => status === 'ClientLatencyAborted') && (
        <ExecutionClientLatencyAborted />
      )}
    </>
  );
}

function ExecutionPending({ legs }: { legs: readonly BulkLeg[] }) {
  return (
    <div className="bg-lvl2 flex-grow-1 border-top pb-48px">
      <div className="container mb-3">
        <div className="mt-48px mb-24px">
          {legs.map((_leg, index) => (
            <div key={index} className="card card-bordered mb-2">
              <div className="card-body">
                <LoadingSkeleton />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function ExecutionTimeout() {
  return (
    <div className="mt-48px mb-24px spacing-mt-0 border border-md border-primary p-3">
      <div className="d-flex flex-column">
        <div className="mb-3">
          <FormattedMessage
            id="ExecutionResult.Timeout.description"
            values={{
              bold: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </div>
        <div className="mb-3">
          <FormattedMessage id="ExecutionResult.Timeout.description2" />
        </div>
      </div>
    </div>
  );
}

function ExecutionClientLatencyAborted() {
  return (
    <div className="mt-48px mb-24px spacing-mt-0 border border-md border-primary p-3">
      <div className="d-flex flex-column">
        <div className="mb-3">
          <FormattedMessage id="ExecutionResult.ClientLatencyAborted.description" />
        </div>
        <div className="mb-3">
          <FormattedMessage id="ExecutionResult.ClientLatencyAborted.description2" />
        </div>
        <div className="mb-3">
          <FormattedMessage id="ExecutionResult.ClientLatencyAborted.description3" />
        </div>
      </div>
    </div>
  );
}

const errorStatus = ['Rejected', 'Timeout', 'ClientLatencyAborted'];
const getLength = (
  allRfsByCurrencyPair: Record<string, CurrencyPairRfsState>,
  legsByCurrencyPair: Record<string, readonly BulkLeg[]>,
) => {
  const legsLength = Object.values(legsByCurrencyPair)
    .flat()
    .filter((leg) => leg.isSelectedForExecution).length;

  return Object.values(allRfsByCurrencyPair).reduce((acc, rfs) => {
    if (
      rfs.isRejectedOnRequest === false &&
      errorStatus.includes(rfs.executionStatus!) &&
      rfs.legIds
    ) {
      acc += rfs.legIds.length;
    }
    return acc;
  }, legsLength);
};

function getTextColor(execStatus: ExecutionResultStatus) {
  switch (execStatus) {
    case 'Pending':
      return 'text-primary';
    case 'PartiallyDone':
      return 'text-warning';
    case 'Done':
      return 'text-success';
    case 'Timeout':
      return 'text-danger';
    case 'ClientLatencyAborted':
      return 'text-danger';
    default:
      assertUnreachable(execStatus, 'Unknown execution status');
  }
}

function getIcon(execStatus: ExecutionResultStatus) {
  switch (execStatus) {
    case 'Pending':
      return '';
    case 'PartiallyDone':
      return 'info_outline';
    case 'Done':
      return 'check_circle_outline';
    case 'Timeout':
      return 'schedule';
    case 'ClientLatencyAborted':
      return 'schedule';
    default:
      assertUnreachable(execStatus, 'Unknown execution status');
  }
}

export const getStatus = (
  executionStatusByCurrencyPair: Record<string, ExecutionStatus | undefined>,
) => {
  const executionStatus = Object.values(executionStatusByCurrencyPair);

  const allIsDone = executionStatus.every((executionStatus) => executionStatus === 'Done');

  if (allIsDone) {
    return 'Done';
  }

  if (executionStatus.includes('Done')) {
    return 'PartiallyDone';
  }

  if (executionStatus.every((status) => status === 'ClientLatencyAborted')) {
    return 'ClientLatencyAborted';
  }

  if (executionStatus.every((status) => status === 'Timeout')) {
    return 'Timeout';
  }

  return 'Pending';
};
