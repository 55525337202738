import { RootState } from '@/state/store';
import { originalSgwtConnect } from '@/widgets/sgwtConnect';
import { selectRfsId } from '@/features/rfs/rfsSlice';

type HelpRequestConfig = 'timeouteddeal';

export interface HelpRequestPayload {
  application: 'SGME-HedgeConnect';
  config?: HelpRequestConfig;
  details?: string;
  sendConfirmation?: boolean;
  data?: object;
  login?: string;
  userAgent: string;
}

export const toTimeoutedDealMail = (state: RootState, currencyPair: string): HelpRequestPayload => {
  const { userAgent } = navigator;
  // user info is extracted from sgwtConnect
  const login = originalSgwtConnect.getIdTokenClaims()?.sub; //todo should I keep that or put fake user in dev -see swgConnect.ts ?
  const rfsId = selectRfsId(state, currencyPair);
  const selectedCompanyBrdId = state.user.chosenBdrId;

  const details = {
    selectedCompanyBrdId,
    selectedCompanyName: selectedCompanyBrdId
      ? state.user.companies[selectedCompanyBrdId]
      : undefined,
    tradeDate: state.rfs.rfsByCurrencyPair[currencyPair].quoteReply?.frontReceivedTimestamp,
    // // currencyPair: `${state.rfs.buyCurrency}/${state.rfs.sellCurrency}`, //todo what to do with currencyPair since we have several potential deals
  };

  return {
    login,
    userAgent,
    config: 'timeouteddeal',
    application: 'SGME-HedgeConnect', //todo check app name in back and put same casing
    details: JSON.stringify(details),
    sendConfirmation: false,
    data: { multipassId: rfsId },
  };
};
