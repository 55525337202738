import {
  combineReducers,
  configureStore,
  createListenerMiddleware,
  ListenerEffectAPI,
  TypedStartListening,
} from '@reduxjs/toolkit';
import userReducer from '@/features/user/userSlice';
import productReducer from '@/features/product/productSlice';
import streamingReducer from '@/features/streaming/streamingSlice';
import executedTradesReducer from '@/features/executedTrades/executedTradesSlice';
import { webApi } from '../features/api/web.api';
import rfsReducer from '@/features/rfs/rfsSlice';
import stepReducer from '@/features/step/stepSlice';
import errorReducer from '@/features/error/errorSlice';
import { getNewRfsId } from '@/utils/rfs';
import { v4 as getNewGuid } from 'uuid';
import { fxkitApi } from '@/features/api/fxkit.api';

const rootReducer = combineReducers({
  webApi: webApi.reducer,
  fxkitApi: fxkitApi.reducer,
  user: userReducer,
  product: productReducer,
  streaming: streamingReducer,
  executedTrades: executedTradesReducer,
  rfs: rfsReducer,
  step: stepReducer,
  error: errorReducer,
})


export const createStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: { extraArgument } })
        .concat([webApi.middleware, fxkitApi.middleware])
        .prepend(listenerMiddleware.middleware),
  });

// Infer the `RootState` and `AppDispatch` types from the store itself

export type AppStore = ReturnType<typeof createStore>
export type RootState = ReturnType<typeof rootReducer>


// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];

export interface ExtraArgument {
  webApiEndpoints: typeof webApi.endpoints;
  fxKitEndpoints: typeof fxkitApi.endpoints;
  getNewRfsId: () => string;
  getNewGuid: () => string;
  getDateNow: () => Date;
};

export type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch, ExtraArgument>;

const extraArgument = {
  webApiEndpoints: webApi.endpoints,
  fxKitEndpoints: fxkitApi.endpoints,
  getNewRfsId,
  getNewGuid,
  getDateNow: () => new Date(),
} satisfies ExtraArgument;

export const listenerMiddleware = createListenerMiddleware({
  extra: extraArgument,
});

export const startAppListening = listenerMiddleware.startListening as TypedStartListening<
  RootState,
  AppDispatch,
  ExtraArgument
>;

export const store = createStore();