import { ProductLeg, ProductLegFromTms, ProductLegToTms } from '&types/hedgeconnect';
import { currencies } from '@/data/currencies';
import { assertIsDefined } from '@sgme/fp';
import { clearUndefined } from './clearUndefined';

export interface ProductLegWithDisplayWay extends ProductLeg {
  displayWay: 'Buy' | 'Sell';
}

function toCapitalize(string: string): string {
  return `${string.charAt(0)}${string.slice(1).toLowerCase()}`;
}

function getContraAmount(amount: number, rate: string, isInverted: boolean) {
  const floatRate = parseFloat(rate.replace(',', '.'));

  return isInverted ? Math.round(amount / floatRate) : Math.round(amount * floatRate);
}

export function mapProductLegFromTms(
  productLegFromTms: ProductLegFromTms,
): ProductLegWithDisplayWay {
  const type = toCapitalize(productLegFromTms.product);

  const sellCurrency = productLegFromTms.sellCurrency;
  const buyCurrency = productLegFromTms.buyCurrency;

  const shouldCcyPair = [sellCurrency, buyCurrency].join('/');
  const isCcyPairInverted = currencies[shouldCcyPair].isInverted;
  const currencyPair = !isCcyPairInverted ? shouldCcyPair : [buyCurrency, sellCurrency].join('/');
  const [ccy1, ccy2] = currencyPair.split('/');

  const isBuyAmount = Boolean(productLegFromTms.buyAmount);

  const way = isBuyAmount
    ? buyCurrency === ccy2
      ? 'Sell'
      : 'Buy'
    : sellCurrency === ccy1
      ? 'Sell'
      : 'Buy';

  const displayWay = isBuyAmount ? 'Buy' : 'Sell';

  const amount = isBuyAmount ? productLegFromTms.buyAmount! : productLegFromTms.sellAmount!;
  const amountCurrency = isBuyAmount ? buyCurrency : sellCurrency;

  assertIsDefined(productLegFromTms.settlement, 'Cannot execute without expiry date');

  return {
    thirdPartyTradeReference: productLegFromTms.thirdPartyTradeReference,
    type,
    way,
    displayWay,
    currencyPair,
    amount,
    amountCurrency,
    expiryDate: productLegFromTms.settlement,
    ...clearUndefined({
      maturityDateTenor: productLegFromTms.maturityDateTenor,
      company: productLegFromTms.company,
      companyDescription: productLegFromTms.companyDescription,
      sgReference: productLegFromTms.sgReference,
    }),
  };
}

export function mapProductLegToTMS(
  productLeg: ProductLeg,
  contraAmount: number,
  rate: string,
): ProductLegToTms {
  const product = productLeg.type.charAt(0) + productLeg.type.slice(1).toLowerCase();

  const [ccy1, ccy2] = productLeg.currencyPair.split('/');

  let buyAmount;
  let sellAmount;
  let buyCurrency;
  let sellCurrency;

  if (productLeg.way === 'Buy') {
    buyCurrency = ccy1;
    sellCurrency = ccy2;
    if (productLeg.amountCurrency === ccy1) {
      buyAmount = productLeg.amount;
      contraAmount = getContraAmount(buyAmount, rate, false);
    } else {
      sellAmount = productLeg.amount;
      contraAmount = getContraAmount(sellAmount, rate, true);
    }
  } else {
    buyCurrency = ccy2;
    sellCurrency = ccy1;
    if (productLeg.amountCurrency === ccy1) {
      sellAmount = productLeg.amount;
      contraAmount = getContraAmount(sellAmount, rate, false);
    } else {
      buyAmount = productLeg.amount;
      contraAmount = getContraAmount(buyAmount, rate, true);
    }
  }

  return {
    product,
    buyCurrency,
    sellCurrency,
    buyAmount,
    sellAmount,
    settlement: productLeg.expiryDate,
    contraAmount,
    rate,
    
    ...clearUndefined({
      thirdPartyTradeReference: productLeg.thirdPartyTradeReference,
      company: productLeg.company,
      companyDescription: productLeg.companyDescription,
      sgReference: productLeg.sgReference,
      maturityDateTenor: productLeg.maturityDateTenor,
    }),
  };
}
