import { BulkLeg } from '@/models/product';
import {
  QuoteReply,
  MultipassQuoteReply,
  MultipassQuoteAbort,
  MultipassHeartbeat,
  MultipassDealRejected,
  MultipassFillReport,
  MultipassDealDone,
  CreditCheck,
} from '@/models/rfs';
import { RootState } from '@/state/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isDefined, isNotDefined } from '@sgme/fp';

export type ExecutionStatus = 'Pending' | 'Rejected' | 'Done' | 'Timeout' | 'ClientLatencyAborted';

// ███████╗████████╗ █████╗ ████████╗███████╗
// ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██╔════╝
// ███████╗   ██║   ███████║   ██║   █████╗
// ╚════██║   ██║   ██╔══██║   ██║   ██╔══╝
// ███████║   ██║   ██║  ██║   ██║   ███████╗
// ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝   ╚══════╝

export type RfsState = {
  rfsByCurrencyPair: Record<string, CurrencyPairRfsState>;
  currencyPairbyRfsId: Record<string, string>;
};

// TODO: maybe isTmsReady is not in the good place
export type CurrencyPairRfsState =
  | UndefinedRfs
  | StartedRfs
  | PendingRfs
  | ReceivedRfs
  | DealDone
  | FilledDealDone
  | RejectedRfs
  | TimeoutRfs
  | ClientLatencyAbortedRfs;

interface UndefinedRfs {
  rfsId: undefined;
  isTmsReady: false;
  legIds: undefined;

  quoteReply: undefined;
  executionStatus: undefined;
  backOfficeIds: undefined;

  isRejectedOnRequest: false;
  errorCode: undefined;
  errorMessage: undefined;
  creditCheck: undefined;
}

interface StartedRfs {
  rfsId: string;
  isTmsReady: boolean;

  legIds: string[];
  quoteReply: undefined;
  executionStatus: undefined;
  backOfficeIds: undefined;

  isRejectedOnRequest: false;
  errorCode: undefined;
  errorMessage: undefined;
  creditCheck: undefined;
}

interface ReceivedRfs {
  rfsId: string;
  isTmsReady: boolean;
  legIds: string[];

  quoteReply: QuoteReply;
  executionStatus: undefined;
  backOfficeIds: undefined;

  isRejectedOnRequest: false;
  errorCode: undefined;
  errorMessage: undefined;
  creditCheck: undefined;
}

export interface PendingRfs {
  rfsId: string;
  isTmsReady: true;
  legIds: string[];

  quoteReply: QuoteReply;
  executionStatus: 'Pending';
  backOfficeIds: undefined;

  isRejectedOnRequest: false;
  errorCode: undefined;
  errorMessage: undefined;
  creditCheck: undefined;
}

export interface DealDone {
  rfsId: string;
  isTmsReady: true;
  legIds: string[];

  quoteReply: QuoteReply;
  executionStatus: 'Done';
  backOfficeIds: undefined;

  isRejectedOnRequest: false;
  errorCode: undefined;
  errorMessage: undefined;
  creditCheck: undefined;
}

interface FilledDealDone {
  rfsId: string;
  isTmsReady: true;
  legIds: string[];

  quoteReply: QuoteReply;
  executionStatus: 'Done';
  backOfficeIds: string[];

  isRejectedOnRequest: boolean;
  errorCode: undefined;
  errorMessage: undefined;
  creditCheck: undefined;
}

export interface RejectedRfs {
  rfsId: string;
  isTmsReady: true;
  legIds: string[];

  quoteReply: undefined;
  executionStatus: 'Rejected';
  backOfficeIds: undefined;

  isRejectedOnRequest: boolean;
  errorCode: string;
  errorMessage: string;
  creditCheck: CreditCheck;
}

interface TimeoutRfs {
  rfsId: string;
  isTmsReady: true;
  legIds: string[];

  quoteReply: undefined;
  executionStatus: 'Timeout';
  backOfficeIds: undefined;

  isRejectedOnRequest: boolean;
  errorCode: undefined;
  errorMessage: undefined;
  creditCheck: undefined;
}

interface ClientLatencyAbortedRfs {
  rfsId: string;
  isTmsReady: true;
  legIds: string[];

  quoteReply: undefined;
  executionStatus: 'ClientLatencyAborted';
  backOfficeIds: undefined;

  isRejectedOnRequest: boolean;
  errorCode: undefined;
  errorMessage: undefined;
  creditCheck: undefined;
}

// ██╗███╗   ██╗██╗████████╗
// ██║████╗  ██║██║╚══██╔══╝
// ██║██╔██╗ ██║██║   ██║
// ██║██║╚██╗██║██║   ██║
// ██║██║ ╚████║██║   ██║
// ╚═╝╚═╝  ╚═══╝╚═╝   ╚═╝

const initialState: RfsState = {
  currencyPairbyRfsId: {},
  rfsByCurrencyPair: {},
};

// ███████╗██╗     ██╗ ██████╗███████╗███████╗
// ██╔════╝██║     ██║██╔════╝██╔════╝██╔════╝
// ███████╗██║     ██║██║     █████╗  ███████╗
// ╚════██║██║     ██║██║     ██╔══╝  ╚════██║
// ███████║███████╗██║╚██████╗███████╗███████║
// ╚══════╝╚══════╝╚═╝ ╚═════╝╚══════╝╚══════╝

export const rfsSlice = createSlice({
  name: 'rfs',
  initialState: initialState as RfsState,
  reducers: {
    streamStarted: (
      state: RfsState,
      action: PayloadAction<{ currencyPair: string; rfsId: string }>,
    ) => ({
      rfsByCurrencyPair: {
        ...state.rfsByCurrencyPair,
        [action.payload.currencyPair]: {
          rfsId: action.payload.rfsId,
          isTmsReady: state.rfsByCurrencyPair[action.payload.currencyPair]?.isTmsReady, // TODO should be default to false when undefined

          quoteReply: undefined,
          executionStatus: undefined,
          backOfficeIds: undefined,

          isRejectedOnRequest: false,
          errorCode: undefined,
          errorMessage: undefined,
          creditCheck: undefined,
        },
      },

      currencyPairbyRfsId: currencyPairbyRfsIdAdapterForStreamStarted(
        state.currencyPairbyRfsId,
        action.payload.currencyPair,
        action.payload.rfsId,
      ),
    } as RfsState),

    tmsReady: (state: RfsState, action: PayloadAction<string>) => {
      const currencyPair = state.currencyPairbyRfsId[action.payload];

      if (state.rfsByCurrencyPair[currencyPair].rfsId !== undefined) {
        const newState = {
          ...state,
          rfsByCurrencyPair: {
            ...state.rfsByCurrencyPair,
            [currencyPair]: {
              ...state.rfsByCurrencyPair[currencyPair],
              isTmsReady: true,
            },
          },
        } as RfsState;

        return newState;
      }
    },

    errorOnRequest: (state: RfsState, action: PayloadAction<string>) => {
      const currencyPair = action.payload;

      if (state.rfsByCurrencyPair[currencyPair]?.rfsId !== undefined) {
        const newState = {
          ...state,
          rfsByCurrencyPair: {
            ...state.rfsByCurrencyPair,
            [currencyPair]: {
              ...state.rfsByCurrencyPair[currencyPair],
              isRejectedOnRequest: true,
            },
          },
        } as RfsState;

        return newState;
      }
    },

    quoteReceived: (
      state: RfsState,
      action: PayloadAction<{
        quoteReply: QuoteReply;
        legsByCurrencyPair: Record<string, readonly BulkLeg[]>;
      }>,
    ) => {
      const currencyPair = state.currencyPairbyRfsId[action.payload.quoteReply.rfsId];
      const legIds = Object.values(action.payload.legsByCurrencyPair[currencyPair])
        .filter(
          (leg) =>
            leg.isSelectedForExecution && isNotDefined(leg.errors) && isNotDefined(leg.warnings),
        )
        .map((leg) => leg.thirdPartyTradeReference!);

      if (
        isDefined(state.rfsByCurrencyPair[currencyPair].rfsId) &&
        state.rfsByCurrencyPair[currencyPair].executionStatus === undefined
      ) {
        const newState = {
          ...state,
          rfsByCurrencyPair: {
            ...state.rfsByCurrencyPair,
            [currencyPair]: {
              ...state.rfsByCurrencyPair[currencyPair],
              legIds,
              quoteReply: action.payload.quoteReply,
              executionStatus: undefined,
              backOfficeIds: undefined,

              isRejectedOnRequest: false,
              errorCode: undefined,
              errorMessage: undefined,
              creditCheck: undefined,
            },
          },
        } as RfsState;

        return newState;
      }
    },

    heartbeat: (_state: RfsState, _action: PayloadAction<MultipassHeartbeat>) => { },

    quoteAbort: (state: RfsState, action: PayloadAction<MultipassQuoteAbort>) => {
      const currencyPair = state.currencyPairbyRfsId[action.payload.QuoteReject.RfsId];

      const stateRfsId = state.rfsByCurrencyPair[currencyPair]?.rfsId;
      if (stateRfsId === undefined || state.rfsByCurrencyPair[currencyPair].isTmsReady === false) {
        return;
      }

      const newState: RfsState =
        state.rfsByCurrencyPair[currencyPair].executionStatus !== 'Done'
          ? ({
            ...state,
            rfsByCurrencyPair: {
              ...state.rfsByCurrencyPair,
              [currencyPair]: {
                rfsId: stateRfsId,
                isTmsReady: state.rfsByCurrencyPair[currencyPair].isTmsReady,

                quoteReply: undefined,
                executionStatus: 'Rejected',
                backOfficeIds: undefined,

                isRejectedOnRequest: state.rfsByCurrencyPair[currencyPair]?.isRejectedOnRequest,
                errorCode: action.payload.QuoteReject.ErrorCode,
                errorMessage: action.payload.QuoteReject.ErrorMessage,
                creditCheck: action.payload.QuoteReject.CreditCheck,
              },
            },
          } as RfsState)
          : ({
            ...state,
            rfsByCurrencyPair: {
              ...state.rfsByCurrencyPair,
              [currencyPair]: {
                rfsId: state.rfsByCurrencyPair[currencyPair].rfsId,
                isTmsReady: state.rfsByCurrencyPair[currencyPair].isTmsReady,

                quoteReply: undefined,
                executionStatus: undefined,
                backOfficeIds: undefined,

                isRejectedOnRequest: false,
                errorCode: undefined,
                errorMessage: undefined,
                creditCheck: undefined,
              },
            },
          } as RfsState);

      return newState;
    },

    quoteWithdraw: (state: RfsState, action: PayloadAction<MultipassQuoteReply>) => {
      // Ignored for MVP scope
    },

    execute: (state: RfsState, action: PayloadAction<string>): RfsState | undefined => {
      const currencyPair = action.payload;
      const stateRfsId = state.rfsByCurrencyPair[currencyPair]?.rfsId;
      if (isDefined(stateRfsId) && state.rfsByCurrencyPair[currencyPair]?.isTmsReady) {
        return {
          ...state,
          rfsByCurrencyPair: {
            ...state.rfsByCurrencyPair,
            [currencyPair]: {
              legIds: state.rfsByCurrencyPair[currencyPair].legIds,
              rfsId: stateRfsId,
              isTmsReady: true,

              quoteReply: state.rfsByCurrencyPair[currencyPair].quoteReply!,
              executionStatus: 'Pending',
              backOfficeIds: undefined,

              isRejectedOnRequest: false,
              errorCode: undefined,
              errorMessage: undefined,
              creditCheck: undefined,
            },
          },
        } as RfsState;
      }
    },

    dealRejected: (state: RfsState, action: PayloadAction<MultipassDealRejected>) => {
      const currencyPair = state.currencyPairbyRfsId[action.payload.Execute.RfsId];
      const rfsState = state.rfsByCurrencyPair[currencyPair];

      if (isDefined(rfsState) && state.rfsByCurrencyPair[currencyPair].isTmsReady) {
        const newState: RfsState = {
          ...state,
          rfsByCurrencyPair: {
            ...state.rfsByCurrencyPair,
            [currencyPair]: {
              legIds: state.rfsByCurrencyPair[currencyPair].legIds,
              rfsId: rfsState.rfsId,
              isTmsReady: true,

              quoteReply: undefined,
              executionStatus: 'Rejected',
              backOfficeIds: undefined,

              isRejectedOnRequest: rfsState?.isRejectedOnRequest,
              errorCode: action.payload.DealReject.ErrorCode,
              errorMessage: action.payload.DealReject.ErrorMessage,
              creditCheck: action.payload.DealReject.CreditCheck,
            },
          },
        } as RfsState;

        return newState;
      }
    },

    dealDone: (state: RfsState, action: PayloadAction<MultipassDealDone>) => {
      const currencyPair = state.currencyPairbyRfsId[action.payload.Execute.RfsId];

      const rfsState = state.rfsByCurrencyPair[currencyPair];
      if (
        state.rfsByCurrencyPair[currencyPair].executionStatus === 'Pending' &&
        isDefined(rfsState)
      ) {
        const newState: RfsState = {
          ...state,
          rfsByCurrencyPair: {
            ...state.rfsByCurrencyPair,
            [currencyPair]: {
              rfsId: rfsState.rfsId,
              isTmsReady: true,
              legIds: state.rfsByCurrencyPair[currencyPair].legIds,
              quoteReply: state.rfsByCurrencyPair[currencyPair].quoteReply,
              executionStatus: 'Done',
              backOfficeIds: undefined,

              isRejectedOnRequest: false,
              errorCode: undefined,
              errorMessage: undefined,
              creditCheck: undefined,
            } as DealDone,
          },
        };

        return newState;
      }
    },

    fillReport: (state: RfsState, action: PayloadAction<MultipassFillReport>) => {
      const currencyPair = state.currencyPairbyRfsId[action.payload.Execute.RfsId];
      const rfsState = state.rfsByCurrencyPair[currencyPair];

      if (state.rfsByCurrencyPair[currencyPair].executionStatus === 'Done' && isDefined(rfsState)) {
        // example of tradeId : FXI-3324467:1
        // FXI-3324467:1 => apply regex =>  FXI-3324467
        const extractBackOfficeIdRegexp = /([^:]*)(?:[:].*)?/;
        const backOfficeIds = action.payload.BackOfficeReply.TradeIds.map(
          (tradeId) => tradeId.match(extractBackOfficeIdRegexp)?.[1],
        ).filter(isDefined);

        const newState: RfsState = {
          ...state,
          rfsByCurrencyPair: {
            ...state.rfsByCurrencyPair,
            [currencyPair]: {
              rfsId: rfsState.rfsId,
              isTmsReady: true,
              legIds: state.rfsByCurrencyPair[currencyPair].legIds,

              quoteReply: state.rfsByCurrencyPair[currencyPair].quoteReply,
              executionStatus: 'Done',
              backOfficeIds,

              isRejectedOnRequest: false,
              errorCode: undefined,
              errorMessage: undefined,
              creditCheck: undefined,
            } as FilledDealDone,
          },
        };

        return newState;
      }
    },

    dealTimeout: (state: RfsState, action: PayloadAction<string>) => {
      const currencyPair = state.currencyPairbyRfsId[action.payload];
      const stateRfsId = state.rfsByCurrencyPair[currencyPair]?.rfsId;

      if (
        action.payload === stateRfsId &&
        state.rfsByCurrencyPair[currencyPair].executionStatus === 'Pending'
      ) {
        const newState: RfsState = {
          ...state,
          rfsByCurrencyPair: {
            ...state.rfsByCurrencyPair,
            [currencyPair]: {
              rfsId: stateRfsId,
              isTmsReady: true,
              legIds: state.rfsByCurrencyPair[currencyPair]?.legIds,

              quoteReply: undefined,
              executionStatus: 'Timeout',
              backOfficeIds: undefined,

              isRejectedOnRequest: state.rfsByCurrencyPair[currencyPair]?.isRejectedOnRequest,
              errorCode: undefined,
              errorMessage: undefined,
              creditCheck: undefined,
            },
          },
        } as RfsState;

        return newState;
      }
    },

    clientLatencyAborted: (state: RfsState, action: PayloadAction<string>) => {
      const currencyPair = state.currencyPairbyRfsId[action.payload];
      const stateRfsId = state.rfsByCurrencyPair[currencyPair]?.rfsId;

      if (
        action.payload === stateRfsId &&
        state.rfsByCurrencyPair[currencyPair].executionStatus === 'Pending'
      ) {
        const newState: RfsState = {
          ...state,
          rfsByCurrencyPair: {
            ...state.rfsByCurrencyPair,
            [currencyPair]: {
              rfsId: stateRfsId,
              isTmsReady: true,
              legIds: state.rfsByCurrencyPair[currencyPair]?.legIds,

              quoteReply: undefined,
              executionStatus: 'ClientLatencyAborted',
              backOfficeIds: undefined,

              isRejectedOnRequest: state.rfsByCurrencyPair[currencyPair]?.isRejectedOnRequest,
              errorCode: undefined,
              errorMessage: undefined,
              creditCheck: undefined,
            },
          },
        } as RfsState;

        return newState;
      }
    },

    cancelStream: (
      state: RfsState,
      action: PayloadAction<{ currencyPair?: string; rfsId: string }>,
    ) => {

      const currencyPair = action.payload.currencyPair ?? state.currencyPairbyRfsId[action.payload.rfsId];

      return {
        ...state,
        rfsByCurrencyPair: {
          ...Object.entries(state.rfsByCurrencyPair)
            .filter(([stateCurrencyPair, _rfs]) => stateCurrencyPair !== currencyPair)
            .reduce((res, [stateCurrencyPair, rfs]) => {
              res[stateCurrencyPair] = rfs;
              return res;
            }, {} as Record<string, CurrencyPairRfsState>),
        },
        currencyPairbyRfsId: {
          ...Object.entries(state.currencyPairbyRfsId)
            .filter(([stateRfsId, _stateCurrencyPair]) => stateRfsId !== action.payload.rfsId)
            .reduce((res, [key, value]) => {
              res[key] = value;

              return res;
            }, {} as Record<string, string>),
        },
      } as RfsState;
    },

    emptyRfsStore: (state: RfsState) => ({
      ...state,
      rfsByCurrencyPair: {},
      currencyPairbyRfsId: {},
    }),
  },
});

//  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
// ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
// ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
// ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
// ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
// ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

export const {
  streamStarted,
  tmsReady,
  quoteReceived,
  heartbeat,
  quoteAbort,
  quoteWithdraw,
  execute,
  dealRejected,
  dealDone,
  fillReport,
  dealTimeout,
  errorOnRequest,
  emptyRfsStore,
  cancelStream,
  clientLatencyAborted,
} = rfsSlice.actions;

// ███████╗███████╗██╗     ███████╗ ██████╗████████╗ ██████╗ ██████╗ ███████╗
// ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝
// ███████╗█████╗  ██║     █████╗  ██║        ██║   ██║   ██║██████╔╝███████╗
// ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║   ██║██╔══██╗╚════██║
// ███████║███████╗███████╗███████╗╚██████╗   ██║   ╚██████╔╝██║  ██║███████║
// ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝

export const selectRfsByCurrencyPair = (state: RootState, currencyPair: string) =>
  state.rfs?.rfsByCurrencyPair?.[currencyPair];

export const selectCurrencyPairByRfsId = (state: RootState, rfsId: string) =>
  state.rfs?.currencyPairbyRfsId[rfsId];

export const selectAllRfsByCurrencyPair = (state: RootState) => state.rfs.rfsByCurrencyPair;

export const selectStateCurrencyPairbyRfsId = (state: RootState) => state.rfs.currencyPairbyRfsId;

export const selectQuoteReply = (state: RootState, currencyPair: string) =>
  selectRfsByCurrencyPair(state, currencyPair).quoteReply;

export const selectRfsId = (state: RootState, currencyPair: string) =>
  selectRfsByCurrencyPair(state, currencyPair)?.rfsId;

export const selectExecutionStatus = (state: RootState, currencyPair: string) =>
  selectRfsByCurrencyPair(state, currencyPair).executionStatus;

export const selectErrorCode = (state: RootState, currencyPair: string) =>
  selectRfsByCurrencyPair(state, currencyPair)?.errorCode;

export const selectIsRejectedOnRequest = (state: RootState, currencyPair: string) =>
  selectRfsByCurrencyPair(state, currencyPair)?.isRejectedOnRequest;

export const selectErrorMessage = (state: RootState, currencyPair: string) =>
  selectRfsByCurrencyPair(state, currencyPair)?.errorMessage;

export const selectCreditCheck = (state: RootState, currencyPair: string) =>
  selectRfsByCurrencyPair(state, currencyPair)?.creditCheck;

export const selectExecutionStatusForAllCurrencies = createSelector([
  selectAllRfsByCurrencyPair
], allRfs => Object.values(allRfs).map((rfs) => rfs.executionStatus))

export const selectExecutionStatusByCurrencies = createSelector([selectAllRfsByCurrencyPair], allRfs => Object.entries(allRfs).reduce((acc, [currencyPair, rfs]) => {
  if (isDefined(rfs.executionStatus)) {
    acc[currencyPair] = rfs.executionStatus;
    return acc;
  }
  return acc;
}, {} as Record<string, ExecutionStatus>))


export const selectAllRfsIds = createSelector([selectStateCurrencyPairbyRfsId], recordCurrencyPairByRfsId => Object.keys(recordCurrencyPairByRfsId));
export const selectAllCurrencyPairs = createSelector([selectStateCurrencyPairbyRfsId], recordCurrencyPairByRfsId => Object.values(recordCurrencyPairByRfsId));

export const selectErrorCodeForAllCurrencies = (state: RootState) => Object.values(selectAllRfsByCurrencyPair(state)).filter((rfs) => isDefined(rfs.errorCode));
export const selectErrorMessageForAllCurrencies = (state: RootState) => Object.values(selectAllRfsByCurrencyPair(state)).filter((rfs) =>
  isDefined(rfs.errorMessage),
);

// Todo rfsId can be undefined ? how handle it
export const selectCurrencyPair =
  (state: RootState) => (rfsId?: string) => selectCurrencyPairByRfsId(state, rfsId ?? '')


// ██████╗ ███████╗██████╗ ██╗   ██╗ ██████╗███████╗██████╗
// ██╔══██╗██╔════╝██╔══██╗██║   ██║██╔════╝██╔════╝██╔══██╗
// ██████╔╝█████╗  ██║  ██║██║   ██║██║     █████╗  ██████╔╝
// ██╔══██╗██╔══╝  ██║  ██║██║   ██║██║     ██╔══╝  ██╔══██╗
// ██║  ██║███████╗██████╔╝╚██████╔╝╚██████╗███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝  ╚═════╝╚══════╝╚═╝  ╚═╝

export default rfsSlice.reducer;

const currencyPairbyRfsIdAdapterForStreamStarted = (
  currencyPairbyRfsId: Record<string, string>,
  currencyPair: string,
  rfsId: string,
) => {
  const shouldAddRfsIdForCurrencyPair = isDefined(
    Object.values(currencyPairbyRfsId).find((currentCurPair) => currentCurPair === currencyPair),
  );
  const modifiedCurrencyPairbyRfsId = Object.entries(currencyPairbyRfsId).reduce(
    (acc, [currentRfsId, currentCurrencyPair]) => {
      if (currentCurrencyPair === currencyPair) {
        acc[rfsId] = currentCurrencyPair;
      } else {
        acc[currentRfsId] = currentCurrencyPair;
      }
      return acc;
    },
    {} as Record<string, string>,
  );
  return !shouldAddRfsIdForCurrencyPair
    ? {
      ...currencyPairbyRfsId,
      [rfsId]: currencyPair,
    }
    : modifiedCurrencyPairbyRfsId;
};
