import { useAppSelector } from '@/features/hooks';
import { SelectCompanyForTradeRetrieverSource } from './content/companies/SelectCompanyForTradeRetrieverSource';
import { productComesFromTradeRetriever } from '@/features/user/userSlice';
import { SelectCompanyForWidgetSource } from './content/companies/SelectCompanyForWidgetSource';

export function SelectCompany(): React.ReactElement | null {
  const isFromUrl = useAppSelector(productComesFromTradeRetriever);

  return isFromUrl ? <SelectCompanyForTradeRetrieverSource /> : <SelectCompanyForWidgetSource />;
}
