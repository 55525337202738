import { SGWTConnectError } from "@sgwt/connect-core";
import { sgwtConnect } from "./sgwtConnect";
import { UserAuthorization } from "@/main";

export const BUS_TOPIC_GLOBALLANGUAGE = 'global.language';
export const BUS_TOPIC_SGCONNECT_ACCESSTOKEN = 'sg-connect.access-token';
export const BUS_TOPIC_SGCONNECT_USERCONNECTION = 'sg-connect.user-connection';
export const BUS_TOPIC_SGCONNECT_USERINFO = 'sg-connect.user-info';
export const BUS_TOPIC_SGCONNECT_GRANTEDSCOPE = 'sg-connect.granted-scope';
export const BUS_TOPIC_SGCONNECT_READY = 'sgwt-connect--ready';

type BusEventCallback<T> = (payload: T | undefined) => void;
type SubscriptionHandle = any;

export interface SgWidgetsBus {
  dangerouslyGetCurrentValue<T>(topicName: string): T | undefined | null;

  subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle;

  unsubscribe(handle: SubscriptionHandle): void;
}

type Subscribers = {
  onUserInfo: (userInfo: UserAuthorization | undefined) => void;
};


export function requestAuthorization(
  onAuthorizedSuccess: () => void,
  onAuthorizationError: (authorizationError: SGWTConnectError) => void,
  subscribers: Subscribers,
) {
  if (sgwtConnect.isAuthorized()) {
    if ((window as any).SGWTWidgetConfiguration) {
      subscribeToWidgetBus(subscribers);
    }

    onAuthorizedSuccess(); // render app
    // connect signalR need store

  } else {
    const authorizationError = sgwtConnect.getAuthorizationError();

    if (authorizationError) {
      onAuthorizationError(authorizationError);
    } else {
      sgwtConnect.requestAuthorization();
    }
  }
}

export function getWidgetBus(): SgWidgetsBus | null {
  const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
  return widgetConfiguration && widgetConfiguration.bus ? widgetConfiguration.bus : null;
}

function subscribeToWidgetBus({ onUserInfo }: Subscribers) {
  const bus = getWidgetBus();

  if (bus && onUserInfo) {
    bus.subscribe(BUS_TOPIC_SGCONNECT_USERINFO, onUserInfo);
  }
}


export function getWidget<T extends HTMLElement>(tagName: string) {
  return document.querySelector(tagName) as T;
}

export function getAuthorizationHeader(): string | null {
  return sgwtConnect.getAuthorizationHeader();
}

interface UserConnection {
  connected: boolean;
  claims: Record<string, string>;
  mail: string;
}

export function getUserConnection(): UserConnection | undefined | null {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<UserConnection>(BUS_TOPIC_SGCONNECT_USERCONNECTION);
  }
  return undefined;
}

export function getAuthorizationToken(): string | undefined {
  const authorizationHeader = getAuthorizationHeader();
  if (authorizationHeader == null) {
    return undefined;
  }
  return authorizationHeader.replace('Bearer ', '');
}
