export type ClientDataRequest = {
  bdrIds: number[];
};

// ---------------------------------------

export type ClientDataResponse = ClientDataOKResponse | ClientDataKOResponse;

// ---------------------------------------

export type ClientDataOKResponse = ClientData[];

// ---------------------------------------

export type ClientData = {
  bdrId: number;
  siret: string;
  country: string;
};

// ---------------------------------------

export type ClientDataKOResponse = {
  error: number;
};

// ---------------------------------------
// ---------------------------------------
// ---------------------------------------

export const isClientDataResponseOk = (
  response: ClientDataResponse,
): response is ClientDataOKResponse => Array.isArray(response);

export const isClientDataResponseKo = (
  response: ClientDataResponse,
): response is ClientDataKOResponse => 'error' in response;
