import { ClientDataKOResponse, ClientDataOKResponse } from '@/models/clientInformation';
import { Company, User } from '@/models/user';
import { RootState } from '@/state/store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isDefined } from '@sgme/fp';
import { AccountCenterNavigateAsItem } from '@sgwt/sgwt-widgets-react';

type AvailablePermission = 'canAccessFromUrl';

export interface UserState {
  currentUser: User | undefined;
  canNavigateAs: boolean | undefined;
  navigableUsersList: AccountCenterNavigateAsItem[];
  selectedUser: User | undefined;

  companies: Record<number, Company>;
  chosenBdrId: number | undefined;
  companyDataStatus: CompanyDataStatus;

  canExecute: boolean | undefined;
  productSource: ProductSource | undefined;

  permissions: Record<AvailablePermission, boolean>;
  thirdPartyId: string | null;
}

export type CompanyDataStatus = 'NotLoaded' | 'Loading' | 'Loaded' | 'Error';

export enum ProductSource {
  FromWidget = 'FromWidget',
  FromTradeRetriever = 'FromTradeRetriever',
}

const initialState: UserState = {
  currentUser: undefined,
  navigableUsersList: [],
  canNavigateAs: undefined,
  selectedUser: undefined,

  companies: {},
  chosenBdrId: undefined,
  companyDataStatus: 'NotLoaded',

  canExecute: true,
  productSource: undefined,

  permissions: { canAccessFromUrl: false },
  thirdPartyId: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state: UserState, { payload: user }: PayloadAction<User | undefined>) => {
      state.currentUser = user;
      selectUserInState(user, state);
    },

    selectUser: (state: UserState, { payload: user }: PayloadAction<User | undefined>) => {
      selectUserInState(user, state);
    },

    chooseBdrId: (state: UserState, action: PayloadAction<number | undefined>) => {
      state.chosenBdrId = action.payload;
    },

    setNavigableUsersList: (state: UserState, action: PayloadAction<AccountCenterNavigateAsItem[]>) => {
      state.navigableUsersList = action.payload;
    },

    companyDataLoadingRequested: (state: UserState) => {
      // use to trigger the call to the web API, only if there isn't a pending call
    },

    companyDataLoadingStarted: (state: UserState) => {
      if (state.companyDataStatus === 'NotLoaded' || state.companyDataStatus === 'Error') {
        state.companyDataStatus = 'Loading';
      }
    },

    companyDataLoaded: (state: UserState, action: PayloadAction<ClientDataOKResponse>) => {
      if (state.companyDataStatus === 'Loading') {
        state.companyDataStatus = 'Loaded';

        action.payload.forEach(({ bdrId, siret, country }) => {
          const updatableCompany = state.companies[bdrId];

          if (isDefined(updatableCompany)) {
            updatableCompany.siret = siret;
            updatableCompany.country = country;
          }
        });
      }
    },

    companyDataFailed: (state: UserState, _action: PayloadAction<ClientDataKOResponse>) => {
      if (state.companyDataStatus === 'Loading') {
        state.companyDataStatus = 'Error';
      }
    },

    setProductSource: (state: UserState, action: PayloadAction<ProductSource>) => {
      state.productSource = action.payload;
    },

    setUserPermissions: (state: UserState, action: PayloadAction<Record<AvailablePermission, boolean>>) => {
      state.permissions = action.payload;
    },

    setThirdPartyId: (state: UserState, action: PayloadAction<string>) => {
      state.thirdPartyId = action.payload;
    }
  },
});

const selectUserInState = (user: User | undefined, state: UserState) => {
  state.selectedUser = user;

  state.companies = Object.fromEntries(
    (user?.companies ?? []).map((company) => [company.companyBdrId, company] as const),
  );

  state.chosenBdrId = undefined;
  state.companyDataStatus = 'NotLoaded';

  state.canExecute = state.currentUser !== undefined && state.currentUser?.email === user?.email;
};

export const {
  setCurrentUser,
  chooseBdrId,
  setNavigableUsersList,
  selectUser,
  companyDataLoadingRequested,
  companyDataLoadingStarted,
  companyDataLoaded,
  companyDataFailed,
  setProductSource,
  setUserPermissions,
  setThirdPartyId
} = userSlice.actions;

export const selectBdriId = (_state: RootState, bdrId: number) => bdrId;

export function selectCurrentUser(state: RootState) {
  return state.user.currentUser;
}

export function selectSelectedUser(state: RootState) {
  return state.user.selectedUser;
}

export function selectChoosenBdrId(state: RootState) {
  return state.user.chosenBdrId;
}

export function selectCompany(state: RootState, bdrId: number) {
  return state.user.companies[bdrId];
}

export function selectCompanies(state: RootState) {
  return state.user.companies;
}

export const selectRfsCurrencies = createSelector([selectCompanies, selectBdriId], (companies, bdrId) => Object.values(companies)
  .filter((company) => company.companyBdrId === bdrId)
  .map((company) => company.rfsCurrencies).flat())

export const selectRights = createSelector([selectCurrentUser], currentUser => ({
  hasSpotRight: currentUser?.hasSpotRight ?? false,
  hasForwardRight: currentUser?.hasForwardRight ?? false
}))

export function selectCompanyDataStatus(state: RootState) {
  return state.user.companyDataStatus;
}

export function selectNavigableUsersList(state: RootState) {
  return state.user.navigableUsersList;
}

export function selectCanExecute(state: RootState) {
  return state.user.canExecute;
}

export function productComesFromTradeRetriever(state: RootState): boolean {
  return state.user.productSource === ProductSource.FromTradeRetriever;
}

export function selectSalesEmails(state: RootState) {
  return state.user.currentUser?.salesEmail;
}

export function selectCanAccessFromUrl(state: RootState) {
  return state.user.permissions.canAccessFromUrl;
}

export default userSlice.reducer;
