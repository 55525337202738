import { useIntl } from 'react-intl';

export interface HighlightedRateProps {
  value: number;
  spotPrecision: number;
  productType: 'Spot' | 'Forward';
}

function makeShouldBeHighlighted(spotPrecision: number): (index: number) => boolean {
  const ticksPrecision = spotPrecision - 1;
  return function shouldBeHighlighted(index) {
    return index >= ticksPrecision - 2 && index < ticksPrecision;
  };
}

export function HighlightedRate({ value, spotPrecision, productType }: HighlightedRateProps) {
  const { formatNumberToParts } = useIntl();
  const forwardPrecision = spotPrecision + 1;
  const fractionDigits = productType === 'Forward' ? forwardPrecision : spotPrecision;
  const formattedParts = formatNumberToParts(value, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  const shouldBeHighlighted = makeShouldBeHighlighted(spotPrecision);
  return (
    <>
      {formattedParts.flatMap(({ type, value }) => {
        if (type !== 'fraction') {
          return [<span className='fs-16 monospace-numbers' key={type}>{value}</span>];
        } else {
          return Array.from(value).map((digit, index) => (
            <span
              className={`${shouldBeHighlighted(index) ? ' text-info' : ''} fs-16 monospace-numbers`}
              key={index}
            >
              {digit}
            </span>
          ));
        }
      })}
    </>
  );
}
