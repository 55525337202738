import { BulkLeg, BulkProduct, InvalidProductStatus } from '@/models/product';
import { RootState } from '@/state/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '&types/hedgeconnect';
import { mapProductLegToBulkLeg } from '@/models/transform/tms/fromTms';
import {
  TradeCaptureBulkErrors,
  TradeCaptureBulkResponseChanges,
  TradeCaptureBulkWarnings,
} from '@/models/tradeCapture';
import { isDefined, isEmpty } from '@sgme/fp';

// TODO: castDraft will be integrated soon in @reduxjs/toolkit; so remove the immer dependency and use the castDraft from @reduxjs/toolkit
import { castDraft } from 'immer';
import { currencies } from '@/data/currencies';

// TODO: check if Trace Capture can return this ID for a "normal" error
export const UPDATED_MATURITY_DATE_WARNING_ID = -100;

// Define the initial state using that type
const initialState: BulkProduct = {
  status: 'NotInitialized',
  legsByCurrencyPair: {},
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    invalidStatus(state: BulkProduct, { payload }: PayloadAction<InvalidProductStatus>) {
      state.status = payload;
    },

    productReceived: (state: BulkProduct, action: PayloadAction<Product>) => {
      const receivedLegs = action.payload.bulkTrade.reduce((acc, leg) => {
        const shouldCcyPair = [leg.sellCurrency, leg.buyCurrency].join('/');
        const isCcyPairInverted = currencies[shouldCcyPair].isInverted;
        const currencyPair = !isCcyPairInverted
          ? shouldCcyPair
          : [leg.buyCurrency, leg.sellCurrency].join('/');

        if (isDefined(acc[currencyPair])) {
          acc[currencyPair] = [...acc[currencyPair], mapProductLegToBulkLeg(leg)];
          return acc;
        }
        acc[currencyPair] = [mapProductLegToBulkLeg(leg)];

        return acc;
      }, {} as Record<string, readonly BulkLeg[]>);

      if (isEmpty(Object.values(receivedLegs))) {
        state.status = 'NoProduct';
      } else {
        state.status = 'Received';
        state.legsByCurrencyPair = castDraft(receivedLegs);
      }
    },

    productValidationRequested: (state: BulkProduct) => {
      state.status = 'Validating';
    },

    productUpdated: (
      state: BulkProduct,
      {
        payload: { changedFields, currencyPair },
      }: PayloadAction<{ changedFields: TradeCaptureBulkResponseChanges; currencyPair: string }>,
    ) => {
      for (const [legId, receivedLeg] of Object.entries(changedFields.legs)) {
        const legIndex = Number(legId);
        const currentLeg = state.legsByCurrencyPair[currencyPair][legIndex];

        // TODO Is this going to be needed  ? should we remove it ?
        // if (receivedLeg.maturityDateTenor) {
        //   currentLeg.maturityDateTenor = receivedLeg.maturityDateTenor;
        // }

        const sentExpiryDate = currentLeg.expiryDate;
        const receivedExpiryDate = receivedLeg.maturityDate;

        if (sentExpiryDate !== receivedExpiryDate) {
          // currentLeg.expiryDate = receivedExpiryDate ?? '';

          const warnings = (currentLeg.warnings ??= {});

          warnings.maturityDate = [
            {
              id: UPDATED_MATURITY_DATE_WARNING_ID,
              description: 'updated maturity date',
              faultyValue: sentExpiryDate,
              previousValue: sentExpiryDate,
            },
          ];
        }
      }
    },

    productValidated: (state: BulkProduct) => {
      state.status = 'Validated';
      return state;
    },

    productCancelled: () => initialState,

    productSelectedForExecution: (
      state: BulkProduct,
      action: PayloadAction<{
        thirdPartyTradeReference: string;
        currencyPair: string;
      }>,
    ) => {
      const getLeg = Object.entries(state.legsByCurrencyPair[action.payload.currencyPair]).find(
        ([_index, leg]) => leg.thirdPartyTradeReference === action.payload.thirdPartyTradeReference,
      );
      if (isDefined(getLeg)) {
        const index = Number(getLeg[0]);
        state.legsByCurrencyPair[action.payload.currencyPair][index].isSelectedForExecution =
          !state.legsByCurrencyPair[action.payload.currencyPair][index].isSelectedForExecution;
      }

      return state;
    },

    unselectDeal: (
      state: BulkProduct,
      action: PayloadAction<{
        thirdPartyTradeReference: string;
        currencyPair: string;
      }>,
    ) => {
      const getLeg = Object.entries(state.legsByCurrencyPair[action.payload.currencyPair]).find(
        ([_, leg]) => leg.thirdPartyTradeReference === action.payload.thirdPartyTradeReference,
      );
      if (isDefined(getLeg)) {
        const index = Number(getLeg[0]);
        state.legsByCurrencyPair[action.payload.currencyPair][index].isSelectedForExecution = false;
      }

      return state;
    },

    currencyPairSelectedForExecution: (
      state: BulkProduct,
      action: PayloadAction<{ currencyPair: string; isSelected: boolean }>,
    ) => {
      state.legsByCurrencyPair[action.payload.currencyPair] = state.legsByCurrencyPair[
        action.payload.currencyPair
      ].map((leg) => ({ ...leg, isSelectedForExecution: action.payload.isSelected }));

      return state;
    },

    productRejected: (
      state: BulkProduct,
      action: PayloadAction<RejectProductPayload & { currencyPair: string }>,
    ) => {
      state.status = 'Rejected';

      Object.entries(action.payload.errors?.legs ?? {}).forEach(([index, errors]) => {
        const legIndex = Number(index);

        state.legsByCurrencyPair[action.payload.currencyPair][legIndex].errors = castDraft(errors);
      });

      return state;
    },
  },
});

export type RejectProductPayload =
  | { errors: TradeCaptureBulkErrors | null; warnings: TradeCaptureBulkWarnings | null }
  | { errors: null; warnings: TradeCaptureBulkWarnings }
  | { errors: TradeCaptureBulkErrors; warnings: null };

export const {
  invalidStatus,
  productReceived,
  productValidationRequested,
  productRejected,
  productUpdated,
  productValidated,
  productSelectedForExecution,
  unselectDeal,
  currencyPairSelectedForExecution,
  productCancelled,
} = productSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectProduct = (state: RootState) => state.product;

export function getLegsByCurrencyPair(state: RootState) {
  return state.product.legsByCurrencyPair;
}

export function getAllCurrencyPair(state: RootState) {
  return state.product.legsByCurrencyPair
}

export function getLegsForCurrencyPair(state: RootState, currencyPair: string) {
  return state.product.legsByCurrencyPair[currencyPair];
}

export default productSlice.reducer;
