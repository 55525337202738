import { FormattedMessage } from 'react-intl';
import { CompanyName } from '../content/CompanyName';
import { useAppDispatch, useAppSelector } from '@/features/hooks';
import { productComesFromTradeRetriever } from '@/features/user/userSlice';
import { cleanProducts } from '@/features/executedTrades/executedTradesAction';

type StreamingErrorHeaderProps = {
  type: 'Request' | 'Execution';
  operationsInError: number;
};

function StreamingErrorHeader({ operationsInError, type }: StreamingErrorHeaderProps) {
  const dispatch = useAppDispatch();

  const isFromUrl = useAppSelector(productComesFromTradeRetriever);

  function handleHeaderAction() {
    if (isFromUrl) {
      dispatch(cleanProducts());
    } else {
      window.close();
    }
  }

  return (
    <div className="mt-48px container mb-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <h2 className="text-danger">
            <FormattedMessage
              id={`StreamingError.${type}.header`}
              values={{
                icon: <i className="icon icon-lg pb-1">error_outline</i>,
                operationsCount: operationsInError,
              }}
            />
          </h2>
          <h5 className="mt-2">
            <CompanyName allIsDone={false} />
          </h5>
        </div>
        <div className="my-auto">
          <button type="button" className="btn btn-flat-primary" onClick={handleHeaderAction}>
            <FormattedMessage
              id={`${isFromUrl ? 'StreamingError.returnEntity' : 'StreamingError.close'}`}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default StreamingErrorHeader;
