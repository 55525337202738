import { NavigateAsUserQuery } from '@/components/sgwt/AccountCenter';
import { getConfig } from '@/config/config';
import { ClientDataRequest, ClientDataResponse } from '@/models/clientInformation';
import { MultipassQuoteExecutionPayload, MultipassQuoteRequestPayload } from '@/models/rfs';
import { TradeCaptureBulkRequest, TradeCaptureBulkResponse } from '@/models/tradeCapture';
import { HelpRequestPayload } from '@/models/transform/helpRequest/toTimeoutedDealMail';
import { UserCurrent } from '@/types/api/userInfo';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './baseQuery';
import { AccountCenterNavigateAsItem } from '@sgwt/sgwt-widgets-react';

export const webApi = createApi({
  reducerPath: 'webApi',

  baseQuery: fetchBaseQuery({
    baseUrl: getConfig().api_url,
    prepareHeaders: (headers, { getState }) => prepareHeaders(headers, getState),
  }),

  endpoints: (builder) => ({
    fetchUserCurrent: builder.query<UserCurrent, void>({
      query: () => 'api/hedgeconnect/user/current',
    }),

    fetchNavigableAsUser: builder.query<AccountCenterNavigateAsItem[] | undefined, void>({
      query: () => 'api/hedgeconnect/users',
    }),

    navigateAs: builder.query<UserCurrent, NavigateAsUserQuery>({
      query: (request) => ({
        url: 'api/hedgeconnect/user/navigateAs',
        body: request,
        method: 'POST',
      }),
    }),

    requestQuote: builder.mutation<ClientDataResponse, MultipassQuoteRequestPayload>({
      query: (request) => ({
        url: 'api/fxBulk/rfs/request',
        body: request,
        method: 'POST',
      }),
    }),

    cancelQuote: builder.mutation<void, string>({
      query: (rfsId) => ({
        url: 'api/fxBulk/rfs/cancel',
        body: { rfsId },
        method: 'POST',
      }),
    }),

    executeQuote: builder.mutation<void, MultipassQuoteExecutionPayload>({
      query: (request) => ({
        url: 'api/fxBulk/rfs/execute',
        body: request,
        method: 'POST',
      }),
    }),

    // ---------------------------------------

    requestTradeCapture: builder.query<
      TradeCaptureBulkResponse,
      { sessionId: string; payload: TradeCaptureBulkRequest }
    >({
      query: (request) => ({
        url: `api/tradeCapture/Bulk/${request.sessionId}`,
        body: request.payload,
        method: 'POST',
      }),
    }),

    // ---------------------------------------

    requestClientData: builder.query<ClientDataResponse, ClientDataRequest>({
      query: (request) => ({
        url: 'api/hedgeconnect/clientsInfos',
        body: request,
        method: 'POST',
      }),
    }),

    // ---------------------------------------

    helpRequest: builder.mutation<void, HelpRequestPayload>({
      query: (request) => ({
        url: 'api/help/request',
        body: request,
        method: 'POST',
      }),
    }),

  }),
});

export const { useFetchUserCurrentQuery, useNavigateAsQuery } = webApi;
