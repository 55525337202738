
import {
  dealRejected,
  errorOnRequest,
  selectCurrencyPairByRfsId,
  selectExecutionStatusForAllCurrencies,
} from '@/features/rfs/rfsSlice';
import { Step, selectStep, stepChanged } from '@/features/step/stepSlice';
import { AppListenerEffectAPI } from '@/state/store';
import { getRfsState } from '@/utils/rights';

export async function dealRejectedListener(
  {
    payload: {
      Execute: { RfsId },
    },
  }: ReturnType<typeof dealRejected>,
  { getState, dispatch }: AppListenerEffectAPI,
) {
  const state = getState();

  const currencyPair = selectCurrencyPairByRfsId(state, RfsId);

  const step = selectStep(state);
  const rfsState = getRfsState(step);

    if (rfsState === 'Request') {
      dispatch(errorOnRequest(currencyPair));
    }

  const executionStatus = selectExecutionStatusForAllCurrencies(state);
  const AreAllDealsRejectedInExecution =
    !executionStatus.includes('Pending') && !executionStatus.includes('Done');

  if (!AreAllDealsRejectedInExecution) {
    dispatch(stepChanged(Step.ExecutionSuccess));
  } else {
    dispatch(stepChanged(Step.ExecutionRejected));
  }
}
