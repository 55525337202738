import { useAppSelector } from '@/features/hooks';
import { selectProduct } from '@/features/product/productSlice';
import {
  CurrencyPairRfsState,
  selectAllRfsByCurrencyPair,
  selectExecutionStatusByCurrencies,
  selectStateCurrencyPairbyRfsId,
} from '@/features/rfs/rfsSlice';
import { BulkLeg, Way } from '@/models/product';
import { PricedBulkLeg, PricedBulkLegWithCurrencyPair } from '@/models/rfs';

import { ProductByCurrencyPair } from './ProductByCurrencyPair';
import { isNotDefined } from '@sgme/fp';
import { ProductLegItem } from '@/components/content/product/shared';

export function AllProductsByCurrencyPair() {
  const { legsByCurrencyPair } = useAppSelector(selectProduct);
  const allRfsByCurrencyPair = useAppSelector(selectAllRfsByCurrencyPair);
  const currencyPairbyRfsId = useAppSelector(selectStateCurrencyPairbyRfsId)

  // @TODO renommé pour rendre plus simple à comprendre
  const backOfficeIds = Object.values(allRfsByCurrencyPair).reduce((res, rfs) => {
    const currencyPair = currencyPairbyRfsId[rfs.rfsId!];
    res[currencyPair] = rfs.backOfficeIds;
    return res;
  }, {} as Record<string, string[] | undefined>);

  const executionStatusByCurrencyPair = useAppSelector(selectExecutionStatusByCurrencies);
  const isReferenceVisible = Object.values(executionStatusByCurrencyPair).includes('Done');

  const legsWithPrice = mapTolegWithPrice(legsByCurrencyPair, allRfsByCurrencyPair, backOfficeIds);

  return (
    <>
      {Object.entries(legsWithPrice).map(([currencyPair, legs]) => (
        <ProductByCurrencyPair
          key={currencyPair}
          ccyPair={currencyPair}
          legs={legs}
          executionStatus={executionStatusByCurrencyPair?.[currencyPair]}
          backOfficeIds={backOfficeIds[currencyPair] ?? []}
          isReferenceVisible={isReferenceVisible}
        />
      ))}
    </>
  );
}

export function mapTolegWithPrice(
  legsWithCurrencyPair: Record<string, readonly BulkLeg[]>,
  rfsByCurrencyPair: Record<string, CurrencyPairRfsState> | undefined,
  backOfficeIds?:  Record<string, string[] | undefined>,
): PricedBulkLegWithCurrencyPair {
  return Object.entries(legsWithCurrencyPair).reduce((acc, [currencyPair, legs]) => {
    const usedLegs =
      rfsByCurrencyPair?.[currencyPair]?.executionStatus === 'Done'
        ? legs.filter(
            (leg) =>
              leg.isSelectedForExecution && isNotDefined(leg.errors) && isNotDefined(leg.warnings),
          )
        : legs;

    const rfs = rfsByCurrencyPair?.[currencyPair];
    const quoteReply = rfs?.quoteReply;
    const legIds = rfs?.legIds;
    const tradeIds = backOfficeIds?.[currencyPair];

    acc[currencyPair] = usedLegs
      .reduce((res, leg) => {
        if (legIds?.includes(leg.thirdPartyTradeReference!)) {
          const index = legIds?.indexOf(leg.thirdPartyTradeReference!);

          const quoteLeg = quoteReply?.legs[index];
          const tradeId = tradeIds !== undefined ? { tradeId: tradeIds?.[index] } : {};

          const result = {
            ...leg,
            ...tradeId,
            spot: quoteLeg?.spotWithMargin,
            fwdPoints: quoteLeg
              ? makeForwardPointWithMargin(
                quoteLeg.customerWay,
                quoteLeg.forwardPoints,
                quoteLeg.forwardMarginPoints,
              )
              : undefined,
          };

          res.push(result);
        } else {
          res.push({
            ...leg,
            spot: undefined,
            fwdPoints: undefined,
          });
        }
        return res;
      }, [] as (PricedBulkLeg | ProductLegItem)[])
      .sort((a, b) => a.expiryDate.localeCompare(b.expiryDate));

    return acc;
  }, {} as PricedBulkLegWithCurrencyPair);
}

const makeForwardPointWithMargin = (
  customerWay: Way,
  forwardPts: number,
  forwardPtsMargin: number | undefined,
): number => {
  switch (customerWay) {
    case 'Sell':
      return forwardPts - (forwardPtsMargin ?? 0);
    case 'Buy':
      return forwardPts + (forwardPtsMargin ?? 0);
  }
};
