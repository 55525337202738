import { FormattedMessage } from 'react-intl';
import { isDefined, isNonEmpty, isNotDefined } from '@sgme/fp';
import { Way } from '@/models/product';
import { TcErrors, TcWarnings, TradeCaptureBulkLegPreviousValues } from '@/models/tradeCapture';
import { ErrorTooltip } from '../ErrorTooltip';

interface LegSummaryProps {
  displayWay: Way | undefined;
  amount: number;
  amountCurrency: string;
  index: number;
  errors?: TcErrors<TradeCaptureBulkLegPreviousValues>;
  warnings?: TcWarnings<TradeCaptureBulkLegPreviousValues>;
  sgReference?: string;
}

// -------------------------------------------------------

export function LegSummary({
  displayWay,
  amount,
  amountCurrency,
  index,
  errors,
  warnings,
  sgReference,
}: LegSummaryProps) {
  const amount1Error = errors?.amount1;
  const amount1Warning = warnings?.amount1;
  const isOnErrorOrOnWarning =
    (isDefined(amount1Warning) && isNonEmpty(amount1Warning)) ||
    (isDefined(amount1Error) && isNonEmpty(amount1Error));

  return (
    <div className="d-flex flex-column me-auto">
      <div
        className={`mt-2 fs-16 fw-medium d-flex flex-row align-items-center mb-2
        ${isOnErrorOrOnWarning ? 'text-danger' : ''}
        ${isNotDefined(sgReference) || sgReference === '' ? 'mt-24px' : 'mt-1'}`}
      >
        {isDefined(amount1Error) && (
          <ErrorTooltip id={`leg-summary-amout-error-${index}`} errorId={amount1Error[0].id ?? 0} />
        )}
        {isDefined(amount1Warning) && (
          <ErrorTooltip
            id={`leg-summary-amout-error-${index}`}
            errorId={amount1Warning[0].id ?? 0}
          />
        )}
        <h6 className="fs-16 fw-medium">
          <FormattedMessage
            id="ExecuteOperations.operation.header"
            values={{ displayWay, amount, amountCurrency }}
          />
        </h6>
      </div>

      {isDefined(sgReference) && sgReference !== '' && (
        <div className="text-secondary fs-16">
          <FormattedMessage id="ExecuteOperations.operation.subHeader" values={{ sgReference }} />
        </div>
      )}
    </div>
  );
}
