import { HighlightedRate } from '@/components/HighlightedRate';
import { sumSpotAndFwdPoints } from '@/models/transform/display';
import { isDefined } from '@sgme/fp';
import { FormattedMessage } from 'react-intl';
import { ForwardRateTooltip, ForwardRateTooltipLine } from './LegForwardTooltip';

interface LegRateProps {
  tradeType: 'Spot' | 'Forward';
  spot: number | undefined;
  spotPrecision: number;
  fwdPoints: number | undefined;
}

// -------------------------------------------------------

export function LegRate({ tradeType, spot, spotPrecision, fwdPoints }: LegRateProps) {
  return isDefined(spot) && isDefined(fwdPoints) ? (
    <div
      className={`d-flex justify-content-end align-items-center mt-4px `}
      style={{ width: '6rem' }}
    >
      {tradeType === 'Forward' && (
        <span>
          <ForwardRateTooltip>
            <div className="d-flex justify-content-between w-100">
              <span className="text-secondary fw-bold">
                <FormattedMessage id="ExecuteOperations.operation.tooltip.spotRate" />
              </span>

              <div>
                <HighlightedRate productType="Spot" spotPrecision={spotPrecision} value={spot} />
              </div>
            </div>

            <ForwardRateTooltipLine
              labelId="ExecuteOperations.operation.tooltip.fwdPts"
              value={fwdPoints}
              precision={2}
              alwaysShowSign
            />
          </ForwardRateTooltip>

          <i className="icon icon-sm text-secondary me-2">info_outline</i>
        </span>
      )}

      <span data-e2e="legRate">
        <HighlightedRate
          productType={tradeType}
          spotPrecision={spotPrecision}
          value={sumSpotAndFwdPoints(spot, fwdPoints, spotPrecision)}
        />
      </span>
    </div>
  ) : (
    <div className="text-end ms-auto my-auto mt-4px" style={{ width: '6rem' }}>
      -
    </div>
  );
}
