import { isDefined, isNonEmpty } from '@sgme/fp';
import { FormattedMessage } from 'react-intl';
import { ErrorTooltip } from '../ErrorTooltip';
import { TcErrors, TcWarnings, TradeCaptureBulkLegPreviousValues } from '@/models/tradeCapture';

interface LegDateProps {
  expiryDate: string | undefined;
  errors?: TcErrors<TradeCaptureBulkLegPreviousValues>;
  warnings?: TcWarnings<TradeCaptureBulkLegPreviousValues>;
  index: number;
}

// -------------------------------------------------------

export function LegDate({ expiryDate, errors, warnings, index }: LegDateProps) {
  const maturityError = errors?.maturityDate;
  const maturityWarning = warnings?.maturityDate;

  const isOnError = isDefined(maturityError) && isNonEmpty(maturityError);
  const isOnWarning = isDefined(maturityWarning) && isNonEmpty(maturityWarning);
  const isOnErrorOrOnWarning = isOnWarning || isOnError;

  return (
    <div className={`${isOnErrorOrOnWarning ? 'text-danger' : ''} mt-4px fs-16`}>
      {isOnError && (
        <ErrorTooltip
          id={`leg-summary-maturity-error-${index}`}
          errorId={maturityError[0].id ?? 0}
        />
      )}
      {isOnWarning && (
        <ErrorTooltip
          id={`leg-summary-maturity-warning-${index}`}
          errorId={maturityWarning[0].id ?? 0}
          isWarning
        />
      )}
      <FormattedMessage
        id="ExecuteOperations.operation.date"
        values={{ expiryDate: new Date(`${expiryDate}T00:00`) }}
      />
    </div>
  );
}
