
interface ToggleButtonProps {
  isOpen: boolean
  onClick: React.MouseEventHandler
  className?: string
}

export function ToggleButton({ isOpen, onClick, className = '' }: ToggleButtonProps) {
  return (
    <button
      type="button"
      className={`btn btn-link p-0 text-secondary text-nowrap mb-0 ${className}`}
      onClick={onClick}
    >
      <i className="icon">{!isOpen ? 'expand_less' : 'expand_more'}</i>
    </button>
  )
}
