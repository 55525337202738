import { RootState } from '@/state/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = '';

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    errorChanged: (_state, action: PayloadAction<string>) => action.payload,
  },
});

export const { errorChanged } = errorSlice.actions;

export function selectError(state: RootState) {
  return state.error;
}

export default errorSlice.reducer;
