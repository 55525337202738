import { productComesFromTradeRetriever } from '@/features/user/userSlice';
import { ExtraArgument, RootState } from '@/state/store';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

export function getProductFromFxKitThunk(): ThunkAction<
  void,
  RootState,
  ExtraArgument,
  AnyAction
> {
  return (dispatch, getState, { fxKitEndpoints: { getProducts } }) => {
    const state = getState();

    const isFromUrl = productComesFromTradeRetriever(state);

    if (isFromUrl) {
      dispatch(getProducts.initiate());
    }

    return;
  };
}
