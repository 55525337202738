import { Step, stepChanged } from '@/features/step/stepSlice';
import { selectUser, setCurrentUser, setNavigableUsersList, setProductSource } from '@/features/user/userSlice';
import { User } from '@/models/user';
import { AppListenerEffectAPI } from '@/state/store';
import { logger } from '@/utils/logger';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProductFromFxKitThunk } from './getProductFromFxKitThunk';
import { isDefined } from '@sgme/fp';

// ----------------------------------------------------------------

export async function bootstrap(
  _action: ReturnType<typeof setProductSource>,
  {
    dispatch,
    extra: {
      webApiEndpoints: { fetchUserCurrent, fetchNavigableAsUser },
    },
  }: AppListenerEffectAPI,
) {
  const { data: userCurrent, isError, error } = await dispatch(fetchUserCurrent.initiate());
  if (isError) {
    logger.logError('user/current request failed with {error}', error);
    window.xprops?.onDealNotDone(new Error('HedgeConnect was unable to retrieve user profile'));
  }

  if (isDefined(userCurrent)) {
    dispatch(getProductFromFxKitThunk())
    dispatch(userAuthorizedThunk({ userCurrent, fetchNavigableAsUser }));
  }

}


const userAuthorizedThunk = createAsyncThunk<
  void,
  {
    userCurrent: User;
    fetchNavigableAsUser: AppListenerEffectAPI['extra']['webApiEndpoints']['fetchNavigableAsUser'];
  }
>('thunk/userAuthorized', async ({ userCurrent, fetchNavigableAsUser }, { dispatch }) => {
  dispatch(setCurrentUser(userCurrent));
  dispatch(stepChanged(Step.SelectCompany));

  if (isNavigateAsEnabled() && userCurrent.canNavigateAs) {
    const navigableAsUser = await dispatch(fetchNavigableAsUser.initiate());

    if (navigableAsUser.isSuccess) {
      dispatch(setNavigableUsersList(navigableAsUser.data ?? []));
    }
  } else {
    dispatch(selectUser(userCurrent));
  }
});

// ----------------------------------------------------------------

const ENVIRONMENTS_WITHOUT_NAVIGATE_AS = ['demo'];
const isNavigateAsEnabled = () => {
  const env = window.sgmeConfiguration.env.toLowerCase();

  return !ENVIRONMENTS_WITHOUT_NAVIGATE_AS.includes(env);
};

