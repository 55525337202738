import { useAppDispatch, useAppSelector } from '@/features/hooks';
import {
  getLegsForCurrencyPair,
  productSelectedForExecution,
} from '@/features/product/productSlice';
import { requestStream } from '@/features/rfs/rfsAction';
import { cancelStream, selectRfsId } from '@/features/rfs/rfsSlice';
import { TcErrors, TcWarnings, TradeCaptureBulkLegPreviousValues } from '@/models/tradeCapture';
import { isDefined } from '@sgme/fp';
import { FC } from 'react';

export interface CheckboxProps {
  isSelected: boolean;
  tradeIndex: number;
  currencyPair: string;
  thirdPartyTradeReference: string;
  warnings?: TcWarnings<TradeCaptureBulkLegPreviousValues>;
  errors?: TcErrors<TradeCaptureBulkLegPreviousValues>;
  shouldDisplayError: boolean;
  isRejectedOnRequest: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  isSelected,
  tradeIndex,
  currencyPair,
  errors,
  warnings,
  thirdPartyTradeReference,
  isRejectedOnRequest,
  shouldDisplayError,
}) => {
  const dispatch = useAppDispatch();

  const rfsId = useAppSelector((state) => selectRfsId(state, currencyPair));
  const legsForCurrencyPair = useAppSelector((state) =>
    getLegsForCurrencyPair(state, currencyPair),
  );
  const neitherLegIsSelected = legsForCurrencyPair.every((leg) => !leg.isSelectedForExecution);
  const selectedLegs = legsForCurrencyPair.filter((leg) => leg.isSelectedForExecution)

  const hasErrors = shouldDisplayError;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      productSelectedForExecution({
        thirdPartyTradeReference,
        currencyPair,
      }),
    );
    if (isDefined(rfsId) && !neitherLegIsSelected) {
      dispatch(cancelStream({ currencyPair, rfsId }));
    }
    if (selectedLegs.length !== 1 || !isSelected) {
      dispatch(requestStream(currencyPair));
    }
  };

  return (
    <div className="form-check">
      <input
        type="checkbox"
        data-e2e={`${currencyPair}-deal-${tradeIndex}`}
        className="form-check-input"
        onChange={handleChange}
        disabled={hasErrors || isRejectedOnRequest}
        checked={hasErrors ? !hasErrors : isSelected}
      />
    </div>
  );
};
