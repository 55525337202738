import styled from '@emotion/styled';
import { isDefined } from '@sgme/fp';
import { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

type Placement = 'top' | 'bottom' | 'left' | 'right';

// reused of the error localization from fx-web\app\src\components\Tooltip\DisplayTooltip.tsx
// -------------------------------------------------------

interface ErrorTooltipCommonProps {
  id: string;
  placement?: Placement;
  isWarning?: boolean;
}

interface ErrorTooltipWithMessageProps extends ErrorTooltipCommonProps {
  message: string;
  errorId?: undefined;
}

interface ErrorTooltipWithErrorIdProps extends ErrorTooltipCommonProps {
  message?: undefined;
  errorId: number;
}

export type ErrorTooltipProps = ErrorTooltipWithMessageProps | ErrorTooltipWithErrorIdProps;

export function ErrorTooltip({
  id,
  message,
  errorId,
  placement = 'top',
  isWarning = false,
}: ErrorTooltipProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <ErrorTooltipWrapperStyles className={isWarning ? 'warning' : undefined}>
      <i className="icon icon-sm" id={id}>
        info_outline
      </i>
      <StyledTooltip
        target={id}
        placement={placement}
        isOpen={tooltipOpen}
        autohide={false}
        toggle={toggle}
        className={isWarning ? 'warning' : undefined}
      >
        {isDefined(errorId) ? <FormattedMessage id={`error-tc-${errorId}`} /> : message}
      </StyledTooltip>
    </ErrorTooltipWrapperStyles>
  );
}

const ErrorTooltipWrapperStyles = styled.span`
  margin: 0 4px;
  color: var(--bs-red);

  &.warning {
    color: var(--bs-red);
  }
`;

const StyledTooltip = styled(Tooltip)`
  & > .tooltip > .tooltip-inner {
    background-color: var(--bs-red);
    border-color: var(--bs-red);
  }

  & > .tooltip[x-placement='top'] > .arrow::before {
    border-top-color: var(--bs-red);
  }

  & > .tooltip[x-placement='bottom'] > .arrow::before {
    border-bottom-color: var(--bs-red);
  }

  & > .tooltip[x-placement='left'] > .arrow::before {
    border-left-color: var(--bs-red);
  }

  & > .tooltip[x-placement='right'] > .arrow::before {
    border-right-color: var(--bs-red);
  }

  &.warning {
    & > .tooltip > .tooltip-inner {
      background-color: var(--bs-red);
      border-color: var(--bs-red);
    }

    & > .tooltip[x-placement='top'] > .arrow::before {
      border-top-color: var(--bs-red);
    }

    & > .tooltip[x-placement='bottom'] > .arrow::before {
      border-bottom-color: var(--bs-red);
    }

    & > .tooltip[x-placement='left'] > .arrow::before {
      border-left-color: var(--bs-red);
    }

    & > .tooltip[x-placement='right'] > .arrow::before {
      border-right-color: var(--bs-red);
    }
  }
`;
