import { BulkLeg } from '@/models/product';
import { BulkLineProduct, BulkTradeCaptureRequestLegProperties } from '@/models/tradeCapture';

export function mapProductLegToTradeCapture({
  type,
  way,
  expiryDate: maturityDateString,
  currencyPair,
  amountCurrency,
  amount,
}: BulkLeg): Partial<BulkTradeCaptureRequestLegProperties> {
  const amountField = currencyPair.startsWith(amountCurrency) ? 'amount1String' : 'amount2String';
  return {
    productName: mapProductLegTypeToProductName(type),
    way,
    maturityDateString,
    [amountField]: String(amount),
  };
}

export function mapProductLegTypeToProductName(type: 'Spot' | 'Forward') {
  return type === 'Spot' ? 'FxSpot' : 'FxFwd';
}
