import { RootState } from "@/state/store";
import { getFakeIam, getFakeUser } from "@/widgets/fakeUser";
import { getAuthorizationHeader } from "@/widgets/widgets";
import { isDefined } from "@sgme/fp";

export function prepareHeaders(headers: Headers, getState?: () => any): Headers {
  const authorizationHeader = getAuthorizationHeader();

  // To determine brokerBdriId on backend side
  if (isDefined(getState)) {
    const thirdPartyId = (getState() as RootState).user.thirdPartyId;

    if (isDefined(thirdPartyId)) {
      headers.set('Third-Party-Id', thirdPartyId);
    }
  }

  if (authorizationHeader) {
    headers.set('Authorization', authorizationHeader);
  }
  const fakeUser = getFakeUser();
  if (isDefined(fakeUser)) {
    headers.set('X-Fake-Authentication', fakeUser);
  }

  const fakeIam = getFakeIam();
  if (isDefined(fakeIam)) {
    headers.set('X-Fake-Iam-Permissions', fakeIam);
  }

  headers.set('Application', 'Hedgeconnect');
  return headers;
};