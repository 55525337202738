import { cleanProducts } from '@/features/executedTrades/executedTradesAction';
import { addExecutedTrades } from '@/features/executedTrades/executedTradesSlice';
import { getLegsByCurrencyPair, productCancelled } from '@/features/product/productSlice';
import {
  emptyRfsStore,
  selectExecutionStatusByCurrencies,
} from '@/features/rfs/rfsSlice';
import { Step, stepChanged } from '@/features/step/stepSlice';
import { selectChoosenBdrId } from '@/features/user/userSlice';
import { AppListenerEffectAPI } from '@/state/store';
import { isNotDefined } from '@sgme/fp';

export async function getExecutedTrades(
  { payload }: ReturnType<typeof cleanProducts>,
  { getState, dispatch }: AppListenerEffectAPI,
) {
  const state = getState();

  // const rfsByCurrencyPair = selectAllRfsByCurrencyPair(state);
  const executionStatusByCurrencies = selectExecutionStatusByCurrencies(state);
  const chosenBdrId = selectChoosenBdrId(state);

  const currenciesInError = Object.entries(executionStatusByCurrencies).reduce(
    (acc, [currencyPair, executionStatus]) => {
      if (executionStatus === 'Done') {
        acc.push(currencyPair);
      }
      return acc;
    },
    [] as string[],
  );

  const legs = getLegsByCurrencyPair(state);

  const legsWithoutMpErros = Object.fromEntries(
    Object.entries(legs).filter(([currencyPair, leg]) => currenciesInError.includes(currencyPair)),
  );

  const executedLegs = Object.values(legsWithoutMpErros)
    .flat()
    .filter(
      (leg) => leg.isSelectedForExecution && isNotDefined(leg.errors) && isNotDefined(leg.warnings),
    );

  const executedLegIds = executedLegs.map((leg) => leg.thirdPartyTradeReference!);

  dispatch(addExecutedTrades({ [chosenBdrId!]: executedLegIds }));
  dispatch(stepChanged(Step.SelectCompany));
  dispatch(productCancelled());
  dispatch(emptyRfsStore());
}
