import { assertIsDefined } from '@sgme/fp';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../state/store';
import { selectChoosenBdrId, selectCompany } from './user/userSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useCompanyName() {
  const choosenBdrId = useAppSelector(selectChoosenBdrId);
  assertIsDefined(choosenBdrId, 'Cannot call useCompanyName before bdrId is choosen');
  const companyName = useAppSelector((state) => selectCompany(state, choosenBdrId).companyName);

  return companyName;
}

