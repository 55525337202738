import { AllProductsByCurrencyPair } from '@/components/content/product/AllProductsByCurrencyPair';
import { cleanProducts } from '@/features/executedTrades/executedTradesAction';
import { useAppDispatch, useAppSelector, useCompanyName } from '@/features/hooks';
import { getAllCurrencyPair, getLegsByCurrencyPair } from '@/features/product/productSlice';
import { execute, selectAllRfsByCurrencyPair, selectAllRfsIds } from '@/features/rfs/rfsSlice';
import {
  productComesFromTradeRetriever,
  selectCanExecute,
  selectChoosenBdrId,
  selectCurrentUser,
  selectRfsCurrencies,
  selectRights,
} from '@/features/user/userSlice';
import { BulkLeg } from '@/models/product';
import styled from '@emotion/styled';
import { assertIsDefined, isDefined, isNotDefined } from '@sgme/fp';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { isCurrencyPairAllowed } from './content/product/shared';
import ExecutionModal from './ExecutionModal';

export function ExecuteOperations(): React.ReactElement | null {
  const bdrId = useAppSelector(selectChoosenBdrId);
  assertIsDefined(bdrId, 'Cannot get bdrId');
  const rfsCurrenciesAllowed = useAppSelector((state) => selectRfsCurrencies(state, bdrId));

  const allCurrencyPairs = useAppSelector(getAllCurrencyPair);
  const currencyPairs = Object.keys(allCurrencyPairs);

  const { hasForwardRight, hasSpotRight } = useAppSelector(selectRights);

  const legsByCurrencyPair = useAppSelector(getLegsByCurrencyPair);
  const isFromUrl = useAppSelector(productComesFromTradeRetriever);

  const currentUser = useAppSelector(selectCurrentUser);
  const readonly = currentUser?.readonly;
  const canExecute = useAppSelector(selectCanExecute);
  const allRfs = useAppSelector((state) => selectAllRfsByCurrencyPair(state));

  const isTmsReady = Object.values(allRfs).every((rfs) => rfs.isTmsReady);

  const isDisabled = !canExecute || !isTmsReady || Boolean(readonly);

  const dispatch = useAppDispatch();

  const currencyPairsWithNoErrors = Object.entries(legsByCurrencyPair).reduce(
    (acc, [currency, unfilteredLegs]) => {
      if (unfilteredLegs.every((leg) => isNotDefined(leg.errors))) {
        acc.push(currency);
      }

      return acc;
    },
    [] as string[],
  );

  const onClickExecute = () => {
    currencyPairsWithNoErrors.forEach((currencyPair) => {
      if (isDefined(allRfs[currencyPair]?.quoteReply)) {
        dispatch(execute(currencyPair));
      }
    });
  };

  const company = useCompanyName();

  const flattedLegs = Object.values(legsByCurrencyPair).flat();

  const hasErrors = flattedLegs.every((leg) => isDefined(leg.errors));
  const hasWarnings = flattedLegs.every((leg) => isDefined(leg.warnings));

  const hasNoSpotRights = !hasSpotRight && flattedLegs.every((leg) => leg.type === 'Spot');
  const hasNoForwardRights = !hasForwardRight && flattedLegs.every((leg) => leg.type === 'Forward');

  const noRights = !hasSpotRight && !hasForwardRight;

  const noCurrencyAllowed = currencyPairs.every(
    (currencyPair) => !isCurrencyPairAllowed(rfsCurrenciesAllowed, currencyPair),
  );

  const isHeaderNotOnError =
    !hasErrors &&
    !hasWarnings &&
    !hasNoSpotRights &&
    !hasNoForwardRights &&
    !noRights &&
    !noCurrencyAllowed;

  const rfsIds = useAppSelector(selectAllRfsIds);

  function handleHeaderAction() {
    if (isFromUrl) {
      dispatch(cleanProducts());
    } else {
      rfsIds.forEach((rfsId) => {
        window?.xprops?.onDealNotDone(rfsId);
      });
      window.close();
    }
  }

  const handleCloseWhilePricing = () => {
    rfsIds.forEach((rfsId) => {
      window?.xprops?.onDealNotDone(rfsId);
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleCloseWhilePricing);
    return () => {
      window.removeEventListener('beforeunload', handleCloseWhilePricing);
    };
  }, []);

  if (isNotDefined(legsByCurrencyPair)) {
    return null;
  }
  return (
    <main className="p-0 d-flex flex-column">
      <div className="container mb-3">
        <div className="mt-48px mb-24px">
          <div className="d-flex justify-content-between">
            {isHeaderNotOnError ? (
              <ExecutionHeader
                isDisabled={isDisabled}
                legsByCurrencyPair={legsByCurrencyPair}
                company={company}
                onClickExecute={onClickExecute}
              />
            ) : (
              <ErrorHeader
                company={company}
                handleHeaderAction={handleHeaderAction}
                isFromUrl={isFromUrl}
              />
            )}
          </div>
        </div>
      </div>

      <div className="bg-lvl2 flex-grow-1 border-top pb-48px">
        <div className="container mb-4">
          <AllProductsByCurrencyPair />
        </div>
      </div>
    </main>
  );
}

// ███╗   ██╗ ██████╗ ██████╗ ███╗   ███╗ █████╗ ██╗         ██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗
// ████╗  ██║██╔═══██╗██╔══██╗████╗ ████║██╔══██╗██║         ██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
// ██╔██╗ ██║██║   ██║██████╔╝██╔████╔██║███████║██║         ███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
// ██║╚██╗██║██║   ██║██╔══██╗██║╚██╔╝██║██╔══██║██║         ██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
// ██║ ╚████║╚██████╔╝██║  ██║██║ ╚═╝ ██║██║  ██║███████╗    ██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
// ╚═╝  ╚═══╝ ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝╚═╝  ╚═╝╚══════╝    ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝
//
interface ExecutionHeaderProps {
  isDisabled: boolean;
  legsByCurrencyPair: Record<string, readonly BulkLeg[]>;
  company: string;
  onClickExecute: () => void;
}

function ExecutionHeader({
  isDisabled,
  legsByCurrencyPair,
  company,
  onClickExecute,
}: ExecutionHeaderProps) {
  const dispatch = useDispatch();
  const [isExecutionModalOpen, setIsExecutionModalOpen] = useState(false);

  const switchExecutionModalState = () =>
    setIsExecutionModalOpen((previousValue) => (!isDisabled ? !previousValue : false));

  const isFromUrl = useAppSelector(productComesFromTradeRetriever);
  const legsWithCurrencyPairWithoutErrors = Object.values(legsByCurrencyPair).filter(
    (unfilteredLegs) => unfilteredLegs.every((leg) => isNotDefined(leg.errors)),
  );

  const legs = legsWithCurrencyPairWithoutErrors.flat();

  const numberOfFilteredLegs = legs.reduce(
    (count, value) =>
      value.isSelectedForExecution && isNotDefined(value.errors) && isNotDefined(value.warnings)
        ? count + 1
        : count,
    0,
  );
  const goBackToEntities = () => {
    dispatch(cleanProducts());
  };

  return (
    <>
      <div className="d-flex flex-column">
        <h2 className="display-3">
          <FormattedMessage
            id="ExecuteOperations.header"
            values={{ operationsCount: legs.length }}
          />
        </h2>

        <div className="mt-2">
          <FormattedMessage
            tagName="h5"
            id="Operations.subHeader"
            values={{ company, css: (chunks) => <span className="text-info">{chunks}</span> }}
          />
        </div>
      </div>

      <div className="my-auto">
        {isFromUrl && (
          <button
            type="button"
            className="btn btn-lg btn-flat-secondary text-secondary me-2"
            onClick={goBackToEntities}
          >
            <FormattedMessage id={`ExecuteOperations.cancel`} />
          </button>
        )}
        <button
          type="button"
          className="btn btn-primary btn-lg"
          data-e2e="execute"
          onClick={switchExecutionModalState}
          disabled={isDisabled}
        >
          <FormattedMessage
            id="ExecuteOperations.next"
            values={{ operationsCount: numberOfFilteredLegs }}
          />
          <ExecutionModal
            isDisabled={isDisabled}
            onClickExecute={onClickExecute}
            isExecutionModalOpen={isExecutionModalOpen}
            numberOfFilteredLegs={numberOfFilteredLegs}
            company={company}
          />
        </button>
      </div>
      <></>
    </>
  );
}

// ███████╗██████╗ ██████╗  ██████╗ ██████╗     ██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗
// ██╔════╝██╔══██╗██╔══██╗██╔═══██╗██╔══██╗    ██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
// █████╗  ██████╔╝██████╔╝██║   ██║██████╔╝    ███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
// ██╔══╝  ██╔══██╗██╔══██╗██║   ██║██╔══██╗    ██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
// ███████╗██║  ██║██║  ██║╚██████╔╝██║  ██║    ██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
// ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝    ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝
//

interface ErrorHeaderProps {
  company: string;
  handleHeaderAction: () => void;
  isFromUrl: boolean;
}

function ErrorHeader({ company, handleHeaderAction, isFromUrl }: ErrorHeaderProps) {
  return (
    <>
      <StyledErrorHeader className="d-flex flex-column">
        <h2 className="d-flex flex-row align-items-center">
          <i className="icon icon-lg me-2">info_outline</i>
          <FormattedMessage id="ExecuteOperations.error.header" />
        </h2>

        <div className="mt-2">
          <FormattedMessage id="ExecuteOperations.error.subHeader" values={{ company }} />
        </div>
      </StyledErrorHeader>

      <div className="my-auto">
        <button
          type="button"
          className="btn btn-flat-primary"
          data-e2e="close"
          onClick={handleHeaderAction}
        >
          <FormattedMessage id={`ExecuteOperations.${isFromUrl ? 'returnEntity' : 'close'}`} />
        </button>
      </div>
    </>
  );
}

const StyledErrorHeader = styled.div`
  & > h2 {
    color: var(--bs-red);
  }
`;
