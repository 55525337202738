import { useNavigateAsQuery } from '@/features/api/web.api';
import { useAppDispatch, useAppSelector } from '@/features/hooks';
import { selectUser } from '@/features/user/userSlice';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { cancelStream, selectAllRfsIds } from '@/features/rfs/rfsSlice';
import { selectStep, Step, stepChanged } from '@/features/step/stepSlice';
import {
  AccountCenterNavigateAsItem,
  SgwtAccountCenter,
  useSgwtWidgets,
} from '@sgwt/sgwt-widgets-react';
import { type SgwtWidgetUser } from '@sgwt/sgwt-widgets-react/dist/utils';
import { getConfig } from '@/config/config';

interface AccountCenterAttributesProps {
  navigateAsList: AccountCenterNavigateAsItem[];
  navigateAsUser?: any;
  canNavigateAs?: boolean;
}

export interface NavigateAsUserQuery {
  navigateAsEmail?: string;
}

export function AccountCenter({
  navigateAsList,
  canNavigateAs,
}: AccountCenterAttributesProps): JSX.Element {
  const { locale } = useIntl();
  const dispatch = useAppDispatch();
  const { sgwtAccountCenter } = useSgwtWidgets();

  const step = useAppSelector(selectStep);
  const rfsIds = useAppSelector(selectAllRfsIds);

  const [navigateAsUserSelected, setNavigateAsUserSelected] = useState<
    NavigateAsUserQuery | undefined
  >();
  const { data: currentUser } = useNavigateAsQuery(navigateAsUserSelected!, {
    skip: !navigateAsUserSelected,
  });

  useEffect(() => {
    if (currentUser !== undefined) {
      dispatch(selectUser(currentUser));
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser !== undefined && step !== Step.SelectCompany) {
      dispatch(stepChanged(Step.SelectCompany));

      rfsIds.forEach((rfsId) => {
        dispatch(cancelStream({ rfsId }));
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (sgwtAccountCenter && !!sgwtAccountCenter.showNavigateAs) {
      if (step === Step.SelectCompany) {
        sgwtAccountCenter?.showNavigateAs();
      }
    }
  }, [sgwtAccountCenter]);

  const onNavigateAsSelectUser = useCallback(
    (user: SgwtWidgetUser) => {
      setNavigateAsUserSelected({ navigateAsEmail: `${user.email}` });
    },
    [setNavigateAsUserSelected],
  );
  const availableLanguages = ['fr', 'en'];

  const { sgwt_env } = getConfig();

  return (
    <SgwtAccountCenter
      authentication="sg-connect"
      mode="sg-markets"
      producerCode="sgm_hedgeconnect"
      language={locale}
      environment={sgwt_env === 'homologation' ? sgwt_env: ''}
      availableLanguages={availableLanguages}
      navigateAs={canNavigateAs}
      navigateAsList={navigateAsList}
      hideServicesLink
      debug={sgwt_env === 'homologation'} // To show debug on homolo env
      onNavigateAsSelectUser={onNavigateAsSelectUser}
    />
  );
}
