import { makeEnhancedMatcher } from '@/utils/rtk';
import { streamingStateChanged } from '@/features/streaming/streamingSlice';
import {
  clientLatencyAborted,
  dealDone,
  dealRejected,
  heartbeat,
  quoteAbort,
  quoteReceived,
} from '@/features/rfs/rfsSlice';
import { isAnyOf } from '@reduxjs/toolkit';

export const streamingConnectedMatcher = makeEnhancedMatcher(
  streamingStateChanged.match,
  (action) => action.payload.state === 'connected',
);

export const currentRfsQuoteReceived = (rfsId: string) =>
  makeEnhancedMatcher(quoteReceived.match, (action) => action.payload.quoteReply.rfsId === rfsId);

export const currentRfsAbort = (rfsId: string) =>
  makeEnhancedMatcher(quoteAbort.match, (action) => action.payload.QuoteReject.RfsId === rfsId);

export const currentRfsClientLatencyAbort = (rfsId: string) =>
  makeEnhancedMatcher(clientLatencyAborted.match, (action) => action.payload === rfsId);

export const currentRfsEnd = (rfsId: string) =>
  makeEnhancedMatcher(
    isAnyOf(dealRejected.match, dealDone.match),
    (action) => action.payload.Execute.RfsId === rfsId,
  );

export const currentRfsHeartbeat = (rfsId: string) =>
  makeEnhancedMatcher(heartbeat.match, (action) => action.payload.Heartbeat.RfsId === rfsId);
