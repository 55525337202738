import { useAppSelector } from '@/features/hooks';
import { Step, selectStep } from '@/features/step/stepSlice';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { useEffect } from 'react';

export function useStepTracker(): void {
  const step = useAppSelector(selectStep);
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const untrackedSteps = [Step.LoadingPage];
  const trackingSteps = Object.values(Step).filter((step) => !untrackedSteps.includes(step));

  // Every time the location changes, we track the page view on Matomo.
  useEffect(() => {
    if (trackingSteps.includes(step as Step)) {
      sgwtWebAnalytics?.trackEvent('Step', step);
    }
  }, [step, trackingSteps]);
}
