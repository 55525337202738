import { HedgeConnectXProps } from '&types/hedgeconnect';
import { getConfig } from '@/config/config';
import { getFakeUser } from '@/widgets/fakeUser';
import { create } from '@krakenjs/zoid';
import { setupSGWTConnect } from '@sgwt/connect-core';
import type { ISGWTConnectIdTokenClaims } from '@sgwt/connect-core/dist/src/SGWTConnectIdTokenClaims';
import type { ISGWTConnectOpenIDStandardClaims } from '@sgwt/connect-core/dist/src/SGWTConnectOpenIDUserInfo';

/**
 * Initialize Zoid component to be able to communicate with the parent window
 */
create<HedgeConnectXProps>({
  tag: 'hedgeconnect-smartbutton',

  url: window.location.href,

  dimensions: {
    width: window.innerWidth,
    height: window.innerHeight,
  },

  defaultContext: 'popup',

  props: {
    getProduct: {
      type: 'function',
      required: true,
    },
    onProductPriced: {
      type: 'function',
      required: true,
    },
    onDealDone: {
      type: 'function',
      required: true,
    },
    onDealNotDone: {
      type: 'function',
      required: true,
    },
    onClose: {
      type: 'function',
      required: true,
    },
    environment: {
      type: 'string',
      required: false,
    },
    user: {
      type: 'string',
      required: false,
    },
    locale: {
      type: 'string',
      required: false,
    },
    referrerUrl: {
      type: 'string',
      required: false,
    },
    thirdPartyId: {
      type: 'string',
      required: true
    }
  },
});

const config = getConfig().sgconnect;

export const originalSgwtConnect = setupSGWTConnect(config);

export const getFakeOverridenSGConnect = () => {
  const fakeUserEmail = getFakeUser() ?? originalSgwtConnect.getIdTokenClaims()?.sub!;

  originalSgwtConnect.getIdTokenClaims = () =>
    ({ sub: fakeUserEmail } as ISGWTConnectIdTokenClaims);

  const originalFetchUserInfo = originalSgwtConnect.fetchUserInfo.bind(originalSgwtConnect);

  originalSgwtConnect.fetchUserInfo = callback => {
    originalFetchUserInfo((error, claims) => {
      const fakeUser = {
        ...claims,
        sub: fakeUserEmail,
        mail: fakeUserEmail,
        name: getNameFromEmail(fakeUserEmail),
      } as ISGWTConnectOpenIDStandardClaims;
      callback(error, fakeUser);
    });
    return originalSgwtConnect;
  };

  return originalSgwtConnect;
};

export const sgwtConnect = window.sgmeConfiguration.useFakeSgConnect
  ? getFakeOverridenSGConnect()
  : originalSgwtConnect;

const getNameFromEmail = (email: string) =>
  email.split('@')[0].replace('-ext', '').replace('.', ' ');
