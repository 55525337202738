import { makeValuesPredicate } from '@sgme/fp';
import { TcErrors, TcWarnings, TradeCaptureBulkLegPreviousValues } from './tradeCapture';

export interface BulkProduct {
  status: BulkProductStatus;
  legsByCurrencyPair: Record<string, readonly BulkLeg[]>;
}

export const INVALID_PRODUCT_STATUSES = ['NoProduct', 'EmptyProduct', 'InvalidProduct'] as const;
export type InvalidProductStatus = (typeof INVALID_PRODUCT_STATUSES)[number];
export const isInvalidProductStatus = makeValuesPredicate(INVALID_PRODUCT_STATUSES);

export type BulkProductStatus =
  | 'NotInitialized'
  | 'Received'
  | 'Validating'
  | 'Validated'
  | 'Rejected'
  | InvalidProductStatus;

export type BulkLeg = BulkSpot | BulkForward;

export type Way = 'Buy' | 'Sell';
export type Side = 'Buy' | 'Sell' | 'Both';

export interface Possible<T> {
  default: T;
  possible: readonly T[];
}

interface BulkLegCommon {
  thirdPartyTradeReference?: string;
  way: Way;
  displayWay?: Way;
  currencyPair: string;
  expiryDate: string;
  amount: number;
  amountCurrency: string;
  errors?: TcErrors<TradeCaptureBulkLegPreviousValues>;
  warnings?: TcWarnings<TradeCaptureBulkLegPreviousValues>;
  maturityDateTenor?: string;
  isSelectedForExecution?: boolean;
  company?: string, 
  companyDescription?: string,
  sgReference?: string
}

interface BulkSpot extends BulkLegCommon {
  type: 'Spot';
}

interface BulkForward extends BulkLegCommon {
  type: 'Forward';
}
